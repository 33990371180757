import './index.scss';
import Button from '../Shared/Button';

const UploadFooterSuccess = (props: any) => {
  return (
    <div className="UploadFooter UploadFooter-succes" data-testid="upload-footer">
      <Button className="Button Button--iconLast">
        {props.content} <i className="icon-Validation-Check"></i>
      </Button>
    </div>
  );
};

export default UploadFooterSuccess;
