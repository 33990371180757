import { useState, useEffect } from 'react';
import './index.scss';
import CategoryTeaser from '../../components/Shared/CategoryTeaser';
import { getArticles } from '../../services/CmsService';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateAllArticles } from '../../store/actions/learnAction';

const CategoryListing = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [allArticles, setAllArticles] = useState<any>([]);
  const [categoryId, setCategoryId] = useState();
  const [businessModelId, setBusinessModelId] = useState();
  let pageType = props.pageType;

  const getCategoryList = () => {
    let categoryid = props.categoryId;
    let businessmodelId = props.businessModelId;

    if (categoryid && businessmodelId) {
      props.setShowLoader(true);
      getArticles(categoryid, businessmodelId, pageType)
        .then((response: any) => {
          if (response) {
            setAllArticles(response?.results);
            if (pageType === 'LearnPage') {
              window.sessionStorage.setItem('allArticles', JSON.stringify(response?.results));
              dispatch(updateAllArticles(response?.results ? response?.results : null));
            }
          }
          props.setShowLoader(false);
        })
        .catch((error: any) => {
          props.setShowLoader(false);
          console.log(error);
        });
    } else {
      props.setShowLoader(false);
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    getCategoryList();
  }, [categoryId, businessModelId]);

  useEffect(() => {
    let entityValues: any = props.selectedEntity ? props.selectedEntity?.value?.split(':') : '';
    if (entityValues) {
      // console.log("preselected", selectedEntity)
      setCategoryId(entityValues[0]);
      setBusinessModelId(entityValues[1]);
    }
  }, [props.selectedEntity]);

  return (
    <>
      {props.selectedEntity && Object.keys(props.selectedEntity).length > 0 && allArticles.length === 0 && (
        <div className="mt-4">No categories found</div>
      )}

      {props.onBoarding &&
        allArticles &&
        allArticles.map((category, index) => {
          return (
            <Link
              to={{
                pathname: '/article' + category.url,
                search:
                  '?categoryId=' +
                  categoryId +
                  '&businessModelId=' +
                  businessModelId +
                  '&pageType=' +
                  pageType +
                  '&from=onboarding',
              }}
            >
              <CategoryTeaser
                category={category}
                title={category.catHeading}
                total={category.tagText}
                description={category.catDescription}
                completed={category.completed}
              ></CategoryTeaser>
            </Link>
          );
        })}

      {!props.onBoarding &&
        allArticles &&
        allArticles.map((category, index) => {
          return (
            <Col xs={12} sm={4} md={4} lg={4} key={category.id}>
              <Link
                to={{
                  pathname: '/article' + category.url,
                  search: '?categoryId=' + categoryId + '&businessModelId=' + businessModelId + '&pageType=' + pageType,
                }}
              >
                <CategoryTeaser
                  category={category}
                  isFullwidth
                  title={category.catHeading}
                  total={category.tagText}
                  description={category.catDescription}
                  completed={category.completed}
                ></CategoryTeaser>
              </Link>
            </Col>
          );
        })}
    </>
  );
};

export default CategoryListing;
