import axios from 'axios';
import getDetails from '../api/EnviromentConfig';
let HmUserUrl: string = getDetails().apiurl;
let subscriptionKey: string = getDetails().subscriptionKey;

export async function getAllHmUsers() {
  try {
    const response = await axios({
      method: 'GET',
      url: HmUserUrl + 'HMUser/GetAllHmUsers',
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getHmUser(email: string) {
  try {
    const response = await axios({
      method: 'GET',
      url: HmUserUrl + 'HMUser/GetHMUser?email=' + email,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response;
  } catch {
    return null;
  }
}

export async function createHmUser(hmUser: any) {
  try {
    const response = await axios({
      method: 'POST',
      url: HmUserUrl + 'HMUser/CreateHMUser',
      data: hmUser,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function updateHmUser(hmUser: any) {
  try {
    const response = await axios({
      method: 'PUT',
      url: HmUserUrl + 'HMUser/UpdateHMUser',
      data: hmUser,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function deleteHmUser(email: string) {
  try {
    const response = await axios({
      method: 'DELETE',
      url: HmUserUrl + 'HMUser/DeleteHMUser?email=' + email,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}
