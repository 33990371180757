import { useState, useEffect } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import { useForm } from 'react-hook-form';
import { getHmUser, updateHmUser } from '../../services/HmUsersService';
import Loader from '../../components/Shared/Loader';

const EditUser = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showLoader, setShowLoader] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const userDetail = useSelector((state: RootStateOrAny) => state.user);

  useEffect(() => {
    let userEmail: any = '';
    if (userDetail.user.username) {
      userEmail = userDetail.user.username;
    } else {
      userEmail = window.sessionStorage.getItem('userEmail');
    }
    getHmUserDetail(userEmail);

    if (props.user.isEditor) {
      setIsEditor(true);
    }
  }, []);

  const getHmUserDetail = (email: string) => {
    setShowLoader(true);
    getHmUser(email)
      .then(function (resp) {
        if (resp) setIsAdmin(resp.data[0].isEditor);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const handleOptimizelyAccess = (event: any) => {
    setIsEditor(event.target.checked);
  };

  const onSubmit = (data: any) => {
    data['isEditor'] = isEditor;
    setShowLoader(true);
    updateHmUser(data)
      .then(function (data) {
        setShowLoader(false);
        props.editSuccess(data);
      })
      .catch(function (error) {
        setShowLoader(false);
        console.log(error);
      });
  };

  return (
    <div className="popup-backdrop">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6 className="Heading">
            Edit user{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
            >
              <i className="icon-Close Popup-close"></i>
            </span>
          </h6>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addUser-popup--body">
            <input type="hidden" defaultValue={props.user.userId} {...register('userId', { required: true })} />

            <input
              defaultValue={props.user.firstName}
              {...register('firstName', { required: true })}
              className={`form-control ${errors.firstName ? 'error-control' : ''}`}
              placeholder="Enter first name"
              autoComplete="off"
            />
            {errors.firstName && <span>Please enter your firstname</span>}

            <input
              defaultValue={props.user.lastName}
              {...register('lastName', { required: true })}
              className={`form-control ${errors.lastName ? 'error-control' : ''}`}
              placeholder="Enter last name"
              autoComplete="off"
            />
            {errors.lastName && <span>Please enter your lastname</span>}

            <div className={`${isAdmin ? '' : 'hide-access'}`}>
              <label className={`optimizely-access`}>
                <input type="checkbox" onChange={handleOptimizelyAccess} checked={isEditor} /> Give optimizely access
              </label>
              <label
                className="tool"
                data-tip="Optimizely is the content management system of the Partner Hub. Giving this access means that the user will have control to edit content on the Partner Hub. Access can only be given if you have the access yourself."
              >
                <i className="icon-Info"></i>
              </label>
            </div>

            <input type="hidden" defaultValue={props.user.email} {...register('email', { required: true })} />
          </div>

          <div className="addUser-popup--footer">
            <Button className="Button" type="submit">
              Save changes
            </Button>
          </div>
        </form>
      </div>
      {showLoader ? <Loader /> : <></>}
    </div>
  );
};

export default EditUser;
