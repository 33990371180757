import './index.scss';
import nextChapterImage from '../../../assets/images/articleImages/chapterimg.png';

import Button from '../Button';
import Link from '../../Global/Link';
const Feedback = (props: any) => {
  return (
    <div className="Feedback" data-testid="feedback-component">
      <div className="Feedback-cta">
        <Button className="Button">Read and understood the article</Button>
        <Button className="Button Button--outlined">Not relevant for me</Button>
      </div>
      <div className="Feedback-chapter">
        <Link className="Link Link--arrow Feedback-chapterLink" href="#">
          <img src={nextChapterImage} className="" />
          Next
        </Link>
      </div>
    </div>
  );
};

export default Feedback;
