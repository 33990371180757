import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../components/Shared/Button';
import './index.scss';
import '../../components/Shared/Indent-hero/index.scss';
import Loader from '../../components/Shared/Loader';
import { getPartnerDetail } from '../../services/PartnersService';
import Constants from '../../types/Constants';
import { useDispatch } from 'react-redux';
import { updatePartner } from '../../store/actions/partnerAction';
import { updateCurrentPartnerUser } from '../../store/actions/currentPartnerUserAction';

const Welcome = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [username, setUsername] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [showDashboard, setShowDashboard] = useState(true);
  const [userType, setUserType] = useState('');
  const user = useSelector((state: RootStateOrAny) => state.user);
  const userTypeData = useSelector((state: RootStateOrAny) => state.userType);
  const [showLoader, setShowLoader] = useState(false);

  const location = useLocation<any>();

  let redirectUrl = location.state?.redirectUrl;
  let hasMultiplePartners = window.sessionStorage.getItem('hasMultiplePartners') === 'true' ? true : false;
  let multiplePartners = JSON.parse(window.sessionStorage.getItem('multiplePartners')?.toString() || '[]');

  useEffect(() => {
    if (user.user.name) {
      setUsername(user.user.name);
    } else {
      let userInfo: any = window.sessionStorage.getItem('user');
      let userInfoDetail: any = JSON.parse(userInfo);
      setUsername(userInfoDetail.name);
    }
    if (userTypeData.userType) {
      setUserType(userTypeData.userType);
      loadUser(userTypeData.userType);
    } else {
      let usertype: any = window.sessionStorage.getItem('userType');
      setUserType(usertype);
      loadUser(usertype);
    }
  }, []);

  const loadUser = (userTypeInfo: string) => {
    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      if (userTypeInfo == 'HmUser') {
        setShowDashboard(true);
        setButtonText('Partner Profiles');
      } else {
        if (!hasMultiplePartners) {
          let partnerId: any = window.sessionStorage.getItem('partnerId');
          setShowLoader(true);
          getPartnerDetail(partnerId)
            .then(function (partnerData) {
              window.sessionStorage.setItem(Constants.SESSIONSTORAGE_PARTNERDETAIL, JSON.stringify(partnerData));
              window.sessionStorage.setItem('partnerId', partnerId);
              window.sessionStorage.setItem('hasMultiplePartners', 'false');
              if (partnerData?.isOnboarded) {
                setShowDashboard(true);
                setButtonText('Dashboard');
              } else {
                setShowDashboard(false);
              }
              setShowLoader(false);
            })
            .catch(function (error) {
              console.log(error);
              setShowLoader(false);
            });
        }
      }
    }
  };

  const goNext = () => {
    if (userType == 'HmUser') {
      history.push('/partner-profile');
    } else {
      history.push('/dashboard');
      setButtonText('Dashboard');
    }
  };

  const SelectPartner = (partnerId: string) => {
    getPartnerDetail(partnerId).then(function (partnerData) {
      window.sessionStorage.setItem(Constants.SESSIONSTORAGE_PARTNERDETAIL, JSON.stringify(partnerData));
      window.sessionStorage.setItem('partnerId', partnerId);
      window.sessionStorage.setItem('hasMultiplePartners', 'true');
      // window.sessionStorage.setItem("multiplePartners", JSON.stringify(partnerData.partnerUserMapping));
      if (partnerData.isOnboarded) {
        history.push('/dashboard');
      } else {
        history.push('/onboarding');
      }
      dispatch(updatePartner(partnerData ? partnerData : null));
      dispatch(updateCurrentPartnerUser(partnerData ? partnerData : null));
    });
  };

  return (
    <div className="welcome-page">
      <div className="bg-area">
        <Container fluid="md">
          <Row className="justify-content-md-center">
            <Col xs={12} sm={12} md={7}>
              <div className="Indent-hero">
                <h1 className="Heading">
                  Hi {username}, <br /> WELCOME TO THE H&M PARTNER HUB
                </h1>
                {hasMultiplePartners && (
                  <div className="Indent-heroWrapper">
                    <p>Please pick what legal entity you would like to use.</p>
                    {multiplePartners &&
                      multiplePartners.map((partner: any) => {
                        return (
                          <Button
                            className="Button Button--offWhite Button--large"
                            onClick={() => SelectPartner(partner.partnerId)}
                            style={{ marginRight: '10px', marginBottom: '20px' }}
                          >
                            {partner.partnerName}
                          </Button>
                        );
                      })}
                  </div>
                )}
                {!hasMultiplePartners && (
                  <div className="Indent-heroWrapper">
                    <p>We are excited to work with you.</p>
                    {showDashboard ? (
                      <Button className="Button Button--offWhite Button--large" onClick={goNext}>
                        Let's go to {buttonText}!
                      </Button>
                    ) : (
                      <Button
                        className="Button Button--offWhite Button--large"
                        onClick={() => history.push('/onboarding')}
                      >
                        Let's go to Onboarding!
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        {showLoader ? <Loader /> : <></>}
      </div>
    </div>
  );
};

export default Welcome;
