import './index.scss';
const Categoryteaser = (props: any) => {
  return (
    <div className={props.isFullwidth ? 'CategoryTeaserFullWidth' : 'CategoryTeaser'}>
      <div className="CategoryTeaser-content">
        {/* <span className="CategoryTeaser-quantity"> {props.total ? props.total : '4 articles'} </span> */}
        <h2 className="Heading CategoryTeaser-heading Heading--h5">{props.title ? props.title : 'Title goes here'}</h2>
        <p className="CategoryTeaser-text">{props.description ? props.description : ''}</p>
      </div>
    </div>
  );
};

export default Categoryteaser;
