import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss';

const TextBlock = (props) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const textBlockData =
      props.block.data?.text?.fragments?.length > 0
        ? props.block.data?.text?.fragments?.map((item) => item.internalFormat).join('')
        : props.block.data?.text;
    const tempElement = document.createElement('div');
    tempElement.innerHTML = textBlockData;
    const anchorTags = tempElement.querySelectorAll('a');

    let modifiedContent = textBlockData;
    let learnReplaced = false;
    let sustainabilityReplaced = false;

    anchorTags.forEach((anchor) => {
      if (anchor.origin === window.location.origin) {
        if (!learnReplaced) {
          modifiedContent = modifiedContent.replaceAll('/learn/', '/article/learn/');
          learnReplaced = true;
        }

        if (!sustainabilityReplaced) {
          modifiedContent = modifiedContent.replaceAll('/sustainability/', '/article/sustainability/');
          sustainabilityReplaced = true;
        }
      }
    });

    setHtmlContent(modifiedContent);
  }, [props.block.data?.text]);

  return (
    <div className="Article-content" id={props.block.data?.id} data-testid="TextBlock">
      <div className="Article-content--text">
        <Row>
          <Col lg={12}>
            <h3 className="Heading">{props.block.data?.heading}</h3>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TextBlock;
