import { Rings } from 'react-loader-spinner';
import './index.scss';

const Loader = () => {
  return (
    <div className="loader-area" data-testid="loader">
      <div className="loader-area--content">
        <Rings color="#FAF9F8" height={100} width={100} />
      </div>
    </div>
  );
};

export default Loader;
