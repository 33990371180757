import React, { useEffect, useState, useMemo, forwardRef } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import TableHeader from './Header';
import Pagination from './Pagination';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getSalesData } from '../../services/SalesService';
import Loader from '../../components/Shared/Loader';
import moment from 'moment';
import Select from 'react-select';
import { getAllFilters } from '../../services/DataService';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import ExportDropdown from '../ExportDropdown';
import ColumnResizer from 'react-table-column-resizer';
import EntitySelector from '../EntitySelector';
import { getUniqueEntityList } from '../../helpers/utilities';
import DatePicker from 'react-datepicker';
import './index.scss';

import 'react-datepicker/dist/react-datepicker.css';

const DataTable = (props: any) => {
  const customDate: any = new Date()?.setDate(new Date()?.getDate() - 4);
  const [salesData, setSalesData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [showLoader, setShowLoader] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [currentDate, setCurrentDate] = useState(customDate);
  const [fromDate, setFromDate] = useState(new Date(customDate)?.setMonth(new Date(customDate)?.getMonth() - 1));
  const [toDate, setToDate] = useState(customDate);
  const [marketsList, setMarketsList] = useState<any[]>([]);
  const [categoriesList, setCategoriesList] = useState<any[]>([]);
  const [brandsList, setBrandsList] = useState<any[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<any[]>([]);
  const [rowExpand, setRowExpand] = useState(false);
  const [rowId, setRowId] = useState('');
  const [totalSalesData, setTotalSalesData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const partnerDetail = useSelector((state: RootStateOrAny) => state.partner);
  const [exportFileName, setExportFileName] = useState('');
  const [exportLevelSelected, setExportLevelSelected] = useState('');
  const [selectedEntity, setSelectedEntity] = useState<any[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedBusinessModelId, setSelectedBusinessModelId] = useState('');
  const [uniqueEntityList, setUniqueEntityList] = useState<any[]>([]);

  const [emptyMessage, setEmptyMessage] = useState<any>('Please use above filtering to get your performance data.');
  const [showError, setShowError] = useState(false);
  const [notifyText, setNotifyText] = useState('');
  let partnerId: string | null = '';
  const categoryId: string = selectedCategoryId;
  const businessModelId: string = selectedBusinessModelId;

  if (partnerDetail && partnerDetail.partner && Object.keys(partnerDetail?.partner)?.length !== 0) {
    partnerId = partnerDetail.partner.partnerId;
  } else {
    partnerId = window.sessionStorage.getItem('partnerId');
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData, fileName, exportLevel) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, exportFileName + fileExtension);
  };

  const handleDateChange = (date: any, target: any) => {
    setCurrentPage(1);
    setSearchName('');
    setSelectedBrand([]);
    setSelectedCategory([]);
    if (target === 'from') {
      setFromDate(date);
    } else {
      setToDate(date);
    }
  };

  const getFilters = () => {
    getAllFilters()
      .then(function (data) {
        let markets: any = [];
        for (let market of data.markets) {
          let marketData: any = { value: market.marketId, label: market.marketName };
          markets.push(marketData);
        }
        setMarketsList(markets);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const exportLevel = [
    {
      label: 'Article Level',
      value: '1',
    },
    {
      label: 'Variant Level',
      value: '2',
    },
  ];

  const handleExportLevel = (selectedOption: any) => {
    setExportLevelSelected(selectedOption.value);
    let pd = parseDataForExport(finalSalesData, selectedOption.value);
    setTotalSalesData(pd);
    exportToCSV(pd, exportFileName, selectedOption.label);
    setIsSearch(true);
  };

  const handleCategory = (selectedOption: any) => {
    setCurrentPage(1);
    setSelectedCategory(selectedOption);
    setIsSearch(true);
  };

  const handleMarket = (selectedOption: any) => {
    setCurrentPage(1);
    setSearchName('');
    setSelectedBrand([]);
    setSelectedCategory([]);
    setSelectedMarket(selectedOption);
    setIsSearch(false);
  };

  const handleEntity = (selectedOption: any) => {
    setCurrentPage(1);
    setSearchName('');
    setSelectedBrand([]);
    setSelectedCategory([]);
    let entityValues = selectedOption?.value?.split(':');
    setSelectedCategoryId(entityValues?.[0]);
    setSelectedBusinessModelId(entityValues?.[1]);
    props.handleSelectedBusinessModelId(entityValues?.[1]);
    setSelectedEntity(selectedOption);
    setIsSearch(false);
  };

  const handleBrand = (selectedOption: any) => {
    setCurrentPage(1);
    setSelectedBrand(selectedOption);
    setIsSearch(true);
  };

  const handleChange = (event: any) => {
    setSearchName(event.target.value);
    setCurrentPage(1);
    setIsSearch(true);
  };

  const handleRowExpand = (id: any) => {
    if (!document.getElementById(id)) {
      setRowExpand(true);
      setRowId(id);
    } else {
      setRowExpand(false);
      setRowId('');
    }
  };

  const getEntityList = (): any => {
    let entityList: any = getUniqueEntityList();
    setUniqueEntityList(entityList);
    props.handleUniqueEntityList(entityList);
    if (entityList?.length === 1) {
      handleEntity(entityList?.[0]);
    }
  };

  useEffect(() => {
    getFilters();
    getEntityList();
  }, []);

  useEffect(() => {
    if (selectedBusinessModelId === '10001') {
      setFromDate(new Date(customDate)?.setMonth(new Date(customDate)?.getMonth() - 1));
      setToDate(customDate);
      setCurrentDate(customDate);
    } else {
      setFromDate(new Date()?.setMonth(new Date()?.getMonth() - 1));
      setToDate(new Date());
      setCurrentDate(new Date());
    }
  }, [selectedBusinessModelId]);

  const ITEMS_PER_PAGE = 100;

  const headers = [
    { name: 'Article Name', tooltip: 'Name of the article.', field: 'article_Name', sortable: true },
    {
      name: 'Article Description',
      tooltip: 'Description of the article, usually describes the specific color name of the article.',
      field: 'article_Description',
      sortable: true,
    },
    selectedBusinessModelId === '10001'
      ? { name: 'Brand Name', tooltip: 'Name of the brand of the article.', field: 'brandName', sortable: true }
      : { name: '', field: '', sortable: true },
    JSON.parse(JSON.stringify(selectedMarket))?.label === 'All'
      ? { name: 'Market', tooltip: 'Name of the market of the article.', field: 'market', sortable: true }
      : { name: '', field: '', sortable: true },
    { name: 'Size', tooltip: 'Size', field: 'size', sortable: true },
    { name: 'GTIN', tooltip: 'Common identifier of the article.', field: 'gtin', sortable: true },
    { name: 'Category', tooltip: 'The category of the article.', field: 'category', sortable: true },
    {
      name: 'No of Order',
      tooltip: 'Number of orders placed within selected time period.',
      field: 'order_count',
      sortable: true,
    },
    {
      name: 'No of Sold Items',
      tooltip: 'Number of sold items within selected time period.',
      field: 'no_of_Sold_Items',
      sortable: true,
    },
    {
      name: 'No of Sold Items After Return',
      tooltip:
        'Number of sold items after returns within selected time period. Please note that if you have higher returns than sales within selected date range, a negative number will be shown.',
      field: 'no_of_Sold_Items_After_Return',
      sortable: true,
    },
    {
      name: 'Return Rate',
      tooltip: 'No of sold items divided by number of returned items within selected time period.',
      field: 'return_Rate',
      sortable: true,
    },
    { name: 'Return Reason', tooltip: 'Reason(s) for return.', field: 'return_Reason', sortable: true },
    !(selectedBusinessModelId === '10001' && selectedCategoryId === '10001')
      ? {
          name: 'Stock',
          tooltip: 'Current stock level of specific article. Not affected by time period selection.',
          field: 'stock',
          sortable: true,
        }
      : { name: '', field: '', sortable: true },
    selectedBusinessModelId === '10002'
      ? { name: 'Approval Rate', tooltip: 'Rate of accepted orders', field: 'approval_Rate', sortable: true }
      : { name: '', field: '', sortable: true },
    selectedBusinessModelId === '10002'
      ? {
          name: 'Approval Delay',
          tooltip: 'Average time for a shop to accept or refuse an order (in seconds)',
          field: 'approval_Delay',
          sortable: true,
        }
      : { name: '', field: '', sortable: true },
  ];

  useEffect(() => {
    const startDate = moment(fromDate).format('YYYY-MM-DD');
    const endDate = moment(toDate).format('YYYY-MM-DD');
    const markets = JSON.parse(JSON.stringify(selectedMarket))?.label;
    const entity = JSON.parse(JSON.stringify(selectedEntity))?.label;

    const getData = () => {
      setShowLoader(true);
      getSalesData(partnerId, startDate, endDate, markets, selectedCategoryId, selectedBusinessModelId)
        .then(function (response: any) {
          setShowLoader(false);
          setSalesData(response?.data);
          const categories = uniqueArray(response?.data, 'category');
          setCategoriesList(categories);
          const brands = uniqueArray(response?.data, 'brandName');
          setBrandsList(brands);
          setIsSearch(false);
          if (response?.status !== 204 && response?.status !== 200) {
            if (response?.detail) {
              setSalesData(response?.data);
              setNotifyText(response?.detail);
              setShowError(true);
              setTimeout(() => {
                setShowError(false);
              }, 7000);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            setNotifyText(error?.response?.statusText);
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 7000);
          }
        });
    };
    if (startDate && endDate && markets && entity) {
      getData();
    }
  }, [selectedMarket, fromDate, toDate]);

  const uniqueArray = (data: any, value: any) => {
    let dataSet: any = [];
    let uniqueData: any = [];
    for (let item of data) {
      dataSet.push(item?.[value]);
    }
    const uniqueFilter = dataSet.filter(function (item: any, i: any, ar: any) {
      return ar.indexOf(item) === i;
    });
    for (let item of uniqueFilter) {
      let modifiedData: any = { value: item, label: item };
      uniqueData.push(modifiedData);
    }
    return uniqueData;
  };

  //Parse data for export to CSV
  const parseDataForExport = (data: any, level: any = 1) => {
    let pd: any = [];

    if (level === '1') {
      for (let sales of data) {
        let parsedSales: any = {};
        parsedSales['Article name'] = sales.article_Name;
        parsedSales['Article Description'] = sales.article_Description;
        if (businessModelId === '10001') {
          parsedSales['Brand name'] = sales.brandName;
        }
        parsedSales['GTIN'] = sales.gtin;
        parsedSales['Category'] = sales.category;
        parsedSales['No of Order'] = sales.order_count;
        parsedSales['No. of sold items'] = sales.no_of_Sold_Items;
        parsedSales['No. of sold items after return'] = sales.no_of_Sold_Items_After_Return;
        parsedSales['Return rate'] = sales?.return_Rate ? sales?.return_Rate + '%' : '0%';
        parsedSales['Return Reason'] = sales?.return_Reason
          ?.replaceAll('Unknown,', '')
          ?.replaceAll(',Unknown', '')
          ?.replaceAll('Unknown', '');
        if (!(businessModelId === '10001' && categoryId === '10001')) {
          parsedSales['Stock'] = sales.stock;
        }
        if (businessModelId === '10002') {
          parsedSales['Approval Rate'] = sales?.approval_Rate ? Math.floor(sales?.approval_Rate * 100) + '%' : '';
        }
        if (businessModelId === '10002') {
          parsedSales['Approval Delay'] = sales?.approval_Delay ? secondsToHms(sales?.approval_Delay) : '';
        }

        pd.push(parsedSales);
      }
    } else if (level === '2') {
      for (let sales of data) {
        if (sales?.variants?.length > 0) {
          for (let variant of sales.variants) {
            let parsedSales: any = {};
            parsedSales['Article name'] = sales.article_Name;
            parsedSales['Article Description'] = sales.article_Description;
            if (businessModelId === '10001') {
              parsedSales['Brand name'] = sales.brandName;
            }
            if (JSON.parse(JSON.stringify(selectedMarket))?.label === 'All') {
              parsedSales['Market'] = variant.market;
            }
            parsedSales['Size'] = variant.size;
            parsedSales['GTIN'] = variant.gtin;
            parsedSales['Category'] = sales.category;
            parsedSales['No of Order'] = variant.order_count;
            parsedSales['No. of sold items'] = variant.no_of_Sold_Items;
            parsedSales['No. of sold items after return'] = variant.no_of_Sold_Items_After_Return;
            parsedSales['Return rate'] = variant.return_Rate;
            parsedSales['Return Reason'] = variant.return_Reason;

            if (!(businessModelId === '10001' && categoryId === '10001')) {
              parsedSales['Stock'] = variant.stock;
            }
            if (businessModelId === '10002') {
              parsedSales['Approval Rate'] = variant.approval_Rate;
            }
            if (businessModelId === '10002') {
              parsedSales['Approval Delay'] = variant.approval_Delay;
            }

            pd.push(parsedSales);
          }
        }
      }
    }

    return pd;
  };

  const finalSalesData = useMemo(() => {
    let computedSalesData = salesData;

    if (searchName) {
      computedSalesData =
        computedSalesData &&
        computedSalesData.filter(
          (sales: any) =>
            sales.article_Name.toLowerCase().includes(searchName.toLowerCase()) ||
            sales?.articleID?.toLowerCase().includes(searchName.toLowerCase()) ||
            sales?.variants?.some((variant: any) => variant?.gtin?.toLowerCase().includes(searchName.toLowerCase())),
        );
    }

    if (selectedCategory && Object.keys(selectedCategory)?.length > 0) {
      const selectedCategoryValue = JSON.parse(JSON.stringify(selectedCategory))?.value;
      computedSalesData =
        computedSalesData &&
        computedSalesData?.filter((sales: any) =>
          sales?.category?.toLowerCase().includes(selectedCategoryValue.toLowerCase()),
        );
    }

    if (selectedBrand && Object.keys(selectedBrand)?.length > 0) {
      const selectedBrandValue = JSON.parse(JSON.stringify(selectedBrand))?.value;
      computedSalesData =
        computedSalesData &&
        computedSalesData.filter((sales: any) =>
          sales?.brandName?.toLowerCase().includes(selectedBrandValue.toLowerCase()),
        );
    }

    setTotalItems(computedSalesData?.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedSalesData =
        computedSalesData &&
        computedSalesData.sort((a: any, b: any) => {
          // check for num vs num
          if (typeof a[sorting.field] === 'number' && typeof b[sorting.field] === 'number') {
            return reversed * a[sorting.field] - b[sorting.field];
          }
          // check for string vs string
          if (typeof a[sorting.field] === 'string' && typeof b[sorting.field] === 'string') {
            if (sorting.field === 'return_Reason') {
              if (
                a[sorting.field]?.replaceAll('Unknown,', '')?.replaceAll(',Unknown', '')?.replaceAll('Unknown', '') <
                b[sorting.field]?.replaceAll('Unknown,', '')?.replaceAll(',Unknown', '')?.replaceAll('Unknown', '')
              )
                return reversed * -1;
              else return reversed * 1;
            } else {
              if (a[sorting.field] < b[sorting.field]) return reversed * -1;
              else return reversed * 1;
            }
          }
          return 0;
        });
    }

    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

    //Add complete data for export
    let pd = parseDataForExport(computedSalesData);
    setTotalSalesData(pd);

    let m = selectedMarket ? JSON.parse(JSON.stringify(selectedMarket))?.label : '';
    let c =
      selectedCategory && Object.keys(selectedCategory)?.length > 0
        ? ' - ' + JSON.parse(JSON.stringify(selectedCategory))?.label
        : '';
    let b =
      selectedBrand && Object.keys(selectedBrand)?.length > 0
        ? ' - ' + JSON.parse(JSON.stringify(selectedBrand))?.label
        : '';
    setExportFileName(
      moment(fromDate).format('DD MMM YYYY') + '-' + moment(toDate).format('DD MMM YYYY') + ' - ' + m + c + b,
    );

    if (selectedMarket && Object.keys(selectedMarket)?.length > 0) {
      setEmptyMessage('No data found');
    }

    //Current Page slice
    return computedSalesData?.slice(indexOfFirstItem, indexOfLastItem);
  }, [salesData, currentPage, searchName, sorting, selectedCategory, selectedBrand]);

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
  };

  const CustomDatepickerInput = forwardRef<any, any>(({ label, value, onClick }, ref) => (
    <button className="customDatepickerInput" onClick={onClick} ref={ref}>
      {label}: {moment(value).format('DD MMM YYYY')}
    </button>
  ));

  return (
    <>
      <div className="row w-100 dataTableContainer">
        <div className="col mb-3 col-12">
          {finalSalesData?.length > 0 && (
            <Row className="customPadding export">
              <Col xs={3} sm={3} md={3}>
                <ExportDropdown onChange={handleExportLevel} data={exportLevel} />
              </Col>
            </Row>
          )}
          <Row key="SearchHeader">
            {uniqueEntityList.length > 1 ? (
              <Col xs={3} sm={3} md={3} className="customPadding market">
                <EntitySelector handleEntity={handleEntity} dataTable></EntitySelector>
              </Col>
            ) : null}
            {selectedBusinessModelId ? (
              <>
                <Col xs={3} sm={3} md={3} className="customPadding">
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => handleDateChange(date, 'from')}
                    minDate={selectedBusinessModelId === '10001' ? new Date('2023-07-01') : null}
                    maxDate={currentDate}
                    customInput={<CustomDatepickerInput label="From" />}
                    closeOnScroll={true}
                    showMonthDropdown
                    useShortMonthInDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </Col>

                <Col xs={3} sm={3} md={3} className="customPadding">
                  <DatePicker
                    selected={toDate}
                    onChange={(date) => handleDateChange(date, 'to')}
                    minDate={fromDate}
                    maxDate={currentDate}
                    customInput={<CustomDatepickerInput label="To" />}
                    closeOnScroll={true}
                    showMonthDropdown
                    useShortMonthInDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </Col>

                <Col xs={3} sm={3} md={3} className="customPadding market">
                  <Select
                    isSearchable
                    value={selectedMarket}
                    onChange={handleMarket}
                    options={marketsList}
                    placeholder="Market"
                  />
                </Col>
              </>
            ) : null}
          </Row>
          <Row key="SearchHeaderSubSection">
            {isSearch || finalSalesData?.length > 1 ? (
              <Col xs={4} sm={4} md={4} className="customPadding">
                <div className="form-group has-search">
                  <span className="icon-Search form-control-search"></span>
                  <input
                    type="text"
                    value={searchName}
                    className="form-control"
                    placeholder="Name or GTIN"
                    onChange={handleChange}
                  />
                </div>
              </Col>
            ) : null}
            {isSearch || finalSalesData?.length > 1 ? (
              <Col xs={2} sm={2} md={2} className="customPadding category">
                <Select
                  isClearable
                  isSearchable
                  value={selectedCategory}
                  onChange={handleCategory}
                  options={categoriesList}
                  placeholder="Category"
                />
              </Col>
            ) : null}
            {(isSearch || finalSalesData?.length > 1) && businessModelId === '10001' ? (
              <Col xs={2} sm={2} md={2} className="customPadding brand">
                <Select
                  isClearable
                  isSearchable
                  value={selectedBrand}
                  onChange={handleBrand}
                  options={brandsList}
                  placeholder="Brand"
                />
              </Col>
            ) : null}
          </Row>
          {finalSalesData?.length > 0 ? (
            <>
              <div className="dataTable">
                <table className="table responsive">
                  <TableHeader headers={headers} onSorting={(field: any, order: any) => setSorting({ field, order })} />
                  <tbody>
                    {finalSalesData &&
                      finalSalesData?.map((sales: any, index: any) => (
                        <React.Fragment key={'airtcle' + index}>
                          <tr onClick={() => handleRowExpand(sales?.article_Name + index)} key={'articleLevel' + index}>
                            <td>
                              <i
                                className={
                                  rowId === sales?.article_Name + index && rowExpand
                                    ? 'icon-Arrows-Up'
                                    : 'icon-Arrows-Down'
                                }
                              />
                            </td>
                            <td>
                              {sales?.url ? (
                                <img src={sales?.url} width="24" height="36" />
                              ) : (
                                <img src={sales?.url} width="24" height="36" className="hiddenVisibility" />
                              )}
                            </td>
                            <td>{sales?.article_Name}</td>
                            <ColumnResizer className="columnResizer" />
                            <td>{sales?.article_Description}</td>
                            <ColumnResizer className="columnResizer" />
                            {businessModelId === '10001' ? (
                              <>
                                <td>{sales?.brandName}</td> <ColumnResizer className="columnResizer" />{' '}
                              </>
                            ) : null}
                            {JSON.parse(JSON.stringify(selectedMarket))?.label === 'All' ? (
                              <>
                                <td>{sales?.market}</td> <ColumnResizer className="columnResizer" />{' '}
                              </>
                            ) : null}
                            <td>{sales?.size}</td>
                            <ColumnResizer className="columnResizer" />
                            <td>{sales?.gtin}</td>
                            <ColumnResizer className="columnResizer" />
                            <td>{sales?.category}</td>
                            <ColumnResizer className="columnResizer" />
                            <td>{sales?.order_count}</td>
                            <ColumnResizer className="columnResizer" />
                            <td>{sales?.no_of_Sold_Items}</td>
                            <ColumnResizer className="columnResizer" />
                            <td>{sales?.no_of_Sold_Items_After_Return}</td>
                            <ColumnResizer className="columnResizer" />
                            <td>{sales?.return_Rate ? sales?.return_Rate + '%' : '0%'}</td>
                            <ColumnResizer className="columnResizer" />
                            <td>
                              {sales?.return_Reason
                                ?.replaceAll('Unknown,', '')
                                ?.replaceAll(',Unknown', '')
                                ?.replaceAll('Unknown', '')}
                            </td>
                            <ColumnResizer className="columnResizer" />
                            {!(businessModelId === '10001' && categoryId === '10001') ? (
                              <>
                                <td>{sales?.stock}</td> <ColumnResizer className="columnResizer" />
                              </>
                            ) : null}
                            {businessModelId === '10002' ? (
                              <>
                                <td>{sales?.approval_Rate ? Math.floor(sales?.approval_Rate * 100) + '%' : ''}</td>{' '}
                                <ColumnResizer className="columnResizer" />
                              </>
                            ) : null}
                            {businessModelId === '10002' ? (
                              <>
                                <td>{sales?.approval_Delay ? secondsToHms(sales?.approval_Delay) : ''}</td>{' '}
                                <ColumnResizer className="columnResizer" />
                              </>
                            ) : null}
                          </tr>
                          {rowId === sales?.article_Name + index && rowExpand ? (
                            <tr
                              id={sales?.article_Name + index}
                              key={'rowExpand' + index}
                              style={{ display: 'contents' }}
                            >
                              {sales?.variants?.map((variant: any, index: any) => (
                                <tr key={variant?.ecc_Variant_Code + index + 'rowExpandValue'}>
                                  <td></td>
                                  <td></td>
                                  <td>{sales?.article_Name}</td>
                                  <ColumnResizer className="columnResizer" />
                                  <td>{sales?.article_Description}</td>
                                  <ColumnResizer className="columnResizer" />
                                  {businessModelId === '10001' ? (
                                    <>
                                      <td>{sales?.brandName}</td> <ColumnResizer className="columnResizer" />
                                    </>
                                  ) : null}
                                  {JSON.parse(JSON.stringify(selectedMarket))?.label === 'All' ? (
                                    <>
                                      <td>{variant?.market}</td> <ColumnResizer className="columnResizer" />{' '}
                                    </>
                                  ) : null}
                                  <td>{variant?.size}</td>
                                  <ColumnResizer className="columnResizer" />
                                  <td>{variant?.gtin}</td>
                                  <ColumnResizer className="columnResizer" />
                                  <td>{sales?.category}</td>
                                  <ColumnResizer className="columnResizer" />
                                  <td>{variant?.order_count}</td>
                                  <ColumnResizer className="columnResizer" />
                                  <td>{variant?.no_of_Sold_Items}</td>
                                  <ColumnResizer className="columnResizer" />
                                  <td>{variant?.no_of_Sold_Items_After_Return}</td>
                                  <ColumnResizer className="columnResizer" />
                                  <td>{variant?.return_Rate}</td>
                                  <ColumnResizer className="columnResizer" />
                                  <td>
                                    {variant?.return_Reason
                                      ?.replaceAll('Unknown,', '')
                                      ?.replaceAll(',Unknown', '')
                                      ?.replaceAll('Unknown', '')}
                                  </td>
                                  <ColumnResizer className="columnResizer" />
                                  {!(businessModelId === '10001' && categoryId === '10001') ? (
                                    <>
                                      <td>{variant?.stock}</td> <ColumnResizer className="columnResizer" />
                                    </>
                                  ) : null}
                                  {businessModelId === '10002' ? (
                                    <>
                                      <td>
                                        {sales?.approval_Rate ? Math.floor(sales?.approval_Rate * 100) + '%' : ''}
                                      </td>{' '}
                                      <ColumnResizer className="columnResizer" />
                                    </>
                                  ) : null}
                                  {businessModelId === '10002' ? (
                                    <>
                                      <td>{sales?.approval_Delay ? secondsToHms(sales?.approval_Delay) : ''}</td>{' '}
                                      <ColumnResizer className="columnResizer" />
                                    </>
                                  ) : null}
                                </tr>
                              ))}
                            </tr>
                          ) : null}
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
              <Row className="pagination" key="Pagination">
                <Col xs={4} sm={4} md={4}></Col>
                <Col xs={4} sm={4} md={4} className="d-flex justify-content-center text-center">
                  {finalSalesData?.length > 0 ? (
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: any) => setCurrentPage(page)}
                    />
                  ) : null}
                </Col>
                <Col xs={4} sm={4} md={4}></Col>
              </Row>
            </>
          ) : (
            <div className="mt-3 text-center">{emptyMessage}</div>
          )}
        </div>
      </div>
      {showLoader ? <Loader /> : ''}
      {showError ? (
        <div className="alert alert-danger show-failed">
          <strong>Error!</strong> {notifyText}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DataTable;
