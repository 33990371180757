import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import closeIcon from '../../assets/images/close-icon.png';
import { deletePartner } from '../../services/PartnersService';
import Loader from '../../components/Shared/Loader';

const DeletePartner = (props: any) => {
  const [showLoader, setShowLoader] = useState(false);
  const [isDeleted, setIsDeleted] = useState(true);

  const handleCheckBox = (e: any) => {
    setIsDeleted(true);
    if (e.target.checked) {
      setIsDeleted(false);
    }
  };

  const deletePartnerDetail = () => {
    setShowLoader(true);
    deletePartner(props.selectedPartner.partnerId)
      .then(function (data) {
        setShowLoader(false);
        props.deleteSuccess();
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  return (
    <div className="popup-backdrop">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6>
            Delete partner{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
            >
              <img src={closeIcon} alt="x" />
            </span>
          </h6>
        </div>

        <div className="addUser-popup--body">
          <div className="addUser-popup--body--delete-user">
            <h6 className="Heading fontFamilySecondary">Are you sure you want to delete this partner?</h6>
            <p>Please note that all users & entities connected to this partner will also be deleted.</p>

            <div className="delete-box">
              <Row>
                <Col xs={10} sm={10} md={10}>
                  <h6 className="Heading fontFamilySecondary">{props.selectedPartner.partnerName}</h6>
                </Col>
                <Col xs={2} sm={2} md={2}>
                  <input type="checkbox" onChange={(e) => handleCheckBox(e)} />
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div className="addUser-popup--footer">
          <Button
            className="Button"
            disabled={isDeleted}
            onClick={() => {
              deletePartnerDetail();
            }}
          >
            Delete partner
          </Button>
        </div>
      </div>
      {showLoader ? <Loader /> : ''}
    </div>
  );
};

export default DeletePartner;
