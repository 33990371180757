export const updatePartner = (user: any) => {
  return {
    type: 'partner/updatePartner',
    payload: user,
  };
};

export const getPartner = () => {
  return {
    type: 'partner/getPartner',
  };
};
