import axios from 'axios';
import getDetails from '../api/EnviromentConfig';

let campaignUrl: string = getDetails().apiurl;
let subscriptionKey: string = getDetails().subscriptionKey;

export async function uploadCampaign(formData: any, partnerId: string) {
  try {
    // let formData: any = new FormData();
    // formData.append('file', files);

    const response = await axios({
      method: 'POST',
      url: campaignUrl + 'Campaign/UploadAssets?partnerId=' + partnerId,
      headers: { 'Content-Type': 'multipart/form-data', 'Ocp-Apim-Subscription-Key': subscriptionKey },
      data: formData,
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getUploadedCampaign(partnerId: string) {
  try {
    const response = await axios({
      method: 'GET',
      url: campaignUrl + 'Campaign/GetAssets?partnerId=' + partnerId,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function deleteCampaign(uniqueIds: any) {
  try {
    let selectedId = uniqueIds.toString();
    const response = await axios({
      method: 'DELETE',
      url: campaignUrl + 'Campaign/DeleteAssets?selectedId=' + selectedId,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function sendForApproval(username: string, brand: string, market: string, campaigns: any) {
  try {
    let selectedCampaigns: string = campaigns.toString();

    const response = await axios({
      method: 'PUT',
      url:
        campaignUrl +
        'Campaign/SendAssets?username=' +
        username +
        '&brand=' +
        brand +
        '&countries=' +
        market +
        '&selectedId=' +
        selectedCampaigns,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}
