import { useState, useEffect } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateDownloadCampaign } from '../../store/actions/campaignDownloadAction';
import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import closeIcon from '../../assets/images/close-icon.png';
import './index.scss';
import Loader from '../../components/Shared/Loader';

const CampaignDownload = (props: any) => {
  const [showLoader, setShowLoader] = useState(false);
  const [campaign, setCampaign] = useState('');
  const [selectedUsage, setSelectedUsage] = useState('');
  const [selectedUsageValue, setSelectedUsageValue] = useState('');
  const filterData = useSelector((state: RootStateOrAny) => state.selectedCampaignDownload);

  const dispatch = useDispatch();

  const usageList: any = [
    { label: 'BrandPage', value: 'BrandPage' },
    { label: 'Campaign', value: 'Campaign' },
  ];

  useEffect(() => {
    // getFilters();
    if (filterData.selectedCampaignDownload) {
      setCampaign(filterData.selectedCampaignDownload.selectedCampaign);
      setSelectedUsage(filterData.selectedCampaignDownload.selectedUsage);
      handleUsage(filterData.selectedCampaignDownload.selectedUsage);
    }
  }, []);

  const handleCampaign = (event: any) => {
    setCampaign(event.target.value);
  };

  const handleUsage = (selectedOption: any) => {
    setSelectedUsage(selectedOption);
    setSelectedUsageValue(selectedOption.value);
  };

  const onDownloadCampaign = () => {
    let filterData: any = {
      selectedCampaign: campaign,
      selectedUsage: selectedUsage,
    };
    dispatch(updateDownloadCampaign(filterData));
    props.downloadCampaign(campaign, selectedUsageValue);
  };

  const onDownload = () => {
    if (selectedUsageValue === 'Campaign') {
      if (campaign) {
        onDownloadCampaign();
      }
    } else {
      onDownloadCampaign();
    }
  };

  return (
    <div className="popup-backdrop">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6>
            Download{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
            >
              <img src={closeIcon} alt="x" />
            </span>
          </h6>
        </div>

        <div className="addUser-popup--body">
          <h5 className="text-center heading mb-4">Action</h5>

          <Select value={selectedUsage} onChange={handleUsage} options={usageList} placeholder="Select usage" />

          {selectedUsageValue === 'Campaign' ? (
            <input
              type="text"
              required
              className="form-control"
              value={campaign}
              onChange={handleCampaign}
              placeholder="Enter Campaign ID"
              autoComplete="off"
            />
          ) : null}
        </div>

        <div className="addUser-popup--footer">
          <Button type="submit" onClick={onDownload} className="Button">
            Download
          </Button>
        </div>
        {showLoader ? <Loader /> : ''}
      </div>
    </div>
  );
};

export default CampaignDownload;
