export default function useGetRoles() {
  let partnerData = JSON.parse(window.sessionStorage.getItem('partnerDetail')!);
  let userRole = '';
  if (window.sessionStorage.getItem('userType') === 'Partner') {
    userRole = 'PARTNER';

    // if(sessionStorage.getItem("activatedMode")) {
    //   userRole = 'PARTNER_PRESENTATION_MODE'
    // }
    // console.log("permissions partnerData", partnerData)
    partnerData?.partnerEntity.map((item: any) => {
      //console.log("permission", item)

      if (item?.businessModelId) {
        //PARTNER WHOLESALE FASHION
        if (item?.businessModelId === '10001' && item?.categoryId === '10001') {
          userRole = 'PARTNER_VIA_DC_FASHION';
        }

        //PARTNER WHOLESALE BEAUTY
        if (item?.businessModelId === '10001' && item?.categoryId === '10003') {
          userRole = 'PARTNER_VIA_DC_BEAUTY';
        }

        //DROPSHIP - FASHION
        if (item?.businessModelId === '10002' && item?.categoryId === '10001') {
          userRole = 'PARTNER_DROPSHIP_FASHION';
        }

        //DROPSHIP - BEAUTY
        if (item?.businessModelId === '10002' && item?.categoryId === '10003') {
          userRole = 'PARTNER_DROPSHIP_BEAUTY';
        }

        //DROPSHIP - HOME
        if (item?.businessModelId === '10002' && item?.categoryId === '10002') {
          userRole = 'PARTNER_DROPSHIP_HOME';
        }

        //DROPSHIP - KIDS
        if (item?.businessModelId === '10002' && item?.categoryId === '10004') {
          userRole = 'PARTNER_DROPSHIP_KIDS';
        }

        //WHOLESALE - Kids
        if (item?.businessModelId === '10001' && item?.categoryId === '10004') {
          userRole = 'PARTNER_VIA_DC_KIDS';
        }
      }
    });

    // if(JSON.parse(window.sessionStorage.getItem("partnerDetail")!)?.sapId){
    //   userRole = 'PARTNER_SAP'
    // }

    // if(JSON.parse(window.sessionStorage.getItem("partnerDetail")!)?.supplierId){
    //   userRole = 'PARTNER_SUPPLIERID'
    // }
  } else {
    userRole = 'HMUSER';
    if (sessionStorage.getItem('activatedMode')) {
      userRole = 'HMUSER_PRESENTATION_MODE';
    }
  }

  return userRole;
}
