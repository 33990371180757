import { useState, useEffect, useCallback } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import '../../components/Global/Grid/index.scss';
import './index.scss';
import Button from '../Shared/Button';
import AddPartnerUser from '../AddPartnerUser';
import DeleteUser from '../DeleteUser';
import Notification from '../Notification';
import { dateFormat } from '../../helpers/utilities';
import { getPartnerUsers } from '../../services/PartnersService';
import Loader from '../../components/Shared/Loader';

const PartnerUserTab = () => {
  const [partnerUsers, setPartnerUsers] = useState<any[]>([]);
  const userDetail = useSelector((state: RootStateOrAny) => state.user);
  const partnerDetail = useSelector((state: RootStateOrAny) => state.partner);
  const [showAddUser, setAddUser] = useState(false);
  const [showDeleteUser, setDeleteUser] = useState(false);
  const [showNotification, setNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [user, setUser] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [partnerId, setPartnerId] = useState('');
  const [showFailed, setShowFailed] = useState(false);
  const [userId, setUserId] = useState('');

  const addUserData = {
    title: 'Add user',
    content: 'User has been added!',
    action: 'partnerUser',
  };
  const deleteUserData = {
    title: 'Delete user',
    content: 'User has been deleted!',
    action: 'partnerUser',
  };

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = () => {
    let partnerId: any = '';
    if (partnerDetail.partner && Object.keys(partnerDetail.partner).length !== 0) {
      partnerId = partnerDetail.partner.partnerId;
    } else {
      partnerId = window.sessionStorage.getItem('partnerId');
    }
    setPartnerId(partnerId);
    getPartnerUser(partnerId);
  };

  const getPartnerUser = (partnerid: string) => {
    setShowLoader(true);
    getPartnerUsers(partnerid)
      .then(function (data) {
        setPartnerUsers(data);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const getPartnerUserDetail = () => {
    getPartnerUser(partnerId);
  };

  const getPartnerUsersDetail = () => {
    if (showNotification) {
      closePopup();
    }
  };

  const closePopup = () => {
    setAddUser(false);
    setDeleteUser(false);
    setNotification(false);
    if (showNotification) {
      getPartnerUserDetail();
    }
  };

  const addSuccess = useCallback((event) => {
    setUserId(event);
    closePopup();
    setNotification(true);
    setNotificationData(addUserData);
  }, []);

  const deleteUser = (user: any) => {
    if (userDetail.user?.username == user.email) {
      setShowFailed(true);
      setTimeout(() => {
        setShowFailed(false);
      }, 5000);
    } else {
      setUser(user);
      setDeleteUser(true);
    }
  };

  const deleteSuccess = useCallback((event) => {
    closePopup();
    setNotification(true);
    setNotificationData(deleteUserData);
  }, []);

  return (
    <div className="partnerUser-tab partnerInformation" data-testid="partnerUserTabComponent">
      <div className="users-table">
        <table className="table">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>email</th>
              <th>last login</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {partnerUsers &&
              partnerUsers
                ?.sort((a, b) => a.firstName.localeCompare(b.firstName, 'sv'))
                ?.map((user) => {
                  return (
                    <tr key={user.userId} id={user.userId} className={userId === user.userId ? 'userActive' : ''}>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.email}</td>
                      <td>{user.lastLogin ? dateFormat(new Date(user.lastLogin)) : null}</td>
                      <td className="u-textRight">
                        <Button
                          data-title="Delete user"
                          className="Button Button--round Button--small Button--red Button--title"
                          onClick={() => {
                            deleteUser(user);
                          }}
                          data-testid={'deleteUser' + user.userId}
                        >
                          <i className="icon-Close"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>

      <div className="add-users text-center">
        <Button
          className="Button  Button--whiteBlack"
          onClick={() => {
            setAddUser(!showAddUser);
          }}
          data-testid="addNewUser"
        >
          Add new user
        </Button>
      </div>

      {showAddUser ? <AddPartnerUser partnerId={partnerId} closePopup={closePopup} addSuccess={addSuccess} /> : ''}
      {showDeleteUser ? (
        <DeleteUser partnerId={partnerId} user={user} closePopup={closePopup} deleteSuccess={deleteSuccess} />
      ) : (
        ''
      )}
      {showNotification ? (
        <Notification getPartnerUser={getPartnerUsersDetail} data={notificationData} closePopup={closePopup} />
      ) : (
        ''
      )}
      {showLoader ? <Loader /> : ''}
      {showFailed ? (
        <div className="alert alert-danger show-failed">
          <strong>Failed!</strong> You can not delete yourself.
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PartnerUserTab;
