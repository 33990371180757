import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss';
const MultiImageBlock = (props: any) => {
  return (
    <>
      <div className="Article-content" id={props.block.data?.id} data-testid="MultiImageBlock">
        <div className={'Article-content--multiImage'}>
          <Row>
            <Col lg={10}>
              <h3 className="Heading">{props.block.data?.heading}</h3>
              <p className="u-marginBlg">
                <div dangerouslySetInnerHTML={{ __html: props.block.data?.text }} />
              </p>
            </Col>
            <Col lg={12}>
              <div className="multiImgeWrapper">
                {props.block.data?.images.map((image: any) => {
                  return <img src={image}></img>;
                })}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default MultiImageBlock;
