export const ROLES = {
  partner: 'PARTNER',
  hmuser: 'HMUSER',

  partnerPresentationMode: 'PARTNER_PRESENTATION_MODE',
  hmuserPresentationMode: 'HMUSER_PRESENTATION_MODE',

  partnerDropShipFashion: 'PARTNER_DROPSHIP_FASHION',
  partnerDropShipHome: 'PARTNER_DROPSHIP_HOME',
  partnerDropShipBeauty: 'PARTNER_DROPSHIP_BEAUTY',

  partnerViaDcFashion: 'PARTNER_VIA_DC_FASHION',
  partnerViaDcBeauty: 'PARTNER_VIA_DC_BEAUTY',

  partnerDropShip: 'PARTNER_DROP_SHIP',
  partnerViaDc: 'PARTNER_VIA_DC',
  partnerSap: 'PARTNER_SAP',

  partnerDropShipKids: 'PARTNER_DROPSHIP_KIDS',
  partnerViaDcKids: 'PARTNER_VIA_DC_KIDS',
};

export const SCOPES = {
  canAccessSupport: 'can-access-support',
  canAccessSizes: 'can-access-sizes',
  canAccessDashboard: 'can-access-dashboard',
  canApproveCampaigns: 'can-approve-campaigns',
  canCreateCampaigns: 'can-create-campaigns',
  canAccessPresentationMode: 'can-access-presentation-mode',
  canAccessInsights: 'can-access-insights',
  canAccessPartnerProfile: 'can-access-partner-profile',
  canAccessPartnerAccount: 'can-access-partner-account',
  canAccessHmUsers: 'can-access-hm-users',
  canAccessContacts: 'can-access-contacts',
  canAccessCompanyInfo: 'can-access-company-info',
  canAccessContactsViaDc: 'can-access-contacts-via-dc',
  canAccessContactsDropship: 'can-access-contacts-via-dropship',
  canAccessDropShipInvoice: 'can-access-drop-ship-invoice',
};

export const PERMISSIONS = {
  [ROLES.partner]: [
    SCOPES.canCreateCampaigns,
    SCOPES.canAccessDashboard,
    SCOPES.canAccessPartnerAccount,
    SCOPES.canAccessSupport,
    SCOPES.canAccessContacts,
    SCOPES.canAccessCompanyInfo,
    //SCOPES.canAccessPartnerProfile,
  ],

  [ROLES.partnerPresentationMode]: [SCOPES.canAccessPresentationMode],

  [ROLES.partnerDropShipFashion]: [
    SCOPES.canAccessDashboard,
    SCOPES.canAccessInsights,
    SCOPES.canAccessSizes,
    SCOPES.canCreateCampaigns,
    SCOPES.canAccessSupport,
    SCOPES.canAccessContacts,
    SCOPES.canAccessCompanyInfo,
    //SCOPES.canAccessPartnerProfile,
    SCOPES.canAccessPartnerAccount,
    SCOPES.canAccessDropShipInvoice,
  ],

  [ROLES.partnerDropShipBeauty]: [
    SCOPES.canAccessDashboard,
    SCOPES.canAccessInsights,
    SCOPES.canAccessSizes,
    SCOPES.canCreateCampaigns,
    SCOPES.canAccessSupport,
    SCOPES.canAccessContacts,
    SCOPES.canAccessCompanyInfo,
    //SCOPES.canAccessPartnerProfile,
    SCOPES.canAccessPartnerAccount,
    SCOPES.canAccessDropShipInvoice,
  ],

  [ROLES.partnerDropShipHome]: [
    SCOPES.canAccessDashboard,
    SCOPES.canAccessInsights,
    SCOPES.canAccessSizes,
    SCOPES.canCreateCampaigns,
    SCOPES.canAccessSupport,
    SCOPES.canAccessContacts,
    SCOPES.canAccessCompanyInfo,
    //SCOPES.canAccessPartnerProfile,
    SCOPES.canAccessPartnerAccount,
    SCOPES.canAccessDropShipInvoice,
  ],

  [ROLES.partnerViaDc]: [
    SCOPES.canAccessDashboard,
    SCOPES.canCreateCampaigns,
    SCOPES.canAccessSizes,
    SCOPES.canAccessSupport,
    SCOPES.canAccessContacts,
    SCOPES.canAccessCompanyInfo,
    // SCOPES.canAccessPartnerProfile,
    SCOPES.canAccessPartnerAccount,
  ],

  [ROLES.partnerViaDcFashion]: [
    SCOPES.canAccessDashboard,
    SCOPES.canAccessInsights,
    SCOPES.canAccessSizes,
    SCOPES.canCreateCampaigns,
    SCOPES.canAccessSupport,
    SCOPES.canAccessContacts,
    SCOPES.canAccessCompanyInfo,
    //SCOPES.canAccessPartnerProfile,
    SCOPES.canAccessPartnerAccount,
  ],

  [ROLES.partnerViaDcBeauty]: [
    SCOPES.canAccessDashboard,
    SCOPES.canCreateCampaigns,
    SCOPES.canAccessSizes,
    SCOPES.canAccessSupport,
    SCOPES.canAccessContacts,
    SCOPES.canAccessCompanyInfo,
    //SCOPES.canAccessPartnerProfile,
    SCOPES.canAccessPartnerAccount,
  ],

  [ROLES.partnerDropShipKids]: [
    SCOPES.canAccessDashboard,
    SCOPES.canAccessInsights,
    SCOPES.canAccessSizes,
    SCOPES.canCreateCampaigns,
    SCOPES.canAccessSupport,
    SCOPES.canAccessContacts,
    SCOPES.canAccessCompanyInfo,
    //SCOPES.canAccessPartnerProfile,
    SCOPES.canAccessPartnerAccount,
    SCOPES.canAccessContactsDropship,
    SCOPES.canAccessDropShipInvoice,
  ],

  [ROLES.partnerViaDcKids]: [
    SCOPES.canAccessDashboard,
    SCOPES.canAccessInsights,
    SCOPES.canAccessSizes,
    SCOPES.canCreateCampaigns,
    SCOPES.canAccessSupport,
    SCOPES.canAccessContacts,
    SCOPES.canAccessCompanyInfo,
    //SCOPES.canAccessPartnerProfile,
    SCOPES.canAccessPartnerAccount,
    SCOPES.canAccessContactsViaDc,
  ],

  // [ROLES.partnerSap]: [
  //   SCOPES.canAccessSupport
  // ],

  [ROLES.hmuser]: [
    SCOPES.canApproveCampaigns,
    SCOPES.canAccessSupport,
    SCOPES.canAccessPartnerProfile,
    SCOPES.canAccessHmUsers,
    SCOPES.canAccessPresentationMode,
  ],

  [ROLES.hmuserPresentationMode]: [SCOPES.canAccessPresentationMode],
};
