import { useState, useEffect, useRef } from 'react';
import './index.scss';

const ExportDropdown = (props: any) => {
  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState<any>(props.data);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleDropdown = () => setOpen(!isOpen);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);

  const handleItemClick = (item) => {
    setSelectedItem(item.value);
    props.onChange(item);
    toggleDropdown();
  };

  return (
    <div className="exportDropdown" data-testid="exportDropdown">
      <div className="dropdown" ref={ref}>
        <div className="dropdown-header" onClick={toggleDropdown} data-testid="exportButton">
          <i className="icon-Download"></i>
          <span className="text">Export</span>
        </div>
        <div className={`dropdown-body ${isOpen && 'open'}`} data-testid="dropdownBody">
          {items.map((item) => (
            <div
              key={'exportLevel' + item.value}
              className={`dropdown-item ${item.value == selectedItem && 'selected'}`}
              onClick={(e) => handleItemClick(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExportDropdown;
