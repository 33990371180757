import axios from 'axios';
import getDetails from '../api/EnviromentConfig';

let sizeUrl: string = getDetails().apiurl;
let ocpApimSubscriptionKey: string = getDetails().subscriptionKey;

// function getCred(){
//     var data = sessionStorage.getItem('hapiToken');
//     if (data != null && data != '' && data != undefined) {
//       return data;
//     }
//     else {
//       return null;
//     }
// }

//Add sizes to hapi
export async function getSize(partnerHubId: any) {
  try {
    let data = {
      partnerHubId: partnerHubId,
      // token : getCred()
    };

    const response = await axios({
      method: 'POST',
      url: sizeUrl + '/Size/GetPartnerHubData',
      data: data,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Content-Type': 'application/problem+json; charset=utf-8',
      },
    });
    return response;
  } catch {
    return null;
  }
}

//Update sizes
export async function updateSizeHapi(size: any) {
  try {
    // let data = {
    //     size : size,
    //     token : getCred()
    // }

    const response = await axios({
      method: 'PUT',
      url: sizeUrl + '/Size/PutPartnerHub',
      data: size,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Content-Type': 'application/problem+json; charset=utf-8',
      },
    });
    return response;
  } catch {
    return null;
  }
}

//Add sizes to hapi
export async function addSizesToHapi(size: any) {
  try {
    // let data = {
    //     size : size,
    //     token : getCred()
    // }

    const response = await axios({
      method: 'POST',
      url: sizeUrl + '/Size/PostPartnerHub',
      data: size,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Content-Type': 'application/problem+json; charset=utf-8',
      },
    });
    return response;
  } catch {
    return null;
  }
}

//Delete size
export async function deleteSize(partnerHubId: any) {
  try {
    const response = await axios({
      method: 'POST',
      url: sizeUrl + '/Size/DeleteSize?partnerHubId=' + partnerHubId,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Content-Type': 'application/problem+json; charset=utf-8',
      },
    });
    return response;
  } catch {
    return null;
  }
}

//Update size success
export async function updateSizeState(partnerHubId: any, state: boolean) {
  try {
    const response = await axios({
      method: 'POST',
      url: sizeUrl + '/Size/UpdateSizeState?partnerHubId=' + partnerHubId + '&state=' + state,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
    });
    return response;
  } catch {
    return null;
  }
}

export async function updateSize(size: any, partnerId: any) {
  try {
    const response = await axios({
      method: 'POST',
      url:
        sizeUrl +
        `/Size/UpdateSize?customergroup=${size.customerGroup}&tradeMark=${size.trademark}&productType=${size.productType}&partnerId=${partnerId}&partnerHubId=${size.guid}`,
      headers: { 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

//Insert size and return sizeid API
export async function insertSize(size: any, partnerId: any) {
  try {
    const response = await axios({
      method: 'POST',
      url:
        sizeUrl +
        `/Size/InsertSize?customergroup=${size.customerGroup}&tradeMark=${size.trademark}&productType=${size.productType}&partnerId=${partnerId}&partnerHubId=${size.guid}`,
      headers: { 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

//Insert size
export async function insertSizeDescription(size: any) {
  try {
    const response = await axios({
      method: 'POST',
      url:
        sizeUrl + `/Size/InsertSizeDescription?sizeRegion=${size.region}&sizeName=${size.name}&sizeId=${size.sizeId}`,
      headers: { 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

//Get size list from the backed
export async function getDistinctSizes(partnerId: any) {
  try {
    const response = await axios({
      method: 'GET',
      url: sizeUrl + '/Size/GetDistinctSizes?partnerId=' + partnerId + '',
      headers: { 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getTrademarks(partnerId: string) {
  try {
    const response = await axios({
      method: 'GET',
      url: sizeUrl + '/Size/GetEntityBrands?partnerId=' + partnerId,
      headers: { 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getSizeRegion() {
  try {
    const response = await axios({
      method: 'GET',
      url: sizeUrl + '/Size/GetSizeRegion',
      headers: { 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}
