import './index.scss';
import TwoColumnBlock from './TwoColumnBlock';
import QuoteBlock from './QuoteBlock';
import VideoBlock from './VideoBlock';
import TeaserContainerBlock from './TeaserContainerBlock';
import TableBlock from './TableBlock';
import TextBlock from './TextBlock';
import DocumentDownloadBlock from './DocumentDownloadBlock';
import MultiImageBlock from './MultiImageBlock';
import ImageBlock from './ImageBlock';
import AccordionBlock from './AccordionBlock';
import ImageDescriptionBlock from './ImageDescriptionBlock';

const Block = (props: any) => {
  let renderControl;

  switch (props.block.contentTypeFriendly) {
    case 'TwoColumnBlock':
      renderControl = <TwoColumnBlock block={props.block} key={props.key}></TwoColumnBlock>;
      break;
    case 'QuoteBlock':
      renderControl = <QuoteBlock block={props.block} key={props.key}></QuoteBlock>;
      break;
    case 'VideoBlock':
      renderControl = <VideoBlock block={props.block} key={props.key}></VideoBlock>;
      break;
    case 'TeaserContainerBlock':
      renderControl = <TeaserContainerBlock block={props.block} key={props.key}></TeaserContainerBlock>;
      break;
    case 'TableBlock':
      renderControl = <TableBlock block={props.block} key={props.key}></TableBlock>;
      break;
    case 'TextBlock':
      renderControl = <TextBlock block={props.block} key={props.key}></TextBlock>;
      break;
    case 'DocumentDownloadBlock':
      renderControl = <DocumentDownloadBlock block={props.block} key={props.key}></DocumentDownloadBlock>;
      break;
    case 'MultiImageBlock':
      renderControl = <MultiImageBlock block={props.block} key={props.key}></MultiImageBlock>;
      break;
    case 'ImageBlock':
      renderControl = <ImageBlock block={props.block} key={props.key}></ImageBlock>;
      break;
    case 'AccordionBlock':
      renderControl = <AccordionBlock block={props.block} key={props.key}></AccordionBlock>;
      break;
    case 'MultiImageBlockShortDescription':
      renderControl = <ImageDescriptionBlock block={props.block} key={props.key}></ImageDescriptionBlock>;
      break;
    default:
      renderControl = null;
  }
  return renderControl;
};
export default Block;
