import './index.scss';
import logoBlack from '../../../assets/images/h&m_group_black_logo.svg';
import logoWhite from '../../../assets/images/h&m_group_white_logo.svg';
import Button from '../Button';
import Link from '../../Global/Link';
const Header = (props: any) => {
  return (
    <header className={props.classes} data-testid="header-component">
      <div className="Header-logoWrapper">
        <img src={logoBlack} alt="Logo" data-testid="logoBlack" className="Header-logo Header-logo--black" />
        <img src={logoWhite} alt="Logo" data-testid="logoWhite" className="Header-logo Header-logo--white" />
      </div>
      <div className="Header-actions">
        <Button className="Button Button--white Button--outlined">Contact support</Button>
        <Link className="Link Link--arrow" href="#">
          Login as H&M
        </Link>
      </div>
    </header>
  );
};

export default Header;
