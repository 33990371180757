import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../components/Global/Grid/index.scss';
import './index.scss';
import Button from '../../components/Shared/Button';
import Loader from '../../components/Shared/Loader';
import Constants from '../../types/Constants';
import getDetails from '../../api/EnviromentConfig';

const PresentationMode = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [notifyText, setNotifyText] = useState('');
  const presentationPartnerId = getDetails().insightsPartnerIds?.[0];
  const roles = [
    {
      modeId: 'profile1',
      mode: 'Dropship - Fashion',
      value: 'Fashion',
      categoryId: '10001',
      businessModelId: '10002',
      modeName: 'Dropship',
    },
    {
      modeId: 'profile2',
      mode: 'Dropship - Home',
      value: 'Home',
      categoryId: '10002',
      businessModelId: '10002',
      modeName: 'Dropship',
    },
    {
      modeId: 'profile3',
      mode: 'Dropship - Kids',
      value: 'Kids',
      categoryId: '10004',
      businessModelId: '10002',
      modeName: 'Dropship',
    },
    {
      modeId: 'profile4',
      mode: 'Wholesale - Beauty',
      value: 'Beauty',
      categoryId: '10003',
      businessModelId: '10001',
      modeName: 'Wholesale',
    },
    {
      modeId: 'profile5',
      mode: 'Wholesale - Fashion',
      value: 'Fashion',
      categoryId: '10001',
      businessModelId: '10001',
      modeName: 'Wholesale',
    },
    {
      modeId: 'profile6',
      mode: 'Wholesale - Kids',
      value: 'Kids',
      categoryId: '10004',
      businessModelId: '10001',
      modeName: 'Wholesale',
    },
  ];

  const resetPresentation = () => {
    sessionStorage.removeItem('activatedMode');
    sessionStorage.removeItem('currentPartnerUser');
    sessionStorage.setItem('userType', 'HmUser');
    sessionStorage.removeItem('partnerId');
    sessionStorage.removeItem('partnerDetail');
    window.location.reload();
  };

  const activateRole = (role: any) => {
    sessionStorage.setItem('activatedMode', role.modeId);

    const partnerDetails = {
      partnerId: presentationPartnerId,
      partnerName: 'HM Demo',
      companyEmail: '',
      phoneNumber: '',
      isEinvoicingEnabled: true,
      isOnboarded: true,
      shopId: '9999,2077,2078,2002',
      supplierId: '999999',
      sapId: '123',
      gln: '',
      trademarkName: 'H&M (Corporate H&M Brand)',
      partnerEntity: [
        {
          entityId: '2fb6886d-dcb3-4a64-a',
          category: role.value,
          categoryId: role.categoryId,
          businessModel: role.modeName,
          businessModelId: role.businessModelId,
          integrationType: 'Unknown',
          integrationTypeId: '10005',
          brand: 'H&M',
        },
      ], // []
    };

    const partnerDetailJson = JSON.stringify(partnerDetails);

    //dispatch(updateCurrentPartnerUser(partnerDetails));

    sessionStorage.setItem(Constants.SESSIONSTORAGE_CURRENTPARTNERUSER, partnerDetailJson);
    sessionStorage.setItem(Constants.SESSIONSTORAGE_PARTNERDETAIL, partnerDetailJson);
    sessionStorage.setItem('partnerId', presentationPartnerId);
    sessionStorage.setItem('userType', 'Partner');

    window.location.href = '/dashboard';

    // let headersContent = {
    //     'Content-type': 'application/json; charset=UTF-8',
    //     Authorization: 'Bearer ' + window.sessionStorage.getItem('idToken'),
    // };

    // const entity = {
    //     'partnerId': "10001", //UPDATE TO CURRENT PARTNER ID
    //     'categoryId': ""+ role.categoryId +"", // 10001 = Fashion, 10002 = Home, 10003 = Beauty, 10004 = Fashion
    //     'businessModelId':""+ role.businessModelId +"", // 10001 = Dropship, 10002 = Wholesale
    //     'marketId': 'SWE',
    //     'brandId': "1",
    //     'integrationTypeId': "10001" // 10001 = Manual, 10002 = API, 10003 = Aggregator, 10004 = B24, 10005 = Else
    // }

    // const currentPartnerUser = sessionStorage.getItem("currentPartnerUser") ? JSON.parse(sessionStorage.getItem("currentPartnerUser") as string) : null;

    // console.log("currentPartnerUser", currentPartnerUser)

    // if(currentPartnerUser){
    //     deletePartnerEntity(currentPartnerUser).then(function (response: any) {
    //     })
    //     .catch(function (error) {
    //         console.error(error);
    //         setIsLoading(false);
    //     });
    // }

    // createPartnerEntity(entity).then(function (data: any){

    //     console.log("createPartnerEntity", data)

    //     axios({
    //         withCredentials: true,
    //         method: 'POST',
    //         url: process.env.REACT_APP_CMS_COOKIE + '?entityId=' + data,
    //         headers: headersContent,
    //     })
    //     .then(function (resp) {

    //         const partnerDetails = {
    //             "partnerId": "10007",
    //             "partnerName": "Rodde",
    //             "companyEmail": "NULL",
    //             "phoneNumber": "",
    //             "isOnboarded": true,
    //             "shopId": "",
    //             "supplierId": "",
    //             "sapId": "",
    //             "gln": "NULL",
    //             "trademarkName": "Neuw,COSRX",
    //             "market": 'SWE', // "United Kingdom"
    //             "marketId": '2',// "UK"
    //             "partnerEntity": [{
    //                 "entityId": "4bdd9e0d-702b-43b4-b",
    //                 "category": role.value,
    //                 "categoryId": role.categoryId,
    //                 "businessModel": role.modeName,
    //                 "businessModelId": role.businessModelId,
    //                 "integrationType": "Manual",
    //                 "integrationTypeId": "10001",
    //                 "brand": "H&M"
    //             }], // []

    //         }
    //         const partnerDetailJson = JSON.stringify(partnerDetails);

    //         //dispatch(updateCurrentPartnerUser(partnerDetails));

    //         sessionStorage.setItem(Constants.SESSIONSTORAGE_CURRENTPARTNERUSER, partnerDetailJson);
    //         sessionStorage.setItem(Constants.SESSIONSTORAGE_PARTNERDETAIL, partnerDetailJson);

    //         setIsLoading(false);

    //         const userData = {
    //             'firstName': 'temp',
    //             'lastName': 'temp',
    //             'email': 'temp@temp.com'
    //         }
    //         const currentPartnerUser = sessionStorage.getItem("currentPartnerUser") ? JSON.parse(sessionStorage.getItem("currentPartnerUser") as string) : null;
    //         createPartnerUser(userData, currentPartnerUser).then(function (data){
    //             setShowLoader(false);
    //             if(data == 'User already exist'){
    //                 console.log('User already exist.');
    //                 setShowFailed(true);
    //             } else {
    //               console.log(data)
    //             }
    //         }).
    //         catch(function (error){
    //             setShowLoader(false);
    //             console.log(error);
    //         })

    //         sessionStorage.setItem("userType", "Partner");

    //         window.location.href = "/dashboard";

    //         console.log(data);
    //     })
    //     .catch(function (error) {
    //         console.error(error);
    //         setIsLoading(false);
    //     });
    // })
  };
  return (
    <div className="hmusers-page">
      <Container fluid="xl">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <h2 className="mt-4 Heading">Presentation mode selector</h2>

            <div className="users-table">
              Please select below the presentation mode you would like to instantiate from.
              <br></br>
              <br></br>
              <table className="table">
                <thead>
                  <tr>
                    <th>Mode</th>
                    {/* <th>lastname</th>
                                        <th>email</th> */}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {roles &&
                    roles.map((role) => {
                      let activatedMode = sessionStorage.getItem('activatedMode');
                      let buttonColor = 'Button Button--small Button--dawnPink mr-2 Button--title';
                      let textValue = 'Activate';
                      if (activatedMode === role.modeId) {
                        buttonColor = 'Button Button--small Button--red mr-2 Button--title';
                        textValue = 'Activated';
                      }

                      return (
                        <tr key={role.modeId}>
                          <td>{role.mode}</td>
                          <td className="u-textRight">
                            <Button
                              className={buttonColor}
                              data-title="Activate this presentation mode"
                              onClick={() => {
                                activateRole(role);
                              }}
                            >
                              {textValue}
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <br></br>
            {sessionStorage.getItem('activatedMode') && (
              <Button
                className={'Button Button--small Button--red mr-2 Button--title'}
                data-title="Exit presentation mode"
                onClick={() => {
                  resetPresentation();
                }}
              >
                Exit presentation mode
              </Button>
            )}
            {showLoader ? <Loader /> : ''}
            {showFailed ? (
              <div className="alert alert-danger show-failed">
                <strong>Failed!</strong> {notifyText}
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PresentationMode;
