import './index.scss';

const Button = (props: any) => {
  const { children, ...other } = props;
  return (
    <button type="button" {...other}>
      {children}
    </button>
  );
};

export default Button;
