import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss';
import Button from '../../../components/Shared/Button';
import BreadcrumbLink from '../../../components/Shared/BreadcrumbLink';
import ExcelImage from '../../../assets/images/excel.jpg';
import PartnerImage from '../../../assets/images/partner.jpg';
import { useHistory } from 'react-router-dom';

const ImportMethods = () => {
  const history = useHistory();
  const breadcrumbLinks = [
    {
      name: 'Size import',
      path: '/sizes',
    },
    {
      name: 'Choose import method',
      path: '/sizes/importmethod',
    },
  ];

  const handleImportExcell = () => {
    let path = '/sizes/importmethod/excell';
    history.push(path);
  };
  const handleImportManual = () => {
    let path = '/sizes/importmethod/manually';
    history.push(path);
  };
  return (
    <div className="Sizes Page-gradient">
      <Container fluid="xl">
        <Row>
          <Col xs={8} sm={8} md={8}>
            <div className="Hero">
              <h1 className="Heading Heading--h2" style={{ color: 'white' }}>
                Size Import
              </h1>
              <BreadcrumbLink links={breadcrumbLinks} />
            </div>

            <h5 style={{ color: 'white' }}>How to import</h5>
            <p>
              In order for us to process your product data, we need to know your sizes and its regional translations in
              advance. There are two different options for you to provide us with your size information.{' '}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={4} md={4}>
            <div className="placeholderImage">
              <img src={ExcelImage} />
            </div>
            <h6 className="heading">Excel Upload</h6>
            <p>Download an excel template, fill in your size information and upload to partner hub.</p>
            <Button className="Button" onClick={handleImportExcell}>
              Import using excel
            </Button>
          </Col>
          <Col xs={4} sm={4} md={4}>
            <div className="placeholderImage">
              <img src={PartnerImage} />
            </div>
            <h6 className="heading">Upload from Partner Hub </h6>
            <p>Upload your size information straight to the Partner Hub.</p>
            <Button className="Button" onClick={handleImportManual}>
              Import manually
            </Button>
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
};

export default ImportMethods;
