import './index.scss';

const VideoBlock = (props: any) => {
  return (
    <>
      <div className="Article-content" data-testid="VideoBlock">
        <div className={'Article-content--media'} id={props.block.data?.id}>
          <div className="Article-content--mediaContent">
            <label>{props.block.data?.overlineText}</label>
            <h3 className="Heading">{props.block.data?.heading}</h3>
            <p>{props.block.data?.description}</p>
          </div>
          <div className="Article-content--media">
            <video controls poster={props.block.data?.splashImage}>
              <source src={props.block.data?.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </>
  );
};
export default VideoBlock;
