import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss';
import Button from '../../../../components/Shared/Button';
import BreadcrumbLink from '../../../../components/Shared/BreadcrumbLink';
import { useHistory } from 'react-router-dom';
import SizesImage from '../../../../assets/images/sizes.jpg';

const ImportManually = () => {
  const history = useHistory();
  const breadcrumbLinks = [
    {
      name: 'Size upload',
      path: '/sizes',
    },
    {
      name: 'Upload new size',
      path: '/sizes/importmethod/manually',
    },
  ];
  const handleManually = () => {
    let path = '/sizes/importmethod/sizestable';
    history.push(path);
  };

  return (
    <div className="Sizes Page-gradient">
      <Container fluid="xl">
        <Row>
          <Col xs={8} sm={8} md={8}>
            <div className="Hero">
              <h1 className="Heading Heading--h2" style={{ color: 'white' }}>
                Size Upload
              </h1>
              <BreadcrumbLink links={breadcrumbLinks} />
            </div>
            <h5 style={{ color: 'white' }}>Upload from Partner Hub</h5>

            <p>In order to understand your sizing, we need the following information:</p>

            <ul>
              <li>Name of the brand that the size belongs to. Only one brand is allowed per upload.</li>
              <li>The customer group, to which the size applies. Multiple choices are allowed.</li>
              <li>The product types for which the size applies. Multiple choices are allowed.</li>
            </ul>

            <p>
              Once these three fields are set, you can proceed to provide the actual sizes along with their respective
              regional translations. Please note that EU sizes are mandatory, and any additional sizes for regions other
              than the EU should be provided with their translated versions on the same row as the EU version. For
              example, US size 3 and UK size 2.5 would be equivalent to EU size 35. See the example below for
              clarification.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={4} md={4}>
            <div className="placeholderImage">
              <img src={SizesImage} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={8} md={8}>
            {/* <p>Your procedures for handling incidents and refunds will be dependent on your choice of integration, your internal practices, your carriers and so on. Your procedures for handling incidents and refunds will be dependent on your choice of integration, your internal pra</p> */}
            <Button className="Button" onClick={handleManually}>
              Go to the uploader
            </Button>
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
};

export default ImportManually;
