import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../Shared/Button';
import './index.scss';
import Loader from '../../components/Shared/Loader';
import { downloadAssets } from '../../services/CampaignInternalService';
import { Link } from 'react-router-dom';

const InstuctionList = (props: any) => {
  const [showLoader, setShowLoader] = useState(false);

  const downloadGuidelines = () => {
    setShowLoader(true);
    let fileName: string = 'UploadCampaignRequirements.zip';
    downloadAssets(fileName)
      .then(function (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  return (
    <Container fluid="xl">
      <div className="InstructionList">
        <Row className="justify-content-md-center">
          <Col xs={12} lg={4}>
            <h2 className="Heading">How it works</h2>
          </Col>
          <Col xs={1}></Col>
          <Col xs={12} lg={5}>
            <ul className="InstructionList-list">
              <li className="InstructionList-listItem" data-testid="instructionListItem">
                <h5 className="Heading">01 - Make sure your assets meet our requirements</h5>
                <p>
                  Explanation of our beauty requirements can be found{' '}
                  <a onClick={downloadGuidelines} data-testid="here">
                    <i className="icon-Download"></i> here
                  </a>
                  .
                </p>
                <p>
                  Explanation of our fashion/home brand page requirements can be found{' '}
                  <Link to="/guidelines/Brand_Page_guide.pdf" target="_blank" download>
                    <i className="icon-Download"></i> here
                  </Link>
                  .
                </p>
              </li>
              <li className="InstructionList-listItem" data-testid="instructionListItem">
                <h5 className="Heading">02 - Start uploading by clicking “Upload Now”</h5>
                <p>A new page will be opened with the uploading tool </p>
              </li>
              <li className="InstructionList-listItem" data-testid="instructionListItem">
                <h5 className="Heading">03 - Approval</h5>
                <p>When your assets have been uploaded, the H&M team will validate the assets.</p>
              </li>
              <li className="InstructionList-listItem" data-testid="instructionListItem">
                <h5 className="Heading">04 - Go live!</h5>
                <p>
                  When assets are uploaded and your commercial discussion with H&M is complete, your assets will go-live
                  on site!{' '}
                </p>
              </li>
            </ul>

            <div className="InstructionList-cta">
              <h5 className="Heading">Asset transfer tool</h5>
              <Button className="Button Button--transparentBlack" onClick={props.handleCampaign}>
                Upload now
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {showLoader ? <Loader /> : <></>}
    </Container>
  );
};

export default InstuctionList;
