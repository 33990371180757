import './index.scss';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Heroimage from '../../../src/assets/images/articleImage.svg';

const ArticleHero = (props: any) => {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  let onBoarding = params.has('from') ? true : false;
  const pageType = props.pageType;

  return (
    <div className=" Hero ArticleHero Hero-gradient printHidden mb-5" data-testid="article-hero-component">
      <Row>
        <Col sm={12} md={12} lg={9} xl={8} className="Hero-content-container">
          <div className={props.isHeroWithBackground ? 'Hero-content-black' : 'Hero-content'}>
            {!props.hasLink ? (
              <Breadcrumb
                onBoarding={onBoarding}
                rootUrl={
                  onBoarding
                    ? '/onboarding?from=onboarding'
                    : pageType === 'LearnPage'
                    ? '/learn?entity=selected'
                    : '/sustainability?entity=selected'
                }
                rootName={props.heading ? props.heading : 'Article'}
                pageOn={onBoarding ? 'Onboarding' : pageType === 'LearnPage' ? 'Learn' : 'Sustainability'}
              ></Breadcrumb>
            ) : null}
            <h1 className="Heading Hero-heading">{props.heading ? props.heading : 'Creating article data'}</h1>
          </div>
        </Col>
        <Col xs={12} md={8} lg={4} xl={4} className="p-0">
          <div
            className="Hero-multiImageWrapper"
            style={{
              backgroundImage: Heroimage,
              height: '100%',
              width: '100%',
              textAlign: 'right',
            }}
          >
            <img
              className={props.isHeroWithBackground ? 'Hero-multiImage-fullwidth' : 'Hero-multiImage'}
              src={Heroimage}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ArticleHero;
