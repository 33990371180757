import './index.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Quote = (props: any) => {
  return (
    <div className="Quote">
      <div className="Quote-bg"></div>
      <div className="Quote-content">
        <Row>
          <Col lg={8}>
            <h2 className="Heading Quote-heading">
              H&M was the obvious choice
              <br />
              for us to be able to reach
              <br />
              the international market.
            </h2>
          </Col>
          <Col lg={4}>
            <div className="u-posRelative">
              <img className="Quote-image" src="https://picsum.photos/252/336"></img>
              <div className="Quote-sender">
                <span className="Quote-name">Johan Johansson</span>
                <span className="Quote-company">L’Oreal</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Quote;
