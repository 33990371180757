import { useState, useEffect } from 'react';
import './index.scss';
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';
import moment from 'moment-timezone';

const ClockC = () => {
  const [value, setValue] = useState(new Date());
  const interval = setInterval(() => setValue(new Date()), 1000);
  const [cetTime, setCetTime] = useState('');
  const [istTime, setIstTime] = useState('');
  const [usTime, setUsTime] = useState('');

  useEffect(() => {
    showTime();
    setInterval(() => {
      showTime();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  });

  const showTime = () => {
    let date: any = moment(new Date());
    let cettime: any = date.tz('Europe/Stockholm').format('HH:mm z');
    setCetTime(cettime);
    let isttime: any = date.tz('Asia/Kolkata').format('HH:mm z');
    setIstTime(isttime);
    let ustime: any = date.tz('America/New_York').format('HH:mm z');
    setUsTime(ustime);
  };

  return (
    <div className="Clock timestamp">
      <div className="Clock-content">
        <div className="Clock-graphic">
          <Clock value={value} />
        </div>
        <div className="Clock-times">
          <ul className="Clock-list">
            <li className="Clock-listItem">
              <span className="Clock-timeType">{cetTime}</span>
            </li>
            <li className="Clock-listItem">
              <span className="Clock-timeType">{usTime}</span>
            </li>
            <li className="Clock-listItem">
              <span className="Clock-timeType">{istTime}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ClockC;
