import './index.scss';
import Container from 'react-bootstrap/Container';

interface TocItem {
  text: string;
  blockId: number;
}

const Toc = (props: any) => {
  return (
    <Container fluid className="u-textCenter printHidden">
      <h4 className="Heading Toc-Heading">Table of content</h4>
      <nav className="Toc">
        <ul className="Toc-list">
          {props.items &&
            props.items.map((item: TocItem, index) => (
              <li className="Toc-listItem" key={index}>
                <a className="Toc-listLink" href={`#${item.blockId}`} data-testid="toc-list-link">
                  {item.text}
                </a>
              </li>
            ))}
        </ul>
      </nav>
    </Container>
  );
};

export default Toc;
