import axios from 'axios';
import getDetails from '../api/EnviromentConfig';
let PartnerUrl: string = getDetails().apiurl;
let subscriptionKey: string = getDetails().subscriptionKey;

export async function getAllPartners() {
  try {
    const response = await axios({
      method: 'GET',
      url: PartnerUrl + 'Partner/GetAllPartners',
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getPartnerDetail(partnerId: string) {
  try {
    const response = await axios({
      method: 'GET',
      url: PartnerUrl + 'Partner/GetPartner?partnerId=' + partnerId,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getPartnerUsers(partnerId: string) {
  try {
    const response = await axios({
      method: 'GET',
      url: PartnerUrl + 'User/GetPartnerUsersPartnerID?partnerId=' + partnerId,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getUser(email: string) {
  try {
    const response = await axios({
      method: 'GET',
      url: PartnerUrl + 'User/GetUser?email=' + email,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response;
  } catch {
    return null;
  }
}

export async function UpdateUserLogin(userId: string) {
  try {
    const response = await axios({
      method: 'PUT',
      url: PartnerUrl + 'User/UpdateUserLogin?userId=' + userId,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function createPartner(partner: any) {
  try {
    const response = await axios({
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
      method: 'POST',
      url: PartnerUrl + 'Partner/CreatePartner',
      data: partner,
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function updatePartner(partner: any) {
  try {
    const response = await axios({
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
      method: 'PUT',
      url: PartnerUrl + 'Partner/UpdatePartner',
      data: partner,
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function createPartnerUser(partnerUser: any, partnerId: string) {
  try {
    const partnerData = partnerUser;
    partnerData['partnerId'] = partnerId.toString();
    const response = await axios({
      method: 'POST',
      url: PartnerUrl + 'User/Createuser',
      data: partnerData,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function createPartnerEntity(entity: any) {
  try {
    const response = await axios({
      method: 'POST',
      url: PartnerUrl + 'Partner/CreatePartnerEntity',
      data: entity,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function updatePartnerEntity(entity: any) {
  try {
    const response = await axios({
      method: 'PUT',
      url:
        PartnerUrl +
        'Partner/UpdatePartnerEntity?entityId=' +
        entity.entityId +
        '&shopId=' +
        entity.shopId +
        '&spurId=' +
        entity.spurId +
        '&sapId=' +
        entity.sapId,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function deletePartner(partnerId: string) {
  try {
    const response = await axios({
      method: 'DELETE',
      url: PartnerUrl + 'Partner/DeletePartner?partnerId=' + partnerId,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function deletePartnerEntity(entityId: string) {
  try {
    const response = await axios({
      method: 'DELETE',
      url: PartnerUrl + 'Partner/DeletePartnerEntity?entityId=' + entityId,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function deletePartnerUser(email: string, partnerId: string) {
  try {
    const response = await axios({
      method: 'DELETE',
      url: PartnerUrl + 'User/DeleteUser?email=' + email + '&partnerId=' + partnerId,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function updatePartnerOnboarding(partnerId: string, status: boolean) {
  try {
    const response = await axios({
      method: 'PUT',
      url: PartnerUrl + 'Partner/UpdateOnboardingStatus?partnerId=' + partnerId + '&isChecked=' + status,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}
