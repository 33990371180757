import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import './index.scss';
import Button from '../Shared/Button';
// import appInfo from '../../../package.json';
// import EntitySwitch from '../EntitySwitch/EntitySwitch';

const ProfileWidget = (props: any) => {
  const userFullname = sessionStorage.getItem('userName');
  const user = useSelector((state: RootStateOrAny) => state.user);

  return (
    <div className="ProfileWidget" data-testid="profile-widget">
      <div></div>
      <div className="ProfileWidget-content">
        <div className="ProfileWidget-userImage">{/* future image */}</div>
        <div className="ProfileWidget-user">
          <span className="ProfileWidget-userName">{userFullname ? userFullname : user?.user?.name}</span>
          {/* <span className="ProfileWidget-userName">v. {appInfo.version}</span> */}
          {/* <span className='ProfileWidget-userTitle'>Product Designer</span> */}
        </div>
        {/* <div className='Navigation-toggleText'> HIDDEN UNTIL WE ADD SETTINGS FOR PROFILE
                    <a href='#'><i className='icon-Setting-Circle'></i></a>
                </div> */}
      </div>

      <div className="u-textCenter">
        <Button
          className="Button Button--transparentBlack Button--iconFirst ProfileWidget-button"
          onClick={() => {
            props.logOut();
          }}
        >
          <i className="icon-Exit"></i>
          <span className=" Navigation-toggleText">Log out</span>
        </Button>
      </div>
    </div>
  );
};
export default ProfileWidget;
