import { useState, useRef } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import './index.scss';
import Button from '../Shared/Button';
import Loader from '../../components/Shared/Loader';
import { uploadCampaign } from '../../services/CampaignService';

const ProductAdd = (props: any) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const inputFile: any = useRef(null);
  const partnerDetail = useSelector((state: RootStateOrAny) => state.partner);

  const uploadFile = () => {
    inputFile.current.click();
  };

  const handleFileUpload = (e: any) => {
    let partnerId: any = '';
    if (Object.keys(partnerDetail.partner).length !== 0) {
      partnerId = partnerDetail.partner.partnerId;
    } else {
      partnerId = window.sessionStorage.getItem('partnerId');
    }
    setShowLoader(true);
    //let files: any = e.target.files[0];
    const formData = new FormData();
    const uploadFile = (file: any) => {
      formData.append('file', file);
    };

    let files: any = e.target.files;

    for (let i = 0; i < files.length; i++) {
      uploadFile(files[i]);
    }

    uploadCampaign(formData, partnerId)
      .then((response: any) => {
        if (response.length != 0) {
          setShowFailed(true);
          setTimeout(() => {
            setShowFailed(false);
          }, 5000);
        }
        setShowLoader(false);
        props.getUploadCampaigns();
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  return (
    <div className={props.className}>
      <div className="ProductAdd-content">
        <Button
          className="Button Button--round Button--plus  Button--dawnPink Button--large"
          onClick={() => uploadFile()}
          data-testid="addFile"
        ></Button>
        <h4 className="Heading u-textSerif">{props.uploadtext}</h4>
        <span className="ProductAdd-directions">Add up to 2GB</span>
      </div>
      <input
        type="file"
        id="file"
        multiple
        ref={inputFile}
        onChange={handleFileUpload}
        onClick={(event: any) => {
          event.target.value = null;
        }}
        style={{ visibility: 'hidden' }}
      />
      {showLoader ? <Loader /> : ''}
      {showFailed ? (
        <div className="alert alert-danger show-failed">
          <strong>Failed!</strong> You have uploaded invalid image.
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default ProductAdd;
