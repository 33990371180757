import './index.scss';
import smarter1 from '../../../assets/images/Home_Teaser_howtoformatimages.jpg';

const Teaser = (props: any) => {
  return (
    <a href={props.url} className={props.className}>
      <div className="Teaser-image" style={{ backgroundImage: `url(${smarter1})` }}></div>
      <div className={`Teaser-gradient Teaser-gradient--${props.gradientClass}`}>
        <div className="Teaser-content">
          <h3 className="Heading">{props.heading}</h3>
        </div>
      </div>
    </a>
  );
};

export default Teaser;
