import { useEffect, useState, useMemo } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import './index.scss';

const PaginationComponent = ({
  total = 0,
  itemsPerPage = 10,
  currentPage = 1,
  onPageChange,
}: {
  total: any;
  itemsPerPage: any;
  currentPage: any;
  onPageChange: any;
}) => {
  const pages: number[] = [];
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumberLimit, setPageNumberLimit] = useState(4);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(4);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(1);
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0) setTotalPages(Math.ceil(total / itemsPerPage));
  }, [total, itemsPerPage]);

  const paginationItems = useMemo(() => {
    const pages: number[] = [];
    const pagesWithDots: any[] = [];

    if (currentPage === 1) {
      setMaxPageNumberLimit(4);
      setMinPageNumberLimit(1);
    }

    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }

    for (let i of pages) {
      if (i < maxPageNumberLimit + 1 && i >= minPageNumberLimit) {
        pagesWithDots.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
            {i}
          </Pagination.Item>,
        );
      }
    }

    return pagesWithDots;
  }, [totalPages, currentPage]);

  if (totalPages === 0) return null;

  const handleNextbtn = () => {
    onPageChange(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    onPageChange(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  return (
    <Pagination className="Pagination">
      <li key="prev" className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
        <a className="page-link" role="button" onClick={handlePrevbtn}>
          <span className="icon-Arrows-Left"></span>
          <span className="visually-hidden">Previous</span>
        </a>
      </li>
      {minPageNumberLimit > 1 ? <Pagination.Ellipsis /> : null}
      {paginationItems}
      {pages.length > maxPageNumberLimit ? <Pagination.Ellipsis /> : null}
      <li key="next" className={currentPage === totalPages ? 'page-item disabled' : 'page-item'}>
        <a className="page-link" role="button" onClick={handleNextbtn}>
          <span className="icon-Arrows-Right"></span>
          <span className="visually-hidden">Next</span>
        </a>
      </li>
    </Pagination>
  );
};

export default PaginationComponent;
