const getTheme = () => {
  let stylingClass = 'Home';

  let partnerData = JSON.parse(window.sessionStorage.getItem('partnerDetail')!);
  //Check the type of entityuser is logged in as
  let userType = window.sessionStorage.getItem('userType') === 'Partner' ? 'PARTNER' : 'USER';
  if (userType === 'PARTNER') {
    //Check if the user is logged in as a partner
    stylingClass = 'Partner';
    partnerData?.partnerEntity?.map((item: any) => {
      //Check the business model of the partner
      if (item?.businessModelId) {
        //PARTNER WHOLESALE FASHION
        if (item?.businessModelId === '10001' && item?.categoryId?.toString() === '10001') {
          stylingClass = 'Fashion';
        }
        //PARTNER WHOLESALE BEAUTY
        if (item?.businessModelId === '10001' && item?.categoryId?.toString() === '10003') {
          stylingClass = 'Beauty';
        }
        //DROPSHIP - FASHION
        if (item?.businessModelId === '10002' && item?.categoryId?.toString() === '10001') {
          stylingClass = 'Fashion';
        }
        //DROPSHIP - BEAUTY
        if (item?.businessModelId === '10002' && item?.categoryId?.toString() === '10003') {
          stylingClass = 'Beauty';
        }
        //DROPSHIP - HOME
        if (item?.businessModelId === '10002' && item?.categoryId?.toString() === '10002') {
          stylingClass = 'Home';
        }
        //DROPSHIP - KIDS
        if (item?.businessModelId === '10002' && item?.categoryId?.toString() === '10004') {
          stylingClass = 'Kids';
        }
        //WHOLESALE - Kids
        if (item?.businessModelId === '10001' && item?.categoryId?.toString() === '10004') {
          stylingClass = 'Kids';
        }
      }
    });
  }

  return stylingClass;

  // let hasMultiplePartners = window.sessionStorage.getItem("hasMultiplePartners") === "true" ? true : false;
  // if(hasMultiplePartners)	{
  // 	stylingClass = 'Fashion'
  // }
  // else {
  // 	let partnerInfo = sessionStorage.getItem("partnerDetail") ? sessionStorage.getItem("partnerDetail") : 'Home'
  // 	if(partnerInfo !== 'Home' && typeof partnerInfo !== 'undefined') {
  // 			let cat = JSON.parse(partnerInfo!)
  // 			stylingClass = cat?.category ? cat?.category : 'DropShip'
  // 	}
  // }
  // return stylingClass
};
export default getTheme;
