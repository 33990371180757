import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import './index.scss';
import { updatePartner } from '../../services/PartnersService';
import { getAllTradeMark } from '../../services/DataService';
import Loader from '../../components/Shared/Loader';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

const UpdatePartner = (props: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [showLoader, setShowLoader] = useState(false);
  const [trademarksList, setTrademarksList] = useState<any[]>([]);
  const [selectedTrademark, setSelectedTrademark] = useState('');
  const dispatch = useDispatch();

  const onboardedList = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  useEffect(() => {
    getFilters();
  }, []);

  const getFilters = () => {
    setShowLoader(true);
    getAllTradeMark()
      .then(function (data) {
        let trademarks: any = [];
        for (let trademark of data) {
          let trademarkData: any = { value: trademark.brandName, label: trademark.brandName };
          trademarks.push(trademarkData);
        }
        setTrademarksList(trademarks);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    const trademarkSelectedData = props.selectedPartner?.trademarkName
      ? props.selectedPartner?.trademarkName?.split(',')
      : [];

    let trademarks: any = [];
    for (let trademark of trademarkSelectedData) {
      let trademarkData: any = { value: trademark, label: trademark };
      trademarks.push(trademarkData);
    }
    setValue('partnerName', props.selectedPartner?.partnerName);
    setValue('trademarkName', props.selectedPartner?.trademarkName);
    setSelectedTrademark(trademarks);
    setValue('shopId', props.selectedPartner?.shopId);
    setValue('supplierId', props.selectedPartner?.supplierId);
    setValue('sapId', props.selectedPartner?.sapId);
    setValue('isOnboarded', props.selectedPartner?.isOnboarded.toString());
  }, [setValue, props.selectedPartner, trademarksList]);

  const onSubmit = (data: any) => {
    setShowLoader(true);
    data['partnerId'] = props.selectedPartner?.partnerId;
    updatePartner(data)
      .then(function (data: any) {
        setShowLoader(false);
        props.updatePartnerSuccess();
      })
      .catch(function (error) {
        setShowLoader(false);
        console.log(error);
      });
  };

  const handleInputOnChange = (e) => {
    if (e.target.name === 'shopId') {
      setValue(e.target.name, e.target.value.replace(/[^\d,]+/g, ''));
    } else {
      setValue(e.target.name, e.target.value.replace(/[^\d]+/g, ''));
    }
  };

  const handleTrademark = (selectedOption: any) => {
    let selectedTrademarks: any = [];
    for (let trademark of selectedOption) {
      selectedTrademarks.push(trademark.value);
    }
    setSelectedTrademark(selectedOption);
    setValue('trademarkName', selectedTrademarks?.join(','));
  };

  return (
    <div className="popup-backdrop updateEntity">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6 className="Heading">
            Update partner{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
            >
              <i className="icon-Close Popup-close"></i>
            </span>
          </h6>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addUser-popup--body">
            <h5 className="text-center heading mb-4">{props.selectedPartner.partnerName}</h5>

            <label className="tool" data-tip="This field for entering partner name">
              <i className="icon-Info"></i>
            </label>
            <input
              {...register('partnerName', { required: true })}
              className={`form-control ${errors.partnerName ? 'error-control' : ''}`}
              placeholder="Enter partner name"
              autoComplete="off"
            />
            {errors.partnerName && <span>Please enter partner name</span>}

            <label className="tool" data-tip="This field for choosing brand">
              <i className="icon-Info"></i>
            </label>
            <Select
              {...register('trademarkName', { required: false })}
              className={`trademarkMultiDropdown ${errors.trademarkName ? 'error-control' : ''}`}
              isMulti={true}
              value={selectedTrademark}
              onChange={handleTrademark}
              options={trademarksList}
              placeholder="Choose brand"
            />
            {errors.trademarkName && <span>Please choose brand</span>}

            <label
              className="tool"
              data-tip="This Shop-id field needs to be filled to enable the insights feature. Only needed for dropship partners."
            >
              <i className="icon-Info"></i>
            </label>
            <input
              {...register('shopId', { required: false, onChange: (e) => handleInputOnChange(e) })}
              className={`form-control ${errors.shopId ? 'error-control' : ''}`}
              placeholder="Enter Mirakl Shop Id"
              autoComplete="off"
            />
            {errors.shopId && <span>Please enter your Mirakl Shop-id (Min and Max 4 digits)</span>}

            <label
              className="tool"
              data-tip="This Spur-id field needs to be filled to enable the insights feature. Only needed for wholesale partners."
            >
              <i className="icon-Info"></i>
            </label>
            <input
              {...register('supplierId', { required: false, onChange: (e) => handleInputOnChange(e) })}
              className={`form-control ${errors.supplierId ? 'error-control' : ''}`}
              placeholder="Enter Spur Id"
              autoComplete="off"
            />
            {errors.supplierId && <span>Please enter your Spur-id</span>}

            <label className="tool" data-tip="This field for choosing onboarded">
              <i className="icon-Info"></i>
            </label>
            <select
              {...register('isOnboarded', { required: false })}
              className={`form-control ${errors.isOnboarded ? 'error-control' : ''}`}
            >
              <option value="">Choose onboarded</option>
              {onboardedList &&
                onboardedList.map((item: any, index) => {
                  return (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  );
                })}
            </select>
            {errors.isOnboarded && <span>Please choose onboarded</span>}
          </div>

          <div className="addUser-popup--footer">
            <Button type="submit" className="Button" data-testid="update-partner-form">
              Save
            </Button>
          </div>
        </form>
        {showLoader ? <Loader /> : ''}
      </div>
    </div>
  );
};

export default UpdatePartner;
