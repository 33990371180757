import axios from 'axios';

const instance = axios.create({
  withCredentials: true,
  headers: {
    Authorization: 'Bearer ' + window.sessionStorage.getItem('idToken'),
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
});

//API
const CmsUrl = process.env.REACT_APP_CMS_API;
const CmsSearchUrl = process.env.REACT_APP_CMS_SEARCH_API;

export async function search(url: string) {
  try {
    const response = await instance({
      method: 'GET',
      url: CmsSearchUrl + url,
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getPage(url: string) {
  try {
    const response = await instance({
      method: 'GET',
      url: CmsUrl + url,
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getArticles(categoryId: any, businessModelId: any, pageType: any) {
  try {
    const response = await instance({
      method: 'GET',
      url:
        CmsSearchUrl +
        '/partnerportal/getArticles?businessModelId=' +
        businessModelId +
        '&categoryId=' +
        categoryId +
        '&pageType=' +
        pageType,
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getNotification(categoryId: any, businessModelId: any, pageType: any) {
  try {
    const response = await instance({
      method: 'GET',
      url:
        CmsSearchUrl +
        '/partnerportal/getNotification?businessModelId=' +
        businessModelId +
        '&categoryId=' +
        categoryId +
        '&pageType=' +
        pageType,
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getContent(contentUrl: any, categoryId: any, businessModelId: any, pageType: any) {
  try {
    const response = await instance({
      method: 'GET',
      url:
        CmsSearchUrl +
        '/partnerportal/getContent?contentUrl=' +
        contentUrl +
        '&businessModelId=' +
        businessModelId +
        '&categoryId=' +
        categoryId +
        '&pageType=' +
        pageType,
    });
    return response.data;
  } catch {
    return null;
  }
}
