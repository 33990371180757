import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss';
import BreadcrumbLink from '../../../../components/Shared/BreadcrumbLink';
import { ExcelRenderer } from 'react-excel-renderer';
import { insertSize, insertSizeDescription, addSizesToHapi, updateSizeState } from '../../../../services/SizeService';
import { RootStateOrAny, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ExcelImage from '../../../../assets/images/excel.jpg';
import { useHistory, Link } from 'react-router-dom';

let partnerId: string | null = '';
let categoryId: string = '';

const ImportExcell = () => {
  const history = useHistory();

  const [, setExcelData] = useState({});
  const [uploading, setUploading] = useState(false);
  const partnerDetail = useSelector((state: RootStateOrAny) => state.partner);

  if (partnerDetail.partner && Object.keys(partnerDetail.partner).length !== 0) {
    partnerId = partnerDetail.partner.partnerId;
    categoryId = partnerDetail.partner?.categoryId;
  } else {
    partnerId = window.sessionStorage.getItem('partnerId');
    categoryId = JSON.parse(window.sessionStorage.getItem('partnerDetail')!)?.categoryId;
  }

  const breadcrumbLinks = [
    {
      name: 'Size import',
      path: '/sizes',
    },
    {
      name: 'Choose import method',
      path: '/sizes/import/method',
    },
    {
      name: 'Excell import',
      path: '/sizes/importmethod/excell',
    },
  ];

  const fileHandler = (event) => {
    setUploading(true);
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setExcelData({
          cols: resp.cols,
          rows: resp.rows,
        });

        //Sorting resp.rows.sort((a, b) => (a.trademark > b.productType) ? 1 : -1)
        resp.rows.sort((a, b) => a[0].localeCompare(b[0]) || a[1] - b[1] || a[2] - b[2]);

        let uniqueRows: any[] = [];
        let uniqueRowSizes: any[] = [];
        let thisGroup = '';
        let guid = uuidv4();
        let uniqueLoop = -1;
        resp.rows.map((row, index) => {
          //Get the first part for Size table
          if (index > 0) {
            //Get column values starting from column 3 into productSize array
            let productSize: any[] = [];
            for (let i = 3; i < row.length; i++) {
              //Group columns by 2 starting from column 3 and get value from the upcoming column
              if (i % 2 == 1) {
                productSize.push({
                  region: row[i],
                  name: row[i + 1],
                });
              }
            }

            //Check if the current group is the same as the previous group and create new guid
            if (thisGroup != row[0]) {
              thisGroup = row[0];
              guid = uuidv4();
              let rowInfo = row.slice(0, 3);
              uniqueRows.push({
                row: rowInfo,
                sizes: [],
                guid: guid,
              });
              uniqueLoop++;
            }

            uniqueRowSizes.push({ id: uniqueLoop, sizes: productSize });

            var customerGroups = row[0].split(',');
            let cleanCustomerGroups: any[] = [];
            if (customerGroups.length > 1) {
              customerGroups.map((customerGroup) => {
                cleanCustomerGroups.push(returnGroupId(customerGroup));
              });
            } else {
              cleanCustomerGroups.push(returnGroupId(customerGroups));
            }

            let size = {
              customerGroup: customerGroups,
              trademark: row[1],
              productType: row[2],
              productSize: productSize,
              guid: guid,
            };

            // console.log("size excel log", size)

            insertSize(size, partnerId).then((response) => {
              let id = response;
              // Insert each product size into the size description table
              productSize.map((size) => {
                let sizeDescription = {
                  sizeId: id,
                  region: size.region,
                  name: size.name,
                };
                insertSizeDescription(sizeDescription).then((response) => {
                  //console.log(response);
                });
              });
            });
          }
        });

        let asortedSizeArray = uniqueRowSizes.reduce((acc, curr) => {
          const key = curr.id;
          if (acc[key]) {
            acc[key].push(curr);
          } else {
            acc[key] = [curr];
          }
          return acc;
        }, {});

        //Add asortedSizeArray to uniqueRows
        Object.keys(asortedSizeArray).forEach((key) => {
          uniqueRows[key].sizes = asortedSizeArray[key];
        });

        // console.log("uniqueRows", uniqueRows)

        //Create json structre from uniqueRows to send to api client
        uniqueRows.map((row, index) => {
          var customerGroups = row.row[0].split(',');
          let cleanCustomerGroups: any[] = [];
          if (customerGroups.length > 1) {
            customerGroups.map((customerGroup) => {
              cleanCustomerGroups.push(returnGroupId(customerGroup));
            });
          } else {
            cleanCustomerGroups.push(returnGroupId(customerGroups));
          }

          // let size = {
          //     customerGroup: cleanCustomerGroups,
          //     trademark: row.row[1],
          //     productType: row.row[2].replace(/ /g,''),
          //     productSize: row.sizes,
          //     guid: row.guid
          // };

          let cleanSizes: any[] = [];
          //Get sizes from uniquerow into cleanSizes
          row.sizes.map((size, index) => {
            let cleanSizesRows: any[] = [];
            size.sizes.map((size, index) => {
              cleanSizesRows.push({
                sizeRegionId: '' + size.region + '',
                sizeName: '' + size.name + '',
              });
            });

            cleanSizes.push({
              sortOrder: index + 1,
              sizeElements: cleanSizesRows,
            });
          });

          let cleanTypes: any[] = [];
          //Get row from uniquerow into cleanTypes
          var types = row.row[2].split(',');
          types.map((type) => {
            cleanTypes.push({
              id: '' + type + '',
              name: '' + type + '',
            });
          });

          var data = {
            partnerHubId: row.guid,
            trademarkId: row.row[1],
            productTypes: cleanTypes,
            customerGroups: cleanCustomerGroups,
            sizes: cleanSizes,
          };

          // console.log("data", data)

          addSizesToHapi(data).then((response) => {
            // console.log(data,"data");
            // console.log("response",response);
            let message = '';
            let path = '/sizes';
            if (response?.data.reasonPhrase === 'Created') {
              message = 'Sizes sent and saved successfully.';
              updateSizeState(row.guid, true).then(function () {});
            } else {
              message = 'Error when uploading, please check the data.';
            }
            history.push({ pathname: path, state: { message: message } });
          });
        });
      }
    });
    setUploading(false);
  };

  const returnGroupId = function (groupName) {
    switch (groupName) {
      case 'Boy(1-2yr)':
        return '0';
      case 'Boy(1-2yr)':
        return '1';
      case 'Girl (1-12yr)':
        return '2';
      case 'Infant (0-1yr)':
        return '3';
      case 'Men':
        return '4';
      case 'Boy':
        return '1';
      case 'Woman':
        return '5';
      case 'Women':
        return '5';
      case 'Unisex':
        return '6';
      default:
        return '1';
    }
  };

  return (
    <div className="Sizes Page-gradient">
      <Container fluid="xl">
        <Row>
          <Col xs={8} sm={8} md={8}>
            <div className="Hero">
              <h1 className="Heading Heading--h2" style={{ color: 'white' }}>
                Size Import
              </h1>
              <BreadcrumbLink links={breadcrumbLinks} />
            </div>
            <h5 style={{ color: 'white' }}>Import using excell</h5>
            <p>Download an excel template, fill in your size information and upload to partner hub.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={4} md={4}>
            <div className="placeholderImage">
              <img src={ExcelImage} alt="Image" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={8} md={8}>
            {/* <p>Your procedures for handling incidents and refunds will be dependent on your choice of integration, your internal practices, your carriers and so on.</p>                     */}
            <Link to="/excel/sample.xlsm" className="Button" target="_blank" download>
              <i className="icon-Download"></i> Download excell template
            </Link>
            <br></br>
            <br></br>
            <p>Upload your excel template here for us to automatically import the data for you.</p>
            {!uploading && (
              <input
                type="file"
                onChange={fileHandler}
                data-testid="fileHandler"
                className="custom-file-input"
                disabled={uploading}
              />
            )}
            {uploading && <label className="custom-file-label">Uploading Excel, please hold on...</label>}
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
};

export default ImportExcell;
