import './index.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const QuoteBlock = (props: any) => {
  return (
    <>
      <div className="Quote" data-testid="QuoteBlock">
        <div className="Quote-bg"></div>
        <div className="Quote-content">
          <Row>
            <Col lg={8}>
              <h2 className="Heading Quote-heading">{props.block.data?.quoteText}</h2>
            </Col>
            <Col lg={4}>
              <div className="u-posRelative">
                <img className="Quote-image" src={props.block.data?.image}></img>
                <div className="Quote-sender">
                  <span className="Quote-name">{props.block.data?.author}</span>
                  <span className="Quote-company">{props.block.data?.brand}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default QuoteBlock;
