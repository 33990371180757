const getDetails = () => {
  let apiurl: string = '';
  let subscriptionKey: string = '';
  let insightsPartnerIds: any = [];
  let appUrl: string = window.location.origin;
  let devApiUrl = 'https://partnerportal-apim.azure-api.net/';
  let devSubscriptionKey = 'f127f9b668494586b9454f449a1fe8c4';

  //DEVELOPMENT
  if (appUrl == 'http://localhost:3000' || appUrl == 'https://partnerportaldevenv.azurewebsites.net') {
    apiurl = 'https://partnerportal-apim.azure-api.net/';
    subscriptionKey = 'f127f9b668494586b9454f449a1fe8c4';
    insightsPartnerIds = ['10069'];
  }

  //STAGING
  else if (appUrl == 'https://nice-meadow-05d10ac03.2.azurestaticapps.net') {
    apiurl = 'https://partnerportal-apim-staging.azure-api.net/';
    subscriptionKey = '2c78bdd8be2e406782cfda609e3ceb81';
    insightsPartnerIds = ['10182'];
  }

  //PRODUCTION
  else {
    apiurl = 'https://partnerportalprod-apim.azure-api.net/';
    subscriptionKey = '6eab5fcfa52a4c6baaff654a1d4b95e1';
    insightsPartnerIds = ['10182'];
  }

  return { devApiUrl, devSubscriptionKey, apiurl, subscriptionKey, insightsPartnerIds };
};
export default getDetails;
