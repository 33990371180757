import './index.scss';
import TwoColumnBlock from './TwoColumnBlock';
import Accordion from 'react-bootstrap/Accordion';
import TableBlock from './TableBlock';

const AccordionBlock = (props: any) => {
  return (
    <div className="Article-content" id={props.block.data?.id} key={props.block.data?.id} data-testid="AccordionBlock">
      <div className={'Article-content--accordion'} key={props.block.data?.id}>
        <h2 className="Heading">{props.block.data?.heading}</h2>
        {props.block.data?.accordionItems?.map((accordion: any) => {
          return (
            <Accordion className="ArticleAccordion" key={accordion.key}>
              <Accordion.Header className="ArticleAccordion-header">{accordion?.title}</Accordion.Header>
              <Accordion.Body>
                <div className="ArticleAccordion-content">
                  {accordion.content.contentTypeFriendly === 'TwoColumnBlock' && (
                    <TwoColumnBlock block={accordion.content} key={accordion.key} accordion></TwoColumnBlock>
                  )}
                  {accordion.content.contentTypeFriendly === 'TableBlock' && (
                    <TableBlock block={accordion.content} key={accordion.key}></TableBlock>
                  )}
                </div>
              </Accordion.Body>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};
export default AccordionBlock;
