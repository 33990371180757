import axios from 'axios';
import getDetails from '../api/EnviromentConfig';
let salesUrl: string = getDetails().apiurl;
let subscriptionKey: string = getDetails().subscriptionKey;

export async function getSalesData(
  partnerId: string | null,
  startDate: string,
  endDate: string,
  country: string,
  categoryId: string,
  businessModelId: string,
) {
  try {
    const response = await axios({
      method: 'GET',
      url:
        salesUrl +
        'Sales/GetSalesData?partnerID=' +
        partnerId +
        '&startDate=' +
        startDate +
        '&endDate=' +
        endDate +
        '&country=' +
        (country || 'sweden') +
        '&categoryId=' +
        categoryId +
        '&businessModelId=' +
        businessModelId,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response;
  } catch {
    return null;
  }
}
