import { useState, useEffect, useRef } from 'react';
import './index.scss';

const ShowChild = (props: any) => {
  return <>{props.hasChildren && props.children}</>;
};

const DisplayItem = (props: any) => {
  return (
    <>
      {props.items.map((item) => (
        <li>
          <div>
            <span></span>
            {item?.children.length > 0 ? (
              <span onClick={() => props.handleExpanderChange(props.items, item, !item.hasChildren)}>
                <i className={!item.hasChildren ? 'icon-Plus' : 'icon-Minus'}></i>
              </span>
            ) : (
              <span>&emsp;</span>
            )}
            <span>
              <label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={props.listValues[item.id]?.checked}
                  value={item.text}
                  onClick={() => props.handleCheckboxChange(item, props.listValues[item.id]?.checked)}
                />
              </label>
            </span>
            <span className="form-check-label">{item.text}</span>
          </div>
          <ShowChild hasChildren={item?.hasChildren}>
            <ul>
              {item?.children.length ? (
                <DisplayItem
                  items={item?.children}
                  listValues={props.listValues}
                  handleCheckboxChange={props.handleCheckboxChange}
                  handleExpanderChange={props.handleExpanderChange}
                />
              ) : (
                <></>
              )}
            </ul>
          </ShowChild>
        </li>
      ))}
    </>
  );
};

const MultiLevelDropdown = (props: any) => {
  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(props.data);
  const [listValues, setListValues] = useState({});
  const [isDisabled, setIsDisabled] = useState(props.disabled ? props.disabled : false);

  useEffect(() => {
    if (props?.selectedProductType && Object.values(props?.selectedProductType).length) {
      setListValues(props?.selectedProductType);
    }
  }, [props?.selectedProductType]);

  const flattenedData: any = [];

  const getFlattenedData = (parent) => {
    parent?.children?.forEach?.((item) => {
      const i = { ...item };
      getFlattenedData(item);
      delete i.children;
      i.parentId = parent.id;
      flattenedData.push(i);
    });
  };
  props.data.forEach((child) => {
    const i: any = { ...child };
    getFlattenedData(i);
    delete i.children;
    i.parentId = null;
    flattenedData.push(i);
  });

  const toggleDropdown = () => setOpen(!isOpen && !isDisabled);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);

  const productDropdownValues: any = [];

  const productDropdownSelectedValues: any = [];

  Object.values(listValues)
    .filter((s: any) => s.checked && s.isLeafNode)
    ?.map((d: any) => {
      productDropdownValues.push(d.text);
      productDropdownSelectedValues.push({ id: d.id.toString(), name: d.text });
    });

  useEffect(() => {
    props.handleProductType(productDropdownSelectedValues);
  }, [listValues]);

  const getKeysToUpdate = (keyList, children, checked) => {
    children?.forEach((child) => {
      if (child?.children.length) {
        getKeysToUpdate(keyList, child?.children, checked);
      }
      keyList[child.id] = { id: child.id, text: child.text, isLeafNode: !child.children?.length, checked };
    });
  };
  const getParents = (pId, parents) => {
    const parent = flattenedData.find((f) => f.id === pId);
    if (parent?.parentId) {
      getParents(parent.parentId, parents);
    }
    parents.push(pId);
  };

  const handleCheckboxChange = (event, checked) => {
    const newValue = !checked;
    const keysToUpdate: any = {};
    getKeysToUpdate(keysToUpdate, event?.children, newValue);
    const item = flattenedData.find((f) => f.id === event.id);
    if (item && !newValue) {
      const parentIds = [];
      getParents(item.parentId, parentIds);
      parentIds.forEach((p) => {
        keysToUpdate[p] = newValue;
      });
    }
    setListValues({
      ...listValues,
      [event?.id]: { id: event.id, text: event.text, isLeafNode: !event.children?.length, checked: newValue },
      ...keysToUpdate,
    });
  };

  const handleExpanderChange = (itemsData, selectedIteam, hasChildren) => {
    itemsData.map((item) => {
      if (item.id === selectedIteam.id) {
        item.hasChildren = hasChildren;
        if (item?.children.length) {
          handleExpanderChange(item.children, item, item.hasChildren);
        }
      } else {
        if (item?.children.length) {
          handleExpanderChange(item.children, item, item.hasChildren);
        }
      }
    });
    const values = [...items];
    setItem(values);
  };

  return (
    <div className="multiLevelDropdown">
      <div className={isDisabled ? 'dropdown disabled' : 'dropdown'} ref={ref}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <span>
            {Object.values(listValues).filter((s: any) => s.checked && s.isLeafNode).length > 0
              ? productDropdownValues.join(',')
              : 'Please select'}
          </span>
          <i className={`icon-Arrows-Down icon ${isOpen && 'open'}`} data-testid="dropdown-icon"></i>
        </div>
        <div className={`dropdown-body ${isOpen && 'open'}`}>
          <ul>
            <DisplayItem
              items={items}
              listValues={listValues}
              handleCheckboxChange={handleCheckboxChange}
              handleExpanderChange={handleExpanderChange}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MultiLevelDropdown;
