import Button from '../../components/Shared/Button';
import Link from '../../components/Global/Link';
import Checkbox from '../../components/Global/Checkbox';
import Header from '../../components/Shared/Header';
import Hero from '../../components/Hero';
import CtaWidget from '../../components/Shared/CtaWidget';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import Feedback from '../../components/Shared/Feedback';
import Quote from '../../components/Shared/Quote';
import Categoryteaser from '../../components/Shared/CategoryTeaser';
import '../../components/Global/Themes/index.scss';
import Clock from '../../components/Shared/Clock';
import Toc from '../../components/Shared/Toc';
const SharedComponent = () => {
  return (
    <div className="">
      <Hero className="Hero Hero-gradient"></Hero>
      <h2>Shared Components</h2>

      <div className="u-backgroundWhite u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Hero</h3>
      </div>

      <div className="u-backgroundDawnPink u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h2">Header</h3>
        <Header classes="Header" />
        <Header classes="Header Header--inverted" />
      </div>

      <div className="u-backgroundBlue u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h2">Buttons</h3>
        {/* The buttons from the design. We should create a page where we can see and try all compnents */}
        <Button
          className="Button"
          onClick={() => {
            console.log('Set onClick');
          }}
        >
          Default button
        </Button>
        <Button className="Button Button--large">Large button</Button>
        <Button className="Button Button--small">Small button</Button>
        <Button className="Button Button--white">White button</Button>
        <Button className="Button Button--dawnPink">DawnPink button</Button>
        <Button className="Button Button--outlined">Transparent with black border</Button>
        <Button className="Button Button--white Button--outlined">Transparent with white border</Button>
        <Button className="Button Button--white Button--outlined Button--small">
          Small transparent with white border
        </Button>
        <Button className="Button Button--add">Button with icon before</Button>
        <Button className="Button Button--arrow">Button with icon after</Button>
        <Button className="Button Button--snow Button--iconFirst">
          <i className="icon-Exit"></i>Button with inline icon First
        </Button>
        <Button className="Button Button--iconLast">
          Button with inline icon last<i className="icon-Upload"></i>
        </Button>
        <Button className="Button Button--round Button--plus"></Button>
        <Button className="Button Button--round Button--small Button--red">
          <i className="icon-Close"></i>
        </Button>
        <Button className="Button Button--round Button--small Button--dawnPink" title="Edit">
          <i className="icon-edit-write"></i>
        </Button>
      </div>

      <div className="u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Icons</h3>

        <span className="icon-edit-write"></span>
        <span className="icon-Plus"></span>
        <span className="icon-Arrow-black-right"></span>
        <span className="icon-Close"></span>
        <span className="icon-dashboard"></span>
        <span className="icon-Exit"></span>
        <span className="icon-Graph"></span>
        <span className="icon-Info"></span>
        <span className="icon-Learn"></span>
        <span className="icon-Search"></span>
        <span className="icon-Upload"></span>
        <span className="icon-Arrow-Left-L"></span>
        <span className="icon-Arrows-Down"></span>
        <span className="icon-Arrows-Left"></span>
        <span className="icon-Arrows-Right"></span>
        <span className="icon-Arrows-Up"></span>
        <span className="icon-Setting-Circle"></span>
        <span className="icon-Settings"></span>
        <span className="icon-User-multiple"></span>
        <span className="icon-User"></span>
        <i className="icon-Envelope"></i>
        <i className="icon-Settings"></i>
        <span className="icon-Basket"></span>
        <span className="icon-Coins"></span>
        <span className="icon-Money-Graph"></span>
        <span className="icon-Return"></span>
        <span className="icon-Clock"></span>
        <span className="icon-Cloud-upload"></span>
        <span className="icon-Delete-bin"></span>
        <span className="icon-Validation-Check"></span>
        <i className="icon-Download"></i>

        <i className="icon-Blocked"></i>
        <i className="icon-Calendar "></i>
        <i className="icon-ControlPlay"></i>
        <i className="icon-Delete-circle"></i>
        <i className="icon-delete-square"></i>
        <i className="icon-dropdown"></i>

        <i className="con-File-text"></i>
        <i className="icon-Folder"></i>
        <i className="icon-PlusCircle"></i>
        <i className="icon-Validation-check-circle"></i>
      </div>

      <div className="u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">CtaWidget</h3>
        <CtaWidget className="CtaWidget CtaWidget--blue"></CtaWidget>
      </div>

      <div className="u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Headings</h3>
        <h1 className="Heading">Heading</h1>
        <h2 className="Heading">Heading</h2>
        <h3 className="Heading">Heading</h3>
        <h4 className="Heading">Heading</h4>
        <h5 className="Heading">Heading</h5>
        <h6 className="Heading">Heading</h6>
      </div>

      <div className="u-backgroundPink u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Breadcrumb</h3>
        <Breadcrumb />
      </div>
      <div className="u-backgroundBlue u-paddingAlg u-spaceOnNext u-posRelative">
        <h3 className="Heading--h3">Clock</h3>
        <Clock />
      </div>

      <div className="u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Categoryteaser</h3>
        <Categoryteaser />
      </div>

      <div className="u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Table of content</h3>
        <Toc />
      </div>

      <div className="u-backgroundBlue u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Title text</h3>
        <p>Use data-title="". See productcard delete under</p>
      </div>

      <div className="u-backgroundPink u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Link</h3>
        <Link className="Link" href="#">
          Link
        </Link>
        ,
        <Link className="Link Link--arrow" href="#">
          Link with arrow
        </Link>
      </div>

      <div className="u-backgroundStone2 u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Checkbox</h3>
        <Checkbox className="Checkbox Checkbox--default"></Checkbox>

        <span className="Checkbox Checkbox--transparent">
          <input type="checkbox" id="Cb02" className="Checkbox-input" />
          <label htmlFor="Cb02" className="Checkbox-label"></label>
        </span>
      </div>

      <div className="u-backgroundStone2 u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Navigation</h3>
        {/* <Navigation></Navigation>
            <Navigation></Navigation> */}
      </div>

      <div className="u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Feedback</h3>
        <Feedback></Feedback>
      </div>

      <div className="u-paddingAlg u-spaceOnNext">
        <h3 className="Heading--h3">Quote</h3>
        <Quote></Quote>
      </div>
    </div>
  );
};

export default SharedComponent;
