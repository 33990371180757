import { useState, useEffect } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import './index.scss';
import ExcelImage from '../../assets/images/excel.jpg';

const Product = (props: any) => {
  const [imageUrl, setImageUrl] = useState('');
  const [zoomImageUrl, setZoomImageUrl] = useState('');
  const [zoomImagePathUrl, setZoomImagePathUrl] = useState('');
  const [showZoomedImage, setShowZoomedImage] = useState(false);
  const [duration, setDuration] = useState('');
  const [userType, setUserType] = useState('');
  const userTypeData = useSelector((state: RootStateOrAny) => state.userType);
  const partnerDetail = useSelector((state: RootStateOrAny) => state.partner);

  useEffect(() => {
    if (userTypeData.userType) {
      setUserType(userTypeData.userType);
      loadImages(userTypeData.userType);
    } else {
      let usertype: any = window.sessionStorage.getItem('userType');
      setUserType(usertype);
      loadImages(usertype);
    }
  }, [props.campaign.uniqueid]);

  useEffect(() => {
    selectAll();
  }, [props.selectedAll]);

  useEffect(() => {
    selectAll();
  }, [props.unSelectAll]);

  const loadImages = (usertype: string) => {
    let partnerId: any = '';

    if (usertype == 'HmUser') {
      partnerId = props.campaign.partnerId;
    } else {
      if (Object.keys(partnerDetail.partner).length !== 0) {
        partnerId = partnerDetail.partner.partnerId;
      } else {
        partnerId = window.sessionStorage.getItem('partnerId');
      }
    }

    let imagePath: string = props.campaign.uriroot + '/' + partnerId + '/' + props.campaign.thumbUri;
    setImageUrl(props.campaign.isImage ? imagePath : ExcelImage);
    let imageZoomPath: string = props.campaign.uriroot + '/' + partnerId + '/' + props.campaign.uri;
    setZoomImagePathUrl(props.campaign.isImage ? imageZoomPath : ExcelImage);
    getDuration();
  };

  const getDuration = () => {
    const dt_date1 = new Date();
    const dt_date2 = new Date(props.campaign.datetime);
    const date1_time_stamp = dt_date1.getTime();
    const date2_time_stamp = dt_date2.getTime();
    let calc;
    if (date1_time_stamp > date2_time_stamp) {
      calc = new Date(date1_time_stamp - date2_time_stamp);
    } else {
      calc = new Date(date2_time_stamp - date1_time_stamp);
    }
    const calcFormatTmp = calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();
    const calcFormat: any = calcFormatTmp.split('-');
    const days_passed = Number(Math.abs(calcFormat[0]) - 1);
    const months_passed = Number(Math.abs(calcFormat[1]) - 1);
    const years_passed = Number(Math.abs(calcFormat[2]) - 1970);
    const yrsTxt = ['year', 'years'];
    const mnthsTxt = ['month', 'months'];
    const daysTxt = ['day', 'days'];
    const result =
      (years_passed == 1
        ? years_passed + ' ' + yrsTxt[0] + ' '
        : years_passed > 1
        ? years_passed + ' ' + yrsTxt[1] + ' '
        : '') +
      (months_passed == 1
        ? months_passed + ' ' + mnthsTxt[0]
        : months_passed > 1
        ? months_passed + ' ' + mnthsTxt[1] + ' '
        : '') +
      (days_passed == 1 ? days_passed + ' ' + daysTxt[0] : days_passed > 1 ? days_passed + ' ' + daysTxt[1] : '');
    setDuration(result.trim());
  };

  const preview = (imageUrl: string) => {
    setZoomImageUrl(imageUrl);
    setShowZoomedImage(true);
  };

  const closePreview = () => {
    setShowZoomedImage(false);
    setZoomImageUrl('');
  };

  const selectAll = () => {
    if (props.selectedAll) {
      let checkboxes: any = document.getElementsByName('assets');
      for (let checkbox of checkboxes) {
        checkbox.checked = true;
      }
    } else {
      let checkboxes: any = document.getElementsByName('assets');
      for (let checkbox of checkboxes) {
        checkbox.checked = false;
      }
    }
  };

  return (
    <div className={props.className} key={props.campaign.uniqueid}>
      <div
        className="ProductCard-delete"
        data-testid="deleteAsset"
        onClick={() => props.deleteAsset(props.campaign.uniqueid)}
        data-title="Delete asset"
      >
        <i className="icon-Close"></i>
      </div>
      <div className="ProductCard-top">
        <div className="ProductCard-checkbox">
          {userType != 'HmUser' && (props.campaign.status == 'Approved' || props.campaign.status == 'Downloaded') ? (
            <></>
          ) : (
            <span className="Checkbox Checkbox--transparent">
              <input
                type="checkbox"
                id={props.campaign.uniqueid}
                name="assets"
                data-testid="checkboxAsset"
                className="Checkbox-input"
                value={props.campaign.uniqueid}
                onChange={props.selectCampaign}
              />
              <label htmlFor={props.campaign.uniqueid} className="Checkbox-label"></label>
            </span>
          )}
        </div>

        <img
          alt={props.campaign.fileName}
          className="ProductCard-image"
          src={imageUrl}
          onClick={() => {
            preview(zoomImagePathUrl);
          }}
        />
        <div className="ProductCard-content">
          <div>
            <h6 className="Heading ProductCard-heading">{props.campaign.fileName}</h6>
            <span className="ProductCard-size">{props.campaign.resolution}</span>
            {props.campaign.integrationError ? (
              <span className="ProductCard-sizeInvalid">
                <i className="icon-Close"></i>Invalid resolution
                <div className="ProductCard-message">
                  <p className="ProductCard-messageText">{props.campaign.integrationError}</p>
                </div>
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="ProductCard-bottom">
        <span className="ProductCard-history">{duration ? 'Uploaded ' + duration + ' ago' : 'Uploaded today'}</span>
        {props.campaign.status == 'Approved' ? (
          <span className="icon-Validation-Check Icon--round Icon--green" title="Approved"></span>
        ) : (
          <></>
        )}
        {props.campaign.status == 'Downloaded' ? (
          <span className="icon-Download Icon--round Icon--green" title="Downloaded"></span>
        ) : (
          <></>
        )}
      </div>
      {showZoomedImage ? (
        <div className="zoom-asset">
          <span className="close" onClick={closePreview}>
            &times;
          </span>
          <img src={zoomImageUrl} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default Product;
