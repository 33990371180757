import './index.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const TableBlock = (props: any) => {
  let tableCss = props.block.data?.useHorizontalHeader ? 'Article-content ArticleRowColor' : 'Article-content';

  return (
    <div className={tableCss} id={props.data ? props.data?.id : props.block.data?.id} data-testid="TableBlock">
      <Row>
        <Col lg={12}>
          <div
            dangerouslySetInnerHTML={{ __html: props.data ? props.data?.tableEditor : props.block.data?.tableEditor }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default TableBlock;
