import { useState, useEffect } from 'react';
import './index.scss';
import { Container } from 'react-bootstrap';
import { getPage } from '../../services/CmsService';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Block from '../../components/Block';
import Loader from '../../components/Shared/Loader';
import Hero from '../../components/Hero';

const Contact = (props: any) => {
  let path = window.location.pathname;
  const [showLoader, setShowLoader] = useState(false);

  interface Page {
    heading: string;
    name: string;
    preamble: string;
    tableOfContents: any;
    mainContentFriendly: any;
  }

  const history = useHistory();

  const [pageDataViaDc, setPageDataViaDc] = useState<Page>();
  const [pageDataDropShip, setPageDataDropShip] = useState<Page>();

  const partnerData = JSON.parse(window.sessionStorage.getItem('partnerDetail')!);

  let search = window.location.search;
  let par = new URLSearchParams(search);
  let contactData = par.get('contact');
  let isLink = par.has('link') ? true : false;

  let showViaDc = false;
  let showDropShip = false;
  if (contactData === 'all') {
    showViaDc = true;
    showDropShip = true;
  } else if (contactData === 'wholesale') {
    showViaDc = true;
    showDropShip = false;
  } else if (contactData === 'dropship') {
    showViaDc = false;
    showDropShip = true;
  } else {
    showViaDc = false;
    showDropShip = false;
  }

  if (!isLink) {
    partnerData?.partnerEntity?.map((item: any) => {
      if (item.businessModelId === '10001') {
        showViaDc = true;
      }
      if (item.businessModelId === '10002') {
        showDropShip = true;
      }
    });
  }

  useEffect(() => {
    if (showViaDc) {
      getPageDataViaDc('/contacts-wholesale');
    }
    if (showDropShip) {
      getPageDataDropShip('/contacts-dropship');
    }
    if (showViaDc && showDropShip) {
      if (isLink) {
        history.push(path + search);
      } else {
        history.push(path + '?contact=all');
      }
    } else if (showViaDc) {
      if (isLink) {
        history.push(path + search);
      } else {
        history.push(path + '?contact=wholesale');
      }
    } else {
      if (isLink) {
        history.push(path + search);
      } else {
        history.push(path + '?contact=dropship');
      }
    }
  }, []);

  const getPageDataViaDc = (url: string) => {
    setShowLoader(true);
    getPage(url)
      .then(function (data) {
        if (data) {
          setPageDataViaDc(data[0]);
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const getPageDataDropShip = (url: string) => {
    setShowLoader(true);
    getPage(url)
      .then(function (data) {
        if (data) {
          setPageDataDropShip(data[0]);
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  return (
    <div className="" data-testid="contact-component">
      {(pageDataViaDc || pageDataDropShip) && (
        <div>
          <Hero className="Hero Hero-gradient noHeroImage">
            <h1 className="mt-4 Heading">
              {showViaDc && showDropShip
                ? 'Contact Us'
                : showViaDc
                ? pageDataViaDc?.heading
                : showDropShip
                ? pageDataDropShip?.heading
                : ''}
            </h1>
          </Hero>
          <Container fluid="xl">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="Profile-hero">
                  <p className="mt-4 Heading">
                    {showViaDc && showDropShip
                      ? 'Here you can find contact details for people who can support you.'
                      : showViaDc
                      ? pageDataViaDc?.preamble
                      : showDropShip
                      ? pageDataDropShip?.preamble
                      : ''}
                  </p>
                  <div className="Article">
                    {showViaDc &&
                      pageDataViaDc &&
                      pageDataViaDc.mainContentFriendly &&
                      pageDataViaDc?.mainContentFriendly.map((blockListItem: any, index: number) => {
                        return <Block position={index} block={blockListItem}></Block>;
                      })}

                    {showDropShip &&
                      pageDataDropShip &&
                      pageDataDropShip.mainContentFriendly &&
                      pageDataDropShip?.mainContentFriendly.map((blockListItem: any, index: number) => {
                        return <Block position={index} block={blockListItem}></Block>;
                      })}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {showLoader ? <Loader /> : <></>}
    </div>
  );
};

export default Contact;
