import { useState, useEffect, useCallback } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import UploadFooterSuccess from '../../components/UploadFooterSuccess';
import ProductCard from '../../components/ProductCard';
import './index.scss';
import '../../components/Upload/index.scss';
import Loader from '../../components/Shared/Loader';
import CampaignFilter from '../../components/CampaignFilter';
import CampaignDownload from '../../components/CampaignDownload';
import {
  getCampaigns,
  deleteCampaigns,
  approveCampaigns,
  disapproveCampaigns,
  downloadCampaigns,
  downloadAssets,
} from '../../services/CampaignInternalService';
import Button from '../../components/Shared/Button';

const ApproveCampaign = () => {
  const initialState: any = [];
  const [uploadedCampaigns, setUploadedCampaigns] = useState<any[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<string[]>(initialState);
  const [showFilter, setShowFilter] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [approvalSuccess, setApprovalSuccess] = useState(false);
  const [disapprovalSuccess, setDisapprovalSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const filterData = useSelector((state: RootStateOrAny) => state.selectedCampaignFilter);
  const [disableApprove, setDisableApprove] = useState(true);
  const [disableDisapprove, setDisableDisapprove] = useState(true);
  const [disableDelete, setDisableDelete] = useState(true);
  const [disableDownload, setDisableDownload] = useState(true);
  const [unSelectAll, setUnSelectAll] = useState(false);

  useEffect(() => {
    getCampaignsList('', '', 'Received');
  }, []);

  const getUploadedCampaigns = useCallback((brand, country, status) => {
    closePopup();
    getCampaignsList(brand, country, status);
  }, []);

  const getCampaignsList = (brand: string, country: string, status: string) => {
    setShowLoader(true);
    getCampaigns(brand, country, status)
      .then(function (data) {
        setUploadedCampaigns(data);
        localStorage.setItem('approvedcampaigns', JSON.stringify(data));
        setShowLoader(false);
        setUnSelectAll(!unSelectAll);
        clearSelectedCampaigns();
        beforeCheckEnable([]);
        setDisableApprove(true);
        setDisableDisapprove(true);
        setDisableDelete(true);
        setDisableDownload(true);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const clearSelectedCampaigns = () => {
    let updatedCampaign: any = selectedCampaign;
    while (updatedCampaign.length > 0) {
      updatedCampaign.pop();
    }
    setSelectedCampaign(updatedCampaign);
    setSelectedAll(false);
  };

  const closePopup = () => {
    setShowFilter(false);
    setShowDownload(false);
  };

  const deleteAsset = useCallback((uniqueId: string) => {
    let uniqueIds: any = [];
    uniqueIds.push(uniqueId);
    deleteUploadedCampaign(uniqueIds);
  }, []);

  const deleteCampaign = () => {
    if (selectedCampaign.length !== 0) {
      deleteUploadedCampaign(selectedCampaign);
    }
  };

  const deleteUploadedCampaign = (uniqueIds: any) => {
    setShowLoader(true);
    let approvecampaigns: any = localStorage.getItem('approvedcampaigns');
    let campaignsList: any = JSON.parse(approvecampaigns);
    deleteCampaigns(campaignsList[0].partnerId, uniqueIds, user.user.username)
      .then(function (data) {
        setShowLoader(false);
        setDeleteSuccess(true);
        setTimeout(() => {
          setDeleteSuccess(false);
        }, 5000);
        getCampaignsList(
          filterData.selectedCampaignFilter.selectedBrand,
          filterData.selectedCampaignFilter.selectedCountry,
          filterData.selectedCampaignFilter.selectedStatus,
        );
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const approveCampaign = () => {
    setShowLoader(true);
    let approvecampaigns: any = localStorage.getItem('approvedcampaigns');
    let campaignsList: any = JSON.parse(approvecampaigns);
    approveCampaigns(campaignsList[0].partnerId, selectedCampaign, user.user.username)
      .then(function (data) {
        setShowLoader(false);
        setApprovalSuccess(true);
        setTimeout(() => {
          setApprovalSuccess(false);
        }, 5000);
        getCampaignsList(
          filterData.selectedCampaignFilter.selectedBrand,
          filterData.selectedCampaignFilter.selectedCountry,
          filterData.selectedCampaignFilter.selectedStatus,
        );
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const disapproveCampaign = () => {
    setShowLoader(true);
    let approvecampaigns: any = localStorage.getItem('approvedcampaigns');
    let campaignsList: any = JSON.parse(approvecampaigns);
    disapproveCampaigns(campaignsList[0].partnerId, selectedCampaign, user.user.username)
      .then(function (data) {
        setShowLoader(false);
        setDisapprovalSuccess(true);
        setTimeout(() => {
          setDisapprovalSuccess(false);
        }, 5000);
        getCampaignsList(
          filterData.selectedCampaignFilter.selectedBrand,
          filterData.selectedCampaignFilter.selectedCountry,
          filterData.selectedCampaignFilter.selectedStatus,
        );
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const selectCampaign = useCallback((event: any) => {
    let updatedCampaign: any = selectedCampaign;
    if (event.target.checked) {
      updatedCampaign.push(event.target.value);
    } else {
      updatedCampaign.splice(selectedCampaign.indexOf(event.target.value), 1);
    }
    setSelectedCampaign(updatedCampaign);
    checkAll();
    beforeCheckEnable(updatedCampaign);
  }, []);

  const downloadCampaign = useCallback(
    (campaign, selectedUsage) => {
      closePopup();
      setShowLoader(true);

      for (let campaignId of selectedCampaign) {
        let approvecampaigns: any = localStorage.getItem('approvedcampaigns');
        let campaignsList: any = JSON.parse(approvecampaigns);
        let partnerId = '';
        for (let asset of campaignsList) {
          if (campaignId === asset.uniqueid) {
            partnerId = asset.partnerId;
            break;
          }
        }

        downloadCampaigns(partnerId, campaignId, user.user.username, campaign, selectedUsage)
          .then(function (data) {
            setShowLoader(false);
            downloadAsset(campaignId);
          })
          .catch(function (error) {
            console.log(error);
            setShowLoader(false);
          });
      }
    },
    [selectedCampaign],
  );

  const downloadAsset = (campaignId: string) => {
    setShowLoader(true);
    let approvecampaigns: any = localStorage.getItem('approvedcampaigns');
    let uploadedAssets: any = JSON.parse(approvecampaigns);
    let campaignsFileName: string = '';
    let downloadableCampaign: any;
    for (let asset of uploadedAssets) {
      if (campaignId == asset.uniqueid) {
        campaignsFileName = asset.partnerId + '/' + asset.uri;
        downloadableCampaign = asset;
        break;
      }
    }

    downloadAssets(campaignsFileName)
      .then(function (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName: string =
          downloadableCampaign.fileName +
          '_' +
          downloadableCampaign.campaign +
          '_' +
          downloadableCampaign.brand +
          '_' +
          downloadableCampaign.usage +
          '_' +
          downloadableCampaign.resolution +
          '.' +
          downloadableCampaign.fileType;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setShowLoader(false);
        getCampaignsList(
          filterData.selectedCampaignFilter.selectedBrand,
          filterData.selectedCampaignFilter.selectedCountry,
          filterData.selectedCampaignFilter.selectedStatus,
        );
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const checkAll = () => {
    let uploadedItems: any = localStorage.getItem('approvedcampaigns');
    let uploadedAssets: any = JSON.parse(uploadedItems);
    if (selectedCampaign.length == uploadedAssets.length) {
      let checkboxes: any = document.getElementsByName('assetsAll');
      for (let checkbox of checkboxes) {
        checkbox.checked = true;
      }
    } else {
      let checkboxes: any = document.getElementsByName('assetsAll');
      for (let checkbox of checkboxes) {
        checkbox.checked = false;
      }
    }
  };

  const handleSelectAll = (event: any) => {
    setSelectedAll(event.target.checked);
    if (event.target.checked) {
      if (uploadedCampaigns.length !== 0) {
        let campaigns: any = [];
        for (let campaign of uploadedCampaigns) {
          campaigns.push(campaign.uniqueid);
        }
        setSelectedCampaign(campaigns);
        beforeCheckEnable(campaigns);
      }
    } else {
      clearSelectedCampaigns();
      beforeCheckEnable([]);
    }
  };

  const beforeCheckEnable = (uniqueIds: any) => {
    let approvecampaigns: any = localStorage.getItem('approvedcampaigns');
    let uploadedAssets: any = JSON.parse(approvecampaigns);
    let campaignsList: any = [];
    for (let uniqueid of uniqueIds) {
      for (let asset of uploadedAssets) {
        if (uniqueid == asset.uniqueid) {
          campaignsList.push(asset);
          break;
        }
      }
    }
    checkEnable(campaignsList);
  };

  const checkEnable = (selectedCamapignsList: any) => {
    let countApproved: number = 0;
    let countDownloaded: number = 0;
    let countReceived: number = 0;
    setDisableDownload(true);
    setDisableApprove(true);
    setDisableDisapprove(true);
    setDisableDelete(true);
    for (let thumb of selectedCamapignsList) {
      if (thumb.status == 'Approved') {
        ++countApproved;
      } else if (thumb.status == 'Downloaded') {
        ++countDownloaded;
      } else if (thumb.status == 'Received') {
        ++countReceived;
      }
    }
    if (countDownloaded != 0 && countReceived == 0) {
      setDisableDownload(false);
    } else if (countApproved != 0 && countReceived == 0) {
      setDisableDownload(false);
      setDisableDisapprove(false);
    } else if (countDownloaded == 0 && countApproved == 0 && countReceived != 0) {
      setDisableApprove(false);
      setDisableDelete(false);
    }
  };

  return (
    <div className="Page-gradient">
      <Container fluid="xl">
        <Row className="justify-content-md-center">
          <Col xs={12} lg={4}></Col>
        </Row>
      </Container>
      <Container fluid="xl">
        <div className="UpLoad-hero">
          <div className="">
            <Breadcrumb></Breadcrumb>
            <p className="UpLoad-heroText"></p>
          </div>
        </div>

        <div className="Upload">
          <div className="Upload-box Upload-box--scroll">
            {uploadedCampaigns.length != 0 ? (
              <Button
                className="Button Button--transparent Button--iconFirst"
                onClick={() => {
                  setShowFilter(true);
                }}
              >
                <i className="icon-Settings"></i>Filter
              </Button>
            ) : (
              ''
            )}
            <div className="Upload-ctaTop">
              <span className="Checkbox Checkbox--default">
                <input
                  type="checkbox"
                  id="selectAllCheck"
                  className="Checkbox-input"
                  onChange={handleSelectAll}
                  checked={selectedAll}
                />
                <label htmlFor="selectAllCheck" className="Checkbox-label">
                  Select all assets
                </label>
              </span>
            </div>

            <Row className="g-4 mt-1">
              {uploadedCampaigns &&
                uploadedCampaigns.map((campaign: any) => {
                  return (
                    <Col xs={12} lg={3}>
                      <ProductCard
                        className="ProductCard"
                        unSelectAll={unSelectAll}
                        selectedAll={selectedAll}
                        deleteAsset={deleteAsset}
                        campaign={campaign}
                        selectCampaign={selectCampaign}
                      ></ProductCard>
                    </Col>
                  );
                })}
            </Row>

            {uploadedCampaigns.length == 0 ? (
              <Row>
                <Col xs={12} lg={6} className="u-center u-textCenter">
                  <h2 className="Heading">Empty</h2>
                  <p>
                    Please click on <strong>Filter</strong> to get uploaded assets <br />
                    (or)
                    <br /> Your selected filter combination does not have assets yet
                  </p>
                  <Button
                    className="Button Button--dawnPink Button--iconLast"
                    onClick={() => {
                      setShowFilter(true);
                    }}
                    data-testid="Filter"
                  >
                    Filter <i className="icon-Settings"></i>
                  </Button>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </div>
          <div className="UploadFooter">
            <div className="area UploadFooter-area">
              <div className="UploadFooter-selectArea">
                <div className="UploadFooter-selectWrapper">
                  <Button
                    className="Button Button--outlined Button--iconFirst"
                    onClick={approveCampaign}
                    disabled={disableApprove}
                  >
                    <span className="icon-Validation-check-circle"></span> Approve
                  </Button>
                </div>

                <div className="UploadFooter-selectWrapper">
                  <Button
                    className="Button Button--outlined Button--iconFirst"
                    onClick={disapproveCampaign}
                    disabled={disableDisapprove}
                  >
                    <span className="icon-Delete-circle"></span> Disapprove
                  </Button>
                </div>
              </div>
              <div className="UploadFooter-ctaArea">
                <Button className="UploadFooter-delete no-border" onClick={deleteCampaign} disabled={disableDelete}>
                  <i className="icon-Delete-bin"></i>
                  <span>Delete</span>
                </Button>

                <Button
                  className="Button Button--iconFirst"
                  onClick={() => setShowDownload(true)}
                  disabled={disableDownload}
                >
                  <i className="icon-Download"></i> Download
                </Button>
              </div>
            </div>
            <div className="area">
              {deleteSuccess ? <UploadFooterSuccess content="All picture(s) were succesfully deleted" /> : ''}
              {approvalSuccess ? <UploadFooterSuccess content="All picture(s) were succesfully approved" /> : ''}
              {disapprovalSuccess ? <UploadFooterSuccess content="All picture(s) were succesfully disapproved" /> : ''}
            </div>

            {showLoader ? <Loader /> : ''}
          </div>
        </div>
        <div className="u-marginTlg"></div>

        <br />
        {showFilter ? <CampaignFilter closePopup={closePopup} getUploadedCampaigns={getUploadedCampaigns} /> : ''}
        {showDownload ? <CampaignDownload downloadCampaign={downloadCampaign} closePopup={closePopup} /> : ''}
        {showLoader ? <Loader /> : ''}
      </Container>
    </div>
  );
};

export default ApproveCampaign;
