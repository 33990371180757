import './index.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeroDefault from '../../assets/images/Hero-gallery.png';

const Hero = (props: any) => {
  const heroStyle = {
    ...(props.isHeroWithBackground
      ? {
          paddingTop: 80,
          paddingBottom: 20,
          borderRadius: 20,
        }
      : 0),
    ...(props.backgroundColor ? { backgroundColor: props.backgroundColor } : ''),
    ...(props.background ? { background: props.background } : ''),
    ...(props.backgroundImage ? { backgroundImage: props.backgroundImage } : ''),
  };

  return (
    <div className={props.className}>
      <div className="" style={heroStyle}>
        <Row>
          <Col sm={12} md={12} lg={9} xl={8} className="Hero-content-container">
            <div className={props.isHeroWithBackground ? 'Hero-content-black' : 'Hero-content'}>{props.children}</div>
          </Col>
          <Col xs={12} md={8} lg={4} xl={4} className="p-0">
            <div
              className="Hero-multiImageWrapper"
              style={{
                backgroundImage: HeroDefault,
                height: '100%',
                width: '100%',
                textAlign: 'right',
              }}
            >
              {props.heroImage && (
                <img
                  className={props.isHeroWithBackground ? 'Hero-multiImage-fullwidth' : 'Hero-multiImage'}
                  src={props.heroImage ? props.heroImage : ''}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Hero;
