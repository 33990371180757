import { useState, useEffect } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateFilterCampaign } from '../../store/actions/campaignFilterAction';
import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import './index.scss';
import { getAllFilters, getAllTradeMark } from '../../services/DataService';
import Loader from '../../components/Shared/Loader';

const CampaignFilter = (props: any) => {
  const [marketsList, setMarketsList] = useState<any[]>([]);
  const [brandsList, setBrandsList] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const filterData = useSelector((state: RootStateOrAny) => state.selectedCampaignFilter);

  const dispatch = useDispatch();

  const statusList = [
    { id: 'Approved', name: 'Approved' },
    { id: 'Downloaded', name: 'Downloaded' },
    { id: 'Received', name: 'Received' },
  ];

  useEffect(() => {
    getFilters();
    if (filterData.selectedCampaignFilter) {
      setSelectedBrand(filterData.selectedCampaignFilter.selectedBrand);
    }
    if (filterData.selectedCampaignFilter) {
      setSelectedCountry(filterData.selectedCampaignFilter.selectedCountry);
    }
    if (filterData.selectedCampaignFilter) {
      setSelectedStatus(filterData.selectedCampaignFilter.selectedStatus);
    }
  }, []);

  const handleBrand = (event: any) => {
    setSelectedBrand(event.target.value);
  };

  const handleCountry = (event: any) => {
    setSelectedCountry(event.target.value);
  };

  const handleStatus = (event: any) => {
    setSelectedStatus(event.target.value);
  };

  const getFilters = () => {
    setShowLoader(true);
    getAllTradeMark()
      .then(function (data) {
        setBrandsList(data);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
    getAllFilters()
      .then(function (data) {
        setMarketsList(data.markets);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const onFilter = () => {
    let filterData: any = {
      selectedBrand: selectedBrand,
      selectedCountry: selectedCountry,
      selectedStatus: selectedStatus,
    };
    if (selectedBrand || selectedCountry || selectedStatus) {
      dispatch(updateFilterCampaign(filterData));
      props.getUploadedCampaigns(selectedBrand, selectedCountry, selectedStatus);
    }
  };

  return (
    <div className="popup-backdrop">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6 className="Heading">
            Filter options{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
            >
              <i className="icon-Close Popup-close"></i>
            </span>
          </h6>
        </div>

        <div className="addUser-popup--body">
          <h5 className="text-center heading mb-4">Filter by</h5>

          <select className="form-control" value={selectedBrand} onChange={handleBrand}>
            <option value="">Choose brand</option>
            {brandsList &&
              brandsList.map((item: any) => {
                return <option value={item.brandName}>{item.brandName}</option>;
              })}
          </select>

          <select className="form-control" value={selectedCountry} onChange={handleCountry}>
            <option value="">Choose market</option>
            {marketsList &&
              marketsList.map((item: any) => {
                return <option value={item.marketId}>{item.marketName}</option>;
              })}
          </select>

          <select className="form-control" value={selectedStatus} onChange={handleStatus}>
            <option value="">Choose status</option>
            {statusList &&
              statusList.map((item: any) => {
                return <option value={item.id}>{item.name}</option>;
              })}
          </select>
        </div>

        <div className="addUser-popup--footer">
          <Button type="submit" onClick={onFilter} className="Button">
            Filter
          </Button>
        </div>
        {showLoader ? <Loader /> : ''}
      </div>
    </div>
  );
};

export default CampaignFilter;
