import './index.scss';
const ImageBlock = (props: any) => {
  return (
    <>
      <div className="Article-content" data-testid="image-block">
        <div className={'Article-content--media'} id={props.block.data?.id}>
          <img src={props.block.data?.imageUrl} data-testid="image" />
        </div>
      </div>
    </>
  );
};
export default ImageBlock;
