import React, { useState } from 'react';
import AzureAuthenticationContext from '../../azure/azure-authentication-context';
import { AccountInfo } from '@azure/msal-browser';
import { useDispatch } from 'react-redux';
import { isLoggedIn } from '../../store/actions/loginAction';
import { updateUser } from '../../store/actions/userAction';
import { updateUserType } from '../../store/actions/userTypeAction';
import { updatePartner } from '../../store/actions/partnerAction';
import { getUser, UpdateUserLogin } from '../../services/PartnersService';
import { getHmUser } from '../../services/HmUsersService';
import Loader from '../../components/Shared/Loader';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../components/Shared/Button';
import Clock from '../../components/Shared/Clock';
import './index.scss';
import logoWhite from '../../assets/images/HM-logo.svg';
import '../../components/Shared/Indent-hero/index.scss';
import Constants from '../../types/Constants';
import { updateCurrentPartnerUser } from '../../store/actions/currentPartnerUserAction';
import setCmsCookie from '../../api/CmsCookie';
import { getPartnerDetail } from '../../services/PartnersService';
import { useLocation } from 'react-router-dom';
import landingImage from '../../assets/images/landingImage.png';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const isIE = msie > 0 || msie11 > 0;

const Login = () => {
  interface LocationState {
    from: {
      pathname: string;
      state: any;
    };
    referrer: any;
  }

  let history = useHistory();
  const dispatch = useDispatch();
  // Azure client context
  const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();
  const [authenticated, setAuthenticated] = useState<Boolean>(false);
  const [user, setUser] = useState<AccountInfo>();
  const [showLoader, setShowLoader] = useState(false);
  const location = useLocation<LocationState>();
  let redirectUrl = location.state?.referrer;

  const logIn = (method: string): any => {
    const typeName = 'loginPopup';
    const logInType = isIE ? 'loginRedirect' : typeName;
    // Azure Login
    authenticationModule.login(logInType, returnedAccountInfo);
  };

  const returnedAccountInfo = (user: AccountInfo) => {
    //Set cookie for CMS calls
    setCmsCookie();

    //Set token for future calls to HAPI
    // getHapiToken().then(res => {
    //     window.sessionStorage.setItem('hapiToken', res.access_token)
    // })

    // set state
    setAuthenticated(user?.name ? true : false);

    //onAuthenticated(user);
    setUser(user);

    //Remove presentation mode
    sessionStorage.removeItem('activatedMode');

    if (user !== null) {
      //Set user data
      window.sessionStorage.setItem('user', JSON.stringify(user));
      window.sessionStorage.setItem('userName', user?.name ? user?.name : '');

      dispatch(updateUser(user));
      dispatch(isLoggedIn());

      if (user.username && user.username.toLowerCase().lastIndexOf('@hm.com') <= 0) {
        setShowLoader(true);
        dispatch(updateUserType('Partner'));

        window.sessionStorage.setItem('userType', 'Partner');
        window.sessionStorage.setItem('userEmail', user?.username ? user?.username : '');

        getUser(user.username)
          .then(function (resp: any) {
            setShowLoader(false);
            if (resp.status === 204) {
              alert(
                'Login failed! You (' + user?.username + ') are not yet registered with Partner Hub. Please try again.',
              );
            } else {
              let partner = {};

              getPartnerDetail(resp.data.partnerUserMapping[0].partnerId).then(function (partnerData) {
                partner = partnerData;
                window.sessionStorage.setItem(Constants.SESSIONSTORAGE_PARTNERDETAIL, JSON.stringify(partner));
              });

              UpdateUserLogin(resp.data.userId)
                .then(function (data) {})
                .catch(function (error) {
                  console.log(error);
                });

              if (resp.data.partnerUserMapping.length > 1) {
                window.sessionStorage.setItem('partnerId', resp.data.partnerUserMapping[0].partnerId);
                window.sessionStorage.setItem('hasMultiplePartners', 'true');
                window.sessionStorage.setItem('multiplePartners', JSON.stringify(resp.data.partnerUserMapping));
              } else {
                window.sessionStorage.setItem('partnerId', resp.data.partnerUserMapping[0].partnerId);
                window.sessionStorage.setItem('hasMultiplePartners', 'false');
              }

              dispatch(updatePartner(resp.data?.partnerUserMapping ? resp.data?.partnerUserMapping[0] : null));
              dispatch(
                updateCurrentPartnerUser(resp.data?.partnerUserMapping ? resp.data?.partnerUserMapping[0] : null),
              );
              history.push({ pathname: '/welcome', state: { redirectUrl: redirectUrl } });
            }
          })
          .catch(function (error) {
            console.log(error);
            setShowLoader(false);
            alert('Login failed! You are not yet registered with Partner Hub. Please try again.');
          });
      } else {
        setShowLoader(true);
        getHmUser(user.username)
          .then(function (resp: any) {
            setShowLoader(false);
            if (resp.status == 204) {
              alert(
                'Login failed! You (' + user?.username + ') are not yet registered with Partner Hub. Please try again.',
              );
            } else {
              window.sessionStorage.setItem('userType', 'HmUser');
              window.sessionStorage.setItem('userEmail', user?.username ? user?.username : '');
              dispatch(updateUserType('HmUser'));
              history.push({ pathname: '/welcome', state: { redirectUrl: redirectUrl } });
            }
          })
          .catch(function (error: any) {
            console.log(error);
            setShowLoader(false);
            alert('Login failed! You are not yet registered with Partner Hub. Please try again.');
          });
      }
    } else {
      alert('Login failed! Please try again');
    }
  };

  return (
    <div className="Login-page" data-testid="login-component">
      <header className="Header Header--inverted u-positionFixed">
        <div className="Header-logoWrapper">
          <img src={logoWhite} alt="Logo" className="Header-logo Header-logo--white" />
        </div>
      </header>

      {/* <span className="Copy-rightText">partner hub</span>

      <Clock></Clock> */}

      <Container fluid="xl">
        <Row>
          <Col xs={12} sm={12} md={6} className="justify-content-md-center">
            <div className="Indent-hero">
              <h1 className="Heading Heading--1">
                {' '}
                WELCOME TO H&M <br></br> PARTNER HUB
              </h1>

              <div className="Indent-heroWrapper">
                {/* <p>Login to get the journey started:</p> */}
                <Button onClick={() => logIn('loginPopup')} className="Button Button--transparentBlack  Button--large">
                  LOG IN
                </Button>
              </div>
              <span className="preferred-browserText">
                For the best viewing experience, we suggest using Google Chrome or Microsoft Edge
              </span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} className="landingImage">
            <img src={landingImage} alt="LandingImage" />
          </Col>
        </Row>
      </Container>
      {showLoader ? <Loader /> : <></>}
    </div>
  );
};

export default Login;
