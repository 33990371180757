import { useState, useEffect, useCallback } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import './index.scss';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ProductCard from '../ProductCard';
import ProductAdd from '../ProductAdd';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Button from '../Shared/Button';
import '../../components/UploadFooter/index.scss';

import Loader from '../../components/Shared/Loader';
import { getUploadedCampaign, deleteCampaign, sendForApproval } from '../../services/CampaignService';
import UploadFootersucces from '../UploadFooter-succes';
import { getAllFilters, getAllTradeMark } from '../../services/DataService';

const Upload = () => {
  const [uploadedCampaigns, setUploadedCampaigns] = useState<any[]>([]);
  const [approvedCampaings, setApprovedCampaings] = useState<any[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<string[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [updateCampaign, setUpdateCampaign] = useState(false);
  const partnerDetail = useSelector((state: RootStateOrAny) => state.partner);
  const [marketsList, setMarketsList] = useState<any[]>([]);
  const [brandsList, setBrandsList] = useState<any[]>([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedBrandValue, setSelectedBrandValue] = useState('');
  const [selectedMarket, setSelectedMarket] = useState<any[]>([]);
  const [isApproved, setIsApproved] = useState(true);
  const [isDeleted, setIsDeleted] = useState(true);
  const [sentSuccess, setSentSuccess] = useState(false);
  const user = useSelector((state: RootStateOrAny) => state.user);

  useEffect(() => {
    getUploadCampaigns();
  }, []);

  const getUploadCampaigns = () => {
    let partnerId: any = '';
    if (partnerDetail.partner && Object.keys(partnerDetail.partner).length !== 0) {
      partnerId = partnerDetail.partner.partnerId;
    } else {
      partnerId = window.sessionStorage.getItem('partnerId');
    }
    setShowLoader(true);
    getUploadedCampaign(partnerId)
      .then(function (data) {
        let newCampaigns: any = data.filter((campaign: any) => {
          if (campaign.status == 'New') {
            return campaign;
          }
        });
        setUploadedCampaigns(newCampaigns);
        getApprovedCampaigns(data);
        clearSelectedCampaigns();
        setSelectedAll(false);
        setShowLoader(false);
        localStorage.setItem('uploadedCampaigns', JSON.stringify(newCampaigns));
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const getApprovedCampaigns = (data: any) => {
    let newCampaigns: any = data.filter((campaign: any) => {
      if (campaign.status == 'Approved' || campaign.status == 'Downloaded') {
        return campaign;
      }
    });
    setApprovedCampaings(newCampaigns);
  };

  const deleteAsset = useCallback((uniqueId: string) => {
    let uniqueIds: any = [];
    uniqueIds.push(uniqueId);
    deleteUploadedcampaign(uniqueIds);
  }, []);

  const deleteUploadedcampaign = (uniqueIds: any) => {
    setShowLoader(true);
    deleteCampaign(uniqueIds)
      .then(function (data) {
        getUploadCampaigns();
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const selectCampaign = useCallback((event: any) => {
    let updatedCampaign: any = selectedCampaign;
    if (event.target.checked) {
      updatedCampaign.push(event.target.value);
    } else {
      updatedCampaign.splice(selectedCampaign.indexOf(event.target.value), 1);
    }
    setSelectedCampaign(updatedCampaign);
    checkAll();
    isAllSelected();
    isDeletable();
    updateselectCampaign();
  }, []);

  const updateselectCampaign = () => {
    setUpdateCampaign(!updateCampaign);
  };

  const checkAll = () => {
    let uploadedItems: any = localStorage.getItem('uploadedCampaigns');
    let uploadedAssets: any = JSON.parse(uploadedItems);
    if (selectedCampaign.length == uploadedAssets.length) {
      let checkboxes: any = document.getElementsByName('assetsAll');
      for (let checkbox of checkboxes) {
        checkbox.checked = true;
      }
    } else {
      let checkboxes: any = document.getElementsByName('assetsAll');
      for (let checkbox of checkboxes) {
        checkbox.checked = false;
      }
    }
  };

  const deleteSelectedCampaign = () => {
    if (selectedCampaign.length !== 0) {
      deleteUploadedcampaign(selectedCampaign);
    }
  };

  const clearSelectedCampaigns = () => {
    let updatedCampaign: any = selectedCampaign;
    for (let uniqueId of selectedCampaign) {
      updatedCampaign.splice(selectedCampaign.indexOf(uniqueId), 1);
    }
    setSelectedCampaign(updatedCampaign);
  };

  const handleSelectAll = (event: any) => {
    setSelectedAll(event.target.checked);
    let uploadedItems: any = localStorage.getItem('uploadedCampaigns');
    let uploadedAssets: any = JSON.parse(uploadedItems);
    if (event.target.checked) {
      if (uploadedAssets.length !== 0) {
        let campaigns: any = [];
        for (let campaign of uploadedAssets) {
          campaigns.push(campaign.uniqueid);
        }
        setSelectedCampaign(campaigns);
      }
    } else {
      setSelectedCampaign([]);
    }
    isAllSelected();
    isDeletable();
    setUpdateCampaign(!updateCampaign);
  };

  //footer process
  useEffect(() => {
    localStorage.setItem('brand', '');
    localStorage.setItem('market', '');
    getFilters();
  }, []);

  useEffect(() => {
    isAllSelected();
    isDeletable();
  }, [selectedCampaign]);

  const getFilters = () => {
    getAllTradeMark()
      .then(function (data) {
        let brands: any = [];
        for (let brand of data) {
          let brandData: any = { value: brand.brandName, label: brand.brandName };
          brands.push(brandData);
        }
        setBrandsList(brands);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
    getAllFilters()
      .then(function (data) {
        let markets: any = [];
        for (let market of data.markets) {
          let marketData: any = { value: market.marketId, label: market.marketName };
          markets.push(marketData);
        }
        setMarketsList(markets);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const sendApproval = () => {
    if (selectedBrand && selectedMarket && selectedCampaign.length !== 0) {
      let marketItems: any = [];
      for (let val of selectedMarket) {
        marketItems.push(val.value);
      }
      let marketValue: string = marketItems.toString();
      let userName: any = '';
      if (user.user.username) {
        userName = user.user.username;
      } else {
        userName = window.sessionStorage.getItem('userEmail');
      }
      setShowLoader(true);
      sendForApproval(userName, selectedBrandValue, marketValue, selectedCampaign)
        .then(function (data) {
          getUploadCampaigns();
          setSelectedBrand('');
          setSelectedBrandValue('');
          localStorage.setItem('brand', '');
          setSelectedMarket([]);
          localStorage.setItem('market', '');
          setIsApproved(true);
          setShowLoader(false);
          setSentSuccess(true);
          setTimeout(() => {
            setSentSuccess(false);
          }, 5000);
        })
        .catch(function (error) {
          console.log(error);
          setShowLoader(false);
        });
    }
  };

  const handleBrand = (selectedOption: any) => {
    setSelectedBrand(selectedOption);
    setSelectedBrandValue(selectedOption.value);
    localStorage.setItem('brand', selectedOption.value);
    isAllSelected();
  };

  const handleMarket = (selectedOption: any) => {
    setSelectedMarket(selectedOption);
    let marketItems: any = [];
    for (let val of selectedOption) {
      marketItems.push(val.value);
    }
    let marketValue: string = marketItems.toString();
    localStorage.setItem('market', marketValue);
    isAllSelected();
  };

  const isAllSelected = () => {
    let uploadedItems: any = localStorage.getItem('uploadedCampaigns');
    let uploadedAssets: any = JSON.parse(uploadedItems);
    let campaignWithError = '';
    uploadedAssets?.map((item: any) => {
      selectedCampaign.map((campaign: any) => {
        if (item.uniqueid === campaign && item.integrationError !== null) {
          campaignWithError = 'Error';
        }
      });
    });
    if (
      localStorage.getItem('brand') &&
      localStorage.getItem('market') &&
      selectedCampaign.length !== 0 &&
      !campaignWithError
    ) {
      setIsApproved(false);
    } else {
      setIsApproved(true);
    }
  };

  const isDeletable = () => {
    if (selectedCampaign.length !== 0) {
      setIsDeleted(false);
    } else {
      setIsDeleted(true);
    }
  };

  return (
    <div>
      <div className="Upload">
        <div className="Upload-box Upload-box--scroll">
          <div className="Upload-ctaTop">
            <span className="Checkbox Checkbox--default">
              <input
                type="checkbox"
                id="selectAllCheck"
                className="Checkbox-input"
                onChange={handleSelectAll}
                checked={selectedAll}
              />
              <label htmlFor="selectAllCheck" className="Checkbox-label">
                Select all assets
              </label>
            </span>
          </div>

          <Tabs defaultActiveKey="Uploads" id="uncontrolled-tab-example">
            <Tab eventKey="Uploads" title="Uploads">
              <Row className="g-4">
                <Col xs={12} lg={3}>
                  <ProductAdd
                    className="ProductAdd"
                    getUploadCampaigns={getUploadCampaigns}
                    uploadtext="Upload more assets"
                  ></ProductAdd>
                </Col>

                {uploadedCampaigns &&
                  uploadedCampaigns.map((campaign: any) => {
                    return (
                      <Col xs={12} lg={3}>
                        <ProductCard
                          className="ProductCard"
                          selectedAll={selectedAll}
                          selectCampaign={selectCampaign}
                          deleteAsset={deleteAsset}
                          campaign={campaign}
                        ></ProductCard>
                      </Col>
                    );
                  })}
              </Row>
            </Tab>
            <Tab eventKey="Approved" title="Approved">
              <Row className="g-4">
                {approvedCampaings.length == 0 ? (
                  <Row>
                    <Col xs={12} lg={6} className="u-center u-textCenter">
                      <h2 className="Heading">Empty</h2>
                      <p>
                        Looks like there are no approved items <br></br> yet. They will appear here after you’ve{' '}
                        <br></br> uploaded your images.
                      </p>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                {approvedCampaings &&
                  approvedCampaings.map((campaign: any) => {
                    return (
                      <Col xs={12} lg={3}>
                        <ProductCard className="ProductCard" campaign={campaign}></ProductCard>
                      </Col>
                    );
                  })}
              </Row>
            </Tab>
          </Tabs>
        </div>
        <div className="UploadFooter">
          <div className="area">
            <div className="UploadFooter-selectArea">
              <div className="UploadFooter-selectWrapper">
                <label>Choose brand</label>
                <Select value={selectedBrand} onChange={handleBrand} options={brandsList} placeholder="Select brand" />
              </div>

              <div className="UploadFooter-selectWrapper">
                <label>Choose market(s)</label>
                <Select
                  isMulti={true}
                  value={selectedMarket}
                  onChange={handleMarket}
                  options={marketsList}
                  placeholder="Select market(s)"
                />
              </div>
            </div>
            <div className="UploadFooter-ctaArea">
              <Button className="UploadFooter-delete no-border" onClick={deleteSelectedCampaign} disabled={isDeleted}>
                <i className="icon-Delete-bin"></i>
                <span>Delete</span>
              </Button>

              <Button className="Button Button--arrow" onClick={sendApproval} disabled={isApproved}>
                Send for an approval ({selectedCampaign.length})
              </Button>
            </div>
          </div>
          <div className="area">{sentSuccess ? <UploadFootersucces /> : ''}</div>

          {showLoader ? <Loader /> : ''}
        </div>
      </div>

      <div className="u-marginTlg"></div>

      <br />

      {showLoader ? <Loader /> : ''}
    </div>
  );
};

export default Upload;
