import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import closeIcon from '../../assets/images/close-icon.png';
import { deleteHmUser } from '../../services/HmUsersService';
import { deletePartnerUser } from '../../services/PartnersService';
import Loader from '../../components/Shared/Loader';

const DeleteUser = (props: any) => {
  const [showLoader, setShowLoader] = useState(false);
  const [isDeleted, setIsDeleted] = useState(true);

  const handleCheckBox = (e: any) => {
    setIsDeleted(true);
    if (e.target.checked) {
      setIsDeleted(false);
    }
  };

  const deleteHmUserInfo = () => {
    setShowLoader(true);
    deleteHmUser(props.user.email)
      .then(function (data) {
        setShowLoader(false);
        props.deleteSuccess();
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const deletePartnerUserInfo = () => {
    setShowLoader(true);
    deletePartnerUser(props.user.email, props.partnerId)
      .then(function (data) {
        setShowLoader(false);
        props.deleteSuccess();
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  return (
    <div className="popup-backdrop">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6>
            Delete user{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
            >
              <img src={closeIcon} alt="x" />
            </span>
          </h6>
        </div>

        <div className="addUser-popup--body">
          <div className="addUser-popup--body--delete-user">
            <h6 className="Heading fontFamilySecondary">Are you sure you want to delete this user?</h6>

            <div className="delete-box">
              <Row>
                <Col xs={10} sm={10} md={10}>
                  <h6 className="Heading fontFamilySecondary">
                    {props.user.firstName} <br />
                    {props.user.lastName}
                  </h6>
                </Col>
                <Col xs={2} sm={2} md={2}>
                  <input type="checkbox" onChange={(e) => handleCheckBox(e)} />
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div className="addUser-popup--footer">
          {props.partnerId ? (
            <Button
              className="Button"
              disabled={isDeleted}
              onClick={() => {
                deletePartnerUserInfo();
              }}
            >
              Delete user
            </Button>
          ) : (
            <Button
              className="Button"
              disabled={isDeleted}
              onClick={() => {
                deleteHmUserInfo();
              }}
            >
              Delete user
            </Button>
          )}
        </div>
      </div>
      {showLoader ? <Loader /> : ''}
    </div>
  );
};

export default DeleteUser;
