import './index.scss';
import Button from '../Shared/Button';

const UploadFooter = () => {
  return (
    <div className="UploadFooter UploadFooter-succes">
      <Button className="Button Button--iconLast">
        All picture(s) were succesfully sent to H&M <i className="icon-Validation-Check"></i>
      </Button>
      <span>You will find your approved pictures under "Approved" page.</span>
    </div>
  );
};

export default UploadFooter;
