import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import Link from '../../components/Global/Link';
import Upload from '../../components/Upload';
import Loader from '../../components/Shared/Loader';
import { downloadAssets } from '../../services/CampaignInternalService';
import './index.scss';
import Hero from '../../components/Hero';
import Button from '../../components/Shared/Button';
import HeroImage from '../../assets/images/campainHero-image.svg';

const UploadCampaign = () => {
  const [showLoader, setShowLoader] = useState(false);

  const downloadGuidelines = () => {
    setShowLoader(true);
    let fileName: string = 'Guidelines.zip';
    downloadAssets(fileName)
      .then(function (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  return (
    <div className="uploadCampaign">
      <Hero className="Hero Hero-gradient">
        <h1 className="Heading Heading--h1 Hero-heading">Upload Campaign</h1>
        <p className="UpLoad-heroText">In below screen you can upload new assets as well as watching old uploads.</p>

        <Link className="Link Link--arrow" data-testid="downloadGuidelines" onClick={downloadGuidelines}>
          Image guidelines
        </Link>
      </Hero>
      <Container fluid="xl">
        <Upload></Upload>
        {showLoader ? <Loader /> : <></>}
      </Container>
    </div>
  );
};

export default UploadCampaign;
