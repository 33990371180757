import './index.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
const ImageDescriptionBlock = (props: any) => {
  return (
    <>
      <div
        className="Article-content Article-content--size1of3"
        id={props.block.data?.id}
        data-testid="ImageDescriptionBlock"
      >
        <h2 className="Heading Article-sectionHeading">{props.block.data?.headline}</h2>
        <Row>
          {props.block.data?.items.map((item: any) => {
            return (
              <Col lg={4} key={item.heading}>
                <div className="Article-image">
                  <img src={item.image} style={{ alignItems: 'start' }} />
                </div>
                <h6 className="Heading">{item.heading}</h6>
                <p className="ArticleText">
                  {item.preamble
                    ?.replaceAll('/learn/', '/article/learn/')
                    ?.replaceAll('/sustainability/', '/article/sustainability/')}
                </p>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};
export default ImageDescriptionBlock;
