import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import './index.scss';

const PartnerBreadcrumb = (props: any) => {
  let history = useHistory();
  const partnerDetail = useSelector((state: RootStateOrAny) => state.partner);
  const [userType, setUserType] = useState('');
  const userTypeData = useSelector((state: RootStateOrAny) => state.userType);

  useEffect(() => {
    if (userTypeData?.userType) {
      setUserType(userTypeData.userType);
    } else {
      let usertype: any = window.sessionStorage.getItem('userType');
      setUserType(usertype);
    }
  }, []);

  return (
    <ul className="Breadcrumb mt-4" data-testid="partner-breadcrumb">
      {userType == 'HmUser' ? (
        <li className="Breadcrumb-item">
          <a
            onClick={() => {
              history.push('/partner-profile');
            }}
            className="Breadcrumb-link"
          >
            partner profiles
          </a>
        </li>
      ) : (
        ''
      )}
      <li className="Breadcrumb-item">
        <span className="Breadcrumb-current">{partnerDetail?.partner?.partnerName}</span>
      </li>
    </ul>
  );
};

export default PartnerBreadcrumb;
