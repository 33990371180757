export const updateUser = (user: any) => {
  return {
    type: 'user/updateUser',
    payload: user,
  };
};

export const getUser = () => {
  return {
    type: 'user/getUser',
  };
};
