import axios from 'axios';

const setCmsCookie = () => {
  //Call the CMS api to set cookie
  let headersContent = {
    'Content-type': 'application/json; charset=UTF-8',
    Authorization: 'Bearer ' + window.sessionStorage.getItem('idToken'),
  };

  axios({
    withCredentials: true,
    method: 'POST',
    url: process.env.REACT_APP_CMS_COOKIE,
    headers: headersContent,
  })
    .then(function (response) {})
    .catch(function (error) {
      console.log('REACT_APP_CMS_COOKIE', error);
    });
};

export default setCmsCookie;
