import { Configuration, LogLevel } from '@azure/msal-browser';

const AzureActiveDirectoryAppClientId: any = process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID;

export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: AzureActiveDirectoryAppClientId,
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
            //console.warn(message);
            return;
        }
      },
    },
  },
};
