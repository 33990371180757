import { Redirect } from 'react-router-dom';
import { PERMISSIONS } from '../../helpers/permission-maps';
import useGetRoles from '../../helpers/useGetRole';

const hasPermission = ({ permissions, scopes }) => {
  const scopesMap = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });
  return permissions.some((permission) => scopesMap[permission]);
};

export default function PermissionsGate({
  children,
  scopes = [] as string[],
  redirect = false,
  canAccessSupport = false,
  canAccessSizes = false,
  canAccessDropShipInvoice = false,
}) {
  const role = useGetRoles();
  const permissions = PERMISSIONS[role];
  const permissionGranted = hasPermission({ permissions, scopes });

  let childrenData: any = <></>;

  if (!permissionGranted) {
    if (redirect) {
      return <Redirect to="/dashboard" />;
    }
    return null;
  }

  if (canAccessSupport) {
    if (JSON.parse(window.sessionStorage.getItem('partnerDetail')!)?.sapId) {
      return <>{children}</>;
    } else {
      return null;
    }
  }

  if (canAccessDropShipInvoice) {
    if (
      JSON.parse(window.sessionStorage.getItem('partnerDetail')!)?.shopId &&
      JSON.parse(window.sessionStorage.getItem('partnerDetail')!)?.isEinvoicingEnabled
    ) {
      return <>{children}</>;
    } else {
      return null;
    }
  }

  if (canAccessSizes) {
    const showSizes: any = ['10001', '10004'];
    const partnerEntityData: any = [];
    let partnerData = JSON.parse(window.sessionStorage.getItem('partnerDetail')!);
    partnerData?.partnerEntity?.forEach((item: any) => {
      partnerEntityData.push(item.categoryId);
    });

    const uniquePartnerEntityData: any = partnerEntityData?.filter((val, id, array) => {
      return array.indexOf(val) === id;
    });

    if (showSizes?.some((item) => uniquePartnerEntityData?.includes(item))) {
      childrenData = <>{children}</>;
    } else {
      return null;
    }
  } else {
    childrenData = <>{children}</>;
  }

  return childrenData;
}
