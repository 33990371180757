export const updatePartnerProfiles = (profiles: any) => {
  return {
    type: 'partner/updatePartnerProfiles',
    payload: profiles,
  };
};

export const getPartnerProfiles = () => {
  return {
    type: 'partner/getPartnerProfiles',
  };
};
