import { useState } from 'react';
import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import closeIcon from '../../assets/images/close-icon.png';
import { useForm } from 'react-hook-form';
import { createPartnerUser } from '../../services/PartnersService';
import Loader from '../../components/Shared/Loader';

const AddPartnerUser = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showLoader, setShowLoader] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [notifyText, setNotifyText] = useState('');

  const onSubmit = (data: any) => {
    data['firstName'] = data.firstName?.trim();
    data['lastName'] = data.lastName?.trim();
    data['email'] = data.email?.trim();
    setShowLoader(true);
    createPartnerUser(data, props.partnerId)
      .then(function (data) {
        setShowLoader(false);
        if (data === 'Partner user already exist') {
          setNotifyText('User already exist.');
          setShowFailed(true);
          setTimeout(() => {
            setShowFailed(false);
          }, 5000);
        } else {
          props.addSuccess(data);
        }
      })
      .catch(function (error) {
        setShowLoader(false);
        console.log(error);
      });
  };

  return (
    <div className="popup-backdrop">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6>
            Add new user{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
            >
              <img src={closeIcon} alt="x" />
            </span>
          </h6>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addUser-popup--body">
            <input
              {...register('firstName', { required: true })}
              className={`form-control ${errors.firstName ? 'error-control' : ''}`}
              placeholder="First name"
              autoComplete="off"
            />
            {errors.firstName && <span>Please enter your firstname</span>}

            <input
              {...register('lastName', { required: true })}
              className={`form-control ${errors.lastName ? 'error-control' : ''}`}
              placeholder="Last name"
              autoComplete="off"
            />
            {errors.lastName && <span>Please enter your lastname</span>}

            <input
              {...register('email', {
                required: 'required',
                pattern: {
                  value: /^\S+@(?!hm\.com$|hennesandmauritz\.onmicrosoft\.com$)\S+\.\S+$/,
                  message: 'Entered value does not match email format',
                },
              })}
              className={`form-control ${errors.email ? 'error-control' : ''}`}
              placeholder="Email address"
              autoComplete="off"
            />
            {errors.email && <span>Please enter your valid email</span>}
          </div>

          <div className="addUser-popup--footer">
            <Button className="Button Button--transparentBlack" type="submit">
              Add user
            </Button>
          </div>
        </form>
      </div>
      {showLoader ? <Loader /> : ''}
      {showFailed ? (
        <div className="alert alert-danger show-failed">
          <strong>Failed!</strong> {notifyText}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AddPartnerUser;
