import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss';
const DocumentDownloadBlock = (props: any) => {
  return (
    <>
      <div className="Article-content" id={props.block.data?.id} data-testid="DocumentDownloadBlock">
        <div className={'Article-content--download'}>
          <Row>
            <Col xl={8}>
              <h6 className="Heading">{props.block.data?.heading}</h6>
              {props.block.data?.documents?.map((doc: any) => {
                return (
                  <>
                    <p className="Article-docDescription">{doc.description}</p>
                    <a href={doc.url} className="Article-docLink" rel="noreferrer" download target="_blank">
                      <i className="icon-Download"></i>
                      <span>{doc.title}</span>
                    </a>
                  </>
                );
              })}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default DocumentDownloadBlock;
