import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Button from '../../components/Shared/Button';
import { useHistory } from 'react-router-dom';
import { getDistinctSizes, getTrademarks } from '../../services/SizeService';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/Shared/Loader';
import Hero from '../../components/Hero';

let partnerId: string | null = '';

const Sizes = () => {
  const [sizesData, setSizesData] = useState([]);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [trademarkList, setTrademarkList] = useState<any[]>([]);
  partnerId = window.sessionStorage.getItem('partnerId');

  interface LocationState {
    message: any;
    pathname: string;
    state: any;
  }
  const history = useHistory();
  const location = useLocation<LocationState>();

  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state?.message);
      setTimeout(() => {
        setMessage('');
      }, 5000);
      window.history.replaceState({}, document.title);
      getDistinctSizesData();
    }
  }, [location]);

  const handleImport = () => {
    let path = '/sizes/importmethod/manually';
    history.push(path);
  };

  const returnCustomerGroup = (customerGroupId: string) => {
    let groups = '';
    let customerGroupIdArray = customerGroupId?.split(',');
    if (customerGroupIdArray.length > 1) {
      customerGroupIdArray.map((item, index) => {
        groups += returnGroupName(item) + (index < customerGroupIdArray.length - 1 ? ', ' : ' ');
      });
    } else {
      groups += returnGroupName(customerGroupId);
    }
    return groups;
  };

  const returnGroupName = function (groupName) {
    switch (groupName) {
      case '0':
        return 'Boy(1-2yr)';
      case '1':
        return 'Boy(1-2yr)';
      case '2':
        return 'Girl (1-12yr)';
      case '3':
        return 'Infant (0-1yr)';
      case '4':
        return 'Men';
      case '5':
        return 'Woman';
      case '6':
        return 'Unisex';
      default:
        return groupName;
    }
  };

  const getDistinctSizesData = () => {
    setShowLoader(true);
    getDistinctSizes(partnerId).then((sizeList) => {
      //Hard cache needs to be removes
      if (sizeList) {
        let firstpass = JSON.stringify(sizeList);
        let secondpass: any;
        if (firstpass.includes('},')) {
          secondpass = JSON.parse('[' + sizeList + ']');
        } else {
          let data: any = [];
          data.push(sizeList);
          secondpass = data;
        }
        setSizesData(secondpass);
      }
      setShowLoader(false);
    });
  };

  useEffect(() => {
    getDistinctSizesData();
    if (partnerId) {
      setShowLoader(true);
      getTrademarks(partnerId).then((data) => {
        setShowLoader(false);
        const trademarks: any = [];
        data?.map((item: any) => {
          trademarks.push({ value: item.brandId, label: item.brandName });
        });
        setTrademarkList(trademarks);
      });
    }
  }, []);

  //Create link to edit passing state to the edit page
  const handleEdit = (size: any) => {
    let path = '/sizes/' + size.partnerHubId;
    history.push({
      pathname: path,
      state: { size: size },
    });
  };

  const returnTrademark = (trademarkId: string) => {
    return trademarkList.find((item) => item.value === trademarkId)?.label;
  };

  return (
    <div className="Sizes Page-gradient">
      <Hero className="Hero Hero-gradient noHeroImage">
        <h1 className="Heading">Size Upload</h1>
        <p>
          In order to ensure the smooth processing of your product data, we need your size information and its regional
          translations in advance.
        </p>
        <p>Please upload your size information by clicking the ‘Upload new Size’ button.</p>
      </Hero>
      <Container fluid="xl">
        <Row className="message">{message ? <p>{message}</p> : null}</Row>
        <Row>
          {sizesData?.length > 0 && (
            <>
              <Col xs={12} sm={12} md={12}>
                <div>
                  <Button className="Button" onClick={handleImport}>
                    <i className="icon-Plus"></i> Upload new size
                  </Button>
                </div>
                <div>
                  <h5 style={{ color: 'white' }}>Your sizes</h5>
                  {sizesData.map((item: any) => {
                    let dateCreated: any;
                    if (item?.created) {
                      dateCreated = item?.created.split('T');
                      dateCreated = dateCreated[0];
                    }

                    const trademarks = item?.tradeMark ?? [];
                    const customerGroup = item?.customerGroup ?? '';
                    const productType = item?.productType ?? [];
                    const success = item?.success ? item.success : false;
                    //const created = item?.created ? item?.created.split("T") : '';
                    //const partnerHubId = item?.partnerHubId ?? '';

                    return (
                      <div className="sizeItem">
                        <a onClick={() => handleEdit(item)}>
                          <h6 className="pull-left">
                            {dateCreated} - {returnTrademark(trademarks)}{' '}
                            {(trademarks && customerGroup) || (trademarks && productType) ? ',' : ''}{' '}
                            {returnCustomerGroup(customerGroup)} {customerGroup && productType ? ',' : ''}{' '}
                            {productType.length > 40 ? productType.substring(0, 40 - 3) + '...' : productType}
                          </h6>
                          <span className="pull-right">
                            <span className={success === true ? 'status success' : 'status error'}>
                              {success === true ? 'Uploaded' : 'Action needed'}
                            </span>
                            <i className="icon-Arrows-Right"></i>
                          </span>{' '}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </>
          )}

          {sizesData?.length === 0 && (
            <>
              <Col xs={6} sm={6} md={6}>
                <h5>Upload your first size</h5>
                <p></p>
                <Button className="Button" onClick={handleImport}>
                  <i className="icon-Plus"></i> Upload new size
                </Button>
              </Col>
            </>
          )}
        </Row>
        <br />
      </Container>
      {showLoader ? <Loader /> : <></>}
    </div>
  );
};

export default Sizes;
