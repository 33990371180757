import PartnerUser from '../../types/PartnerUser';

export type CurrentPartnerUserUpdateAction = {
  type: 'currentPartnerUser/updateCurrentPartnerUser';
  payload: PartnerUser;
};
export type CurrentPartnerUserGetAction = {
  type: 'currentPartnerUser/getPartnerUser';
};

export const updateCurrentPartnerUser = (user: PartnerUser) => {
  const action: CurrentPartnerUserUpdateAction = {
    type: 'currentPartnerUser/updateCurrentPartnerUser',
    payload: user,
  };
  return action;
};

export const getCurrentPartnerUser = () => {
  const action: CurrentPartnerUserGetAction = {
    type: 'currentPartnerUser/getPartnerUser',
  };
  return action;
};
