export const updatePartnerEntity = (entity: any) => {
  return {
    type: 'partner/updatePartnerEntity',
    payload: entity,
  };
};

export const getPartnerEntity = () => {
  return {
    type: 'partner/getPartnerEntity',
  };
};
