import { useState } from 'react';
import './index.scss';

const Tabs = (props: any) => {
  const printWithFileName = (title: string) => {
    var tempTitle = document.title;
    document.title = title + '.pdf';
    window.print();
    document.title = tempTitle;
  };

  const handleClick = (lessonListItem, index) => {
    props.setCurrentLesson(lessonListItem);
    props.setCurrentIndex(index);
    props.setActiveId(index);
  };

  return (
    <div className="Tabs-overflow">
      <ul className="Tabs">
        {props.lessonList &&
          props.lessonList.map((lessonListItem: any, index: number) => {
            return (
              <li className={index === props.activeId ? 'Tabs-item is-active' : 'Tabs-item'} key={index}>
                <div className="Tabs-link" onClick={() => handleClick(lessonListItem, index)} data-testid="tab-item">
                  <h6 className="Heading Tabs-heading">{lessonListItem.heading}</h6>
                  <div
                    className={
                      lessonListItem.done ? 'Article-status Article-status--done' : 'Article-status Article-status'
                    }
                  ></div>
                </div>
                {index === props.activeId ? (
                  <i
                    className="downloadIcon icon-Download"
                    data-testid="download-icon"
                    onClick={() => printWithFileName(lessonListItem.heading)}
                  ></i>
                ) : null}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Tabs;
