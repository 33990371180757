export const updateAllArticles = (allArticles: any) => {
  return {
    type: 'learn/updateAllArticles',
    payload: allArticles,
  };
};

export const getAllArticles = () => {
  return {
    type: 'learn/getAllArticles',
  };
};
