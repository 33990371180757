import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PartnerBreadcrumb from '../../components/Shared/PartnerBreadcrumb';
import PartnerUserTab from '../../components/PartnerUserTab';
import PartnerInformation from '../../components/PartnerInformation';
import './index.scss';

const PartnerAccount = () => {
  return (
    <div className="partner-user partnerInformation">
      <Container fluid="xl">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <PartnerBreadcrumb />

            <Tabs defaultActiveKey="businessProfile" id="uncontrolled-tab-example">
              <Tab eventKey="businessProfile" title="Business profile">
                <PartnerInformation />
              </Tab>
              <Tab eventKey="users" title="Manage users">
                <PartnerUserTab />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PartnerAccount;
