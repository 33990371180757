import axios from 'axios';
import getDetails from '../api/EnviromentConfig';

let campaignUrl: string = getDetails().apiurl;
let subscriptionKey: string = getDetails().subscriptionKey;

export async function getCampaigns(brand: string, country: string, status: string) {
  try {
    const response = await axios({
      method: 'GET',
      url: campaignUrl + 'Internal/GetAssets?brand=' + brand + '&country=' + country + '&status=' + status,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function deleteCampaigns(partnerId: string, uniqueIds: any, email: string) {
  try {
    let selectedId = uniqueIds.toString();
    const response = await axios({
      method: 'DELETE',
      url: campaignUrl + 'Internal/DeleteAssets?id=' + partnerId + '&selectedId=' + selectedId + '&username=' + email,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function approveCampaigns(partnerId: string, uniqueIds: any, email: string) {
  try {
    let selectedId = uniqueIds.toString();
    let status: string = 'Approved';
    let campaignCode: string = '';
    let usage: string = '';
    const response = await axios({
      method: 'PUT',
      url:
        campaignUrl +
        'Internal/ApproveAssets?partnerId=' +
        partnerId +
        '&selectedId=' +
        selectedId +
        '&username=' +
        email +
        '&status=' +
        status +
        '&campaignCode=' +
        campaignCode +
        '&usage=' +
        usage,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function disapproveCampaigns(partnerId: string, uniqueIds: any, email: string) {
  try {
    let selectedId = uniqueIds.toString();
    let status: string = 'Received';
    let campaignCode: string = '';
    let usage: string = '';
    const response = await axios({
      method: 'PUT',
      url:
        campaignUrl +
        'Internal/ApproveAssets?partnerId=' +
        partnerId +
        '&selectedId=' +
        selectedId +
        '&username=' +
        email +
        '&status=' +
        status +
        '&campaignCode=' +
        campaignCode +
        '&usage=' +
        usage,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function downloadCampaigns(
  partnerId: string,
  uniqueIds: any,
  email: string,
  campaignCode: string,
  usage: string,
) {
  try {
    let selectedId = uniqueIds.toString();
    let status: string = 'Downloaded';
    const response = await axios({
      method: 'PUT',
      url:
        campaignUrl +
        'Internal/ApproveAssets?partnerId=' +
        partnerId +
        '&selectedId=' +
        selectedId +
        '&username=' +
        email +
        '&status=' +
        status +
        '&campaignCode=' +
        campaignCode +
        '&usage=' +
        usage,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function downloadAssets(fileName: any) {
  const fileData: string = fileName;
  try {
    const response = await axios({
      method: 'GET',
      url: campaignUrl + 'Internal/DownloadAsset?fileName=' + encodeURIComponent(fileData),
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
      responseType: 'blob',
    });
    return response.data;
  } catch {
    return null;
  }
}
