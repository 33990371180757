import axios from 'axios';
import getDetails from '../api/EnviromentConfig';
let dataUrl: string = getDetails().apiurl;
let subscriptionKey: string = getDetails().subscriptionKey;

export async function getAllFilters() {
  try {
    const response = await axios({
      method: 'GET',
      url: dataUrl + 'Data/GetAllEntity',
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function getAllTradeMark() {
  try {
    const response = await axios({
      method: 'GET',
      url: dataUrl + 'Data/GetAllTradeMark',
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}
