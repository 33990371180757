import { useState } from 'react';
import './index.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../Shared/Button';
import { useHistory } from 'react-router-dom';
import { downloadAssets } from '../../../services/CampaignInternalService';
import Loader from '../Loader';

const CtaWidget = (props: any) => {
  const [showLoader, setShowLoader] = useState(false);
  let history = useHistory();

  const downloadGuidelines = () => {
    setShowLoader(true);
    let fileName: string = 'Guidelines.zip';
    downloadAssets(fileName)
      .then(function (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  return (
    <div className={props.className}>
      <Row>
        <Col xs={6}>
          <div className="CtaWidget-content">
            <h5 className="Heading">Have a question?</h5>
            <p className="CtaWidget-text">
              Read our guidelines{' '}
              <a data-testid="downloadGuidelines" onClick={downloadGuidelines}>
                here
              </a>{' '}
              or reach out to your H&M contact.
            </p>
          </div>
        </Col>
        <Col xs={6}>
          <div className="CtaWidget-button">
            <Button
              className="Button Button--arrow Button--transparentBlack"
              to="/contact"
              onClick={() => {
                history.push('/contact');
              }}
            >
              Find your contact
            </Button>
          </div>
        </Col>
      </Row>
      {showLoader ? <Loader /> : <></>}
    </div>
  );
};

export default CtaWidget;
