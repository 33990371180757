export const updateDownloadCampaign = (selectedCampaignDownload: any) => {
  return {
    type: 'campaign/updateDownloadCampaign',
    payload: selectedCampaignDownload,
  };
};

export const getDownloadCampaign = () => {
  return {
    type: 'campaign/getDownloadCampaign',
  };
};
