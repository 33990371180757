import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../components/Shared/Button';

import './index.scss';
import '../../components/Shared/Indent-hero/index.scss';
const OnboardingCompleted = () => {
  return (
    <div className="Home">
      <div className="Page-background  onBoardingCompleted">
        <div className="onBoardingCompleted-image"></div>
        <Container fluid="md">
          <Row className="justify-content-md-center">
            <Col xs={12} sm={12} md={7}>
              <div className="Indent-hero">
                <h1 className="Heading">
                  You’ve successfuly
                  <span className="Hero-indent">completed onboarding!</span>
                </h1>

                <div className="Indent-heroWrapper">
                  <p>
                    Eventhough you’ve completed onboarding, you’ll still be able to <br></br> access this knowledge
                    again under the section “Learning.”
                  </p>
                  <Button className="Button Button--white Button--large">Go to your dashboard</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OnboardingCompleted;
