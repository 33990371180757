export const updateFilterCampaign = (selectedCampaignFilter: any) => {
  return {
    type: 'campaign/updateFilterCampaign',
    payload: selectedCampaignFilter,
  };
};

export const getFilterCampaign = () => {
  return {
    type: 'campaign/getFilterCampaign',
  };
};
