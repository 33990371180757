import { useHistory } from 'react-router-dom';
import './index.scss';

const BreadcrumbLink = (props: any) => {
  let history = useHistory();
  const links = props.links;

  return (
    <ul className="Breadcrumb">
      {links?.map((link: any, index: number, links: any) => {
        return (
          <li key={index} className="Breadcrumb-item">
            {index + 1 === links.length ? (
              link.name
            ) : (
              <span className="Breadcrumb-current">
                <a
                  data-testid="linkBreadcrumb"
                  onClick={() => {
                    history.push(link.path);
                  }}
                  className="Breadcrumb-link"
                >
                  {' '}
                  <span>{link.name}</span>{' '}
                </a>
              </span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default BreadcrumbLink;
