import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../components/Hero';
import InstructionList from '../../components/InstructionList';
import CtaWidget from '../../components/Shared/CtaWidget';
import Button from '../../components/Shared/Button';
import HeroImage from '../../assets/images/campainHero-image.svg';

const Campaigns = () => {
  let history = useHistory();

  const handleCampaign = () => {
    history.push('/upload-campaign');
  };

  return (
    <div>
      <Hero className="Hero Hero-gradient" handleCampaign={handleCampaign} heroImage={HeroImage}>
        <h1 className="Heading Heading--h1 Hero-heading">Campaign & Brand Page Asset Tool</h1>
        <p className="Hero-preamable">
          A tool to share your campaign & brand page assets with the new asset tool. Press “Upload Now” to get started!
        </p>
        <div className="Hero-cta Hero-indent">
          <Button className="Button Button--transparentBlack" data-testid="uploadNow" onClick={() => handleCampaign()}>
            Upload now
          </Button>
        </div>
      </Hero>

      <section className="Section">
        <InstructionList handleCampaign={handleCampaign}></InstructionList>
      </section>
      <section className="Section">
        <Container fluid="xl">
          <Row className="justify-content-md-end">
            <Col xs={12} lg={10}>
              <CtaWidget className="CtaWidget CtaWidget--blue" handleCampaign={handleCampaign}></CtaWidget>
            </Col>
            <Col xs={1}></Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Campaigns;
