import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';
import logoBlack from '../../assets/images/HM-logo.svg';
import EntitySelector from '../../components/EntitySelector';
import CategoryListing from '../../components/CategoryListing';
import getTheme from '../../helpers/themeManager';
import { getEntityListForLearn } from '../../helpers/utilities';
import Loader from '../../components/Shared/Loader';
import Button from '../../components/Shared/Button';

const Onboarding = (props: any) => {
  let history = useHistory();
  const [selectedEntity, setSelectedEntity] = useState<any>();
  const [categoryId, setCategoryId] = useState();
  const [businessModelId, setBusinessModelId] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [entityList, setEntityList] = useState<any[]>([]);
  const pageType = 'LearnPage';

  const handleEntity = (selectedOption: any) => {
    let entityValues = selectedOption?.value?.split(':');
    window.sessionStorage.setItem('entitySelectedOption', selectedOption.value);
    setCategoryId(entityValues[0]);
    setBusinessModelId(entityValues[1]);
  };

  useEffect(() => {
    const entityList: any = getEntityListForLearn();
    setEntityList(entityList);
    if (entityList?.length === 1) {
      handleEntity(entityList?.[0]);
      setSelectedEntity(entityList?.[0]);
      window.sessionStorage.setItem('entitySelectedOption', entityList?.[0].value);
    }
  }, []);

  const handleDashboard = () => {
    history.push('/dashboard');
  };

  return (
    <div className={`${getTheme()}`}>
      <header className="Header">
        <div className="Header-logoWrapper">
          <img src={logoBlack} alt="Logo" className="Header-logo Header-logo--black" />
        </div>
        <div className="Header-actions">
          <Button className="Button Button--whiteBlack Button--arrow" onClick={handleDashboard}>
            Skip to dashboard
          </Button>
        </div>
      </header>
      <div className="Onbording">
        <h1 className="Heading">Partner Hub Onboarding</h1>
        <p>
          Once you’ve completed onboarding you’ll be able to access this <br></br> guide again under the section
          “Learn.”
        </p>
        {entityList.length > 1 && (
          <div style={{ width: '400px' }}>
            <EntitySelector
              placeHolder={'View as...'}
              handleEntity={handleEntity}
              setSelectedEntity={setSelectedEntity}
              width={'300px'}
            ></EntitySelector>
          </div>
        )}
        <div className="Onboarding-slideWrapper">
          <div className="Onboarding-slide">
            <CategoryListing
              selectedEntity={selectedEntity}
              onBoarding={true}
              categoryId={categoryId}
              businessModelId={businessModelId}
              pageType={pageType}
              setShowLoader={setShowLoader}
            ></CategoryListing>
          </div>
        </div>
        {/* <div className="">
          <span className="Copy-rightText u-textBlack">partner hub</span>
        </div> */}
      </div>
      {showLoader ? <Loader /> : <></>}
    </div>
  );
};

export default Onboarding;
