import './index.scss';

const Checkbox = (props: any) => {
  const { children, ...other } = props;
  return (
    <span {...other}>
      <input
        type="checkbox"
        id="Checkbox-id"
        className="Checkbox-input"
        name="assetsAll"
        onChange={() => props.cb(!props.checked)}
        checked={props.checked}
        data-testid="Checkbox-input"
      />
      <label htmlFor="Checkbox-id" className="Checkbox-label" data-testid="Checkbox-label">
        {props.title ? props.title : 'Select all assets'}
      </label>
    </span>
  );
};

export default Checkbox;
