export const updateUserType = (userType: string) => {
  return {
    type: 'userType/updateUserType',
    payload: userType,
  };
};

export const getUserType = () => {
  return {
    type: 'userType/getUserType',
  };
};
