import { useState, useEffect } from 'react';
import './index.scss';
import { Container } from 'react-bootstrap';
import { getPage } from '../../services/CmsService';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Block from '../../components/Block';
import Loader from '../../components/Shared/Loader';
import Hero from '../../components/Hero';

const CompanyInformation = () => {
  const [showLoader, setShowLoader] = useState(false);

  interface Page {
    heading: string;
    name: string;
    preamble: string;
    tableOfContents: any;
    mainContentFriendly: any;
  }

  const [pageData, setPageData] = useState<Page>();

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = () => {
    setShowLoader(true);
    getPage('/company-information')
      .then(function (data) {
        if (data) {
          setPageData(data[0]);
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  return (
    <div className="" data-testid="content-loaded">
      {pageData && (
        <div>
          <Hero className="Hero Hero-gradient noHeroImage">
            <h1 className="mt-4 Heading">{pageData?.heading}</h1>
          </Hero>

          <Container fluid="xl">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="Profile-hero">
                  <p className="mt-4 Heading">{pageData?.preamble}</p>
                  <div className="Article">
                    {pageData?.mainContentFriendly &&
                      pageData?.mainContentFriendly.map((blockListItem: any, index: number) => {
                        return <Block position={index} block={blockListItem}></Block>;
                      })}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {showLoader ? <Loader /> : <></>}
    </div>
  );
};

export default CompanyInformation;
