import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PartnerBreadcrumb from '../../components/Shared/PartnerBreadcrumb';
import PartnerUserTab from '../../components/PartnerUserTab';

import './index.scss';

const PartnerUser = () => {
  return (
    <div className="partner-user Page-gradient">
      <Container fluid="xl">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <PartnerBreadcrumb />

            <Tabs defaultActiveKey="users" id="uncontrolled-tab-example">
              <Tab eventKey="users" title="Users">
                <PartnerUserTab />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PartnerUser;
